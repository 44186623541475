import { Breadcrumb, Button, Card, Col, Input, Radio, 
Row, Space, Modal, message, Form, Tag, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ImSortAlphaAsc } from "react-icons/im";
import { IoArrowBack, IoPencil, IoPencilOutline } from "react-icons/io5";
import { GET,POST ,apiURL} from "../../common/crud";
// Custom imports
import { Context } from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { loadPage } from "../../SharedFunctions";

const DedicatedServersManage = () => {
  const { state, dispatch } = useContext(Context);
  const [servers, setServers] = useState([]);
  const [activeObj, setActiveObj] = useState(null);
  const [filter,setFilter] = useState(null);
  const [verify2fa, setVerify2fa] = useState(null);
  const [showLogin,setShowLogin] = useState(false);
  const [power,setPower] = useState("power_on");
  const { Search } = Input;

  const [form] = Form.useForm();
  const onSearch = (value) => console.log(value);
  const customer = JSON.parse(sessionStorage.getItem('userData'));

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type='link'
          className='p-0'
          onClick={() => loadPage("/", dispatch)}>
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Dedicated Server - <em>Manage</em>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const LoginFormSuccess = (values) => {
    console.log("Login values", values);
    const bodyData = {
      email: customer.email,
      password: values.password,
      client_id: "user_portal",
    };

    let formBody = [];
    Object.keys(bodyData).map((key) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(bodyData[key]);
      return formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join("&");

    console.log("Form Body - ", formBody);
    fetch(`${apiURL}/oauth2/token`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Login res - ", data);
        if (data.detail) {
          // setLoginErr(data.detail);
        } else if (data.access_token === null && data.to_do === "verify2fa") {
          //setVerify2fa(true);
          submitPowerProcess()
        } else {
          submitPowerProcess();
          console.log("State - ", state);
        }
      })
      .catch((err) => {
        console.log("Login err - ", err);
      });
  };

  const submitPowerProcess = () =>{

    POST(`servers/${activeObj._id}/${power}`,state.token,{}).then((res)=>{
      console.log(res);
      if(res.success === true){
        getServerById(activeObj);
        message.success("Request submitted successfully");
      }
    })
  }

  const getServerById = (activeObj) => {
    GET(`servers/${activeObj._id}`, state.token)
    .then((res) => {
      if(res.success === true){
       setActiveObj(res.data.server);
        setShowLogin(false);
      }
      else 
        message.open("Failed to get server object");
    })
    .catch((err) => {
      //  setLoading(false);
      console.log("Get err - ", err);
    });
  }

  const getServers = () => {
    GET(`servers/customer/${state.userId}`, state.token)
      .then((res) => {
        console.log(res.data);
        setServers(res.data.servers);
      })
      .catch((err) => {
        //  setLoading(false);
        console.log("Get err - ", err);
      });
  };

  const getServerDetails = (short_id) => {
    GET(`servers/${short_id}`, state.token)
      .then((res) => {
        console.log(res.data);
        if( res.data.success === true){
        setActiveObj(res.data.server);}

      })
      .catch((err) => {
        //  setLoading(false);
        console.log("Get err - ", err);
      });
  };

  const serviceCols = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Date Start',
      dataIndex: 'date_start',
      key: 'date_start',
    },
    {
      title: 'Date End',
      dataIndex: 'date_end',
      key: 'date_end',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    }];

  useEffect(() => {
    getServers();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return activeObj === null ? (
    <>
      {/* Page Header */}
      <Row>
        <Col span='24' className='full-width page-header'>
          <Row align='middle'>
            <Col span='14' className='page-title'>
              Dedicated Servers - <span>Manage</span>
            </Col>
            <Col span='10' flex='row' align='end'>
              <Row>
                <Col span='8'>
                  <Button type='default'>
                    <ImSortAlphaAsc
                      style={{ marginRight: "8px", color: "#666" }}
                    />{" "}
                    Sort by Name
                  </Button>
                </Col>
                <Col span='1'>&nbsp;</Col>
                <Col span='15'>
                  <Search
                    placeholder='Search...'
                    onChange={(e)=> setFilter(e.target.value === "" ? null :e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row align='stretch'>
        <Col className='bg-white'>
          <div className='full-width order-list-block'>
            <ul className='order-list list-unstyled full-width mb-0'>
              {servers.filter(rec => {
                if(filter === null || filter === "")
                  return rec;
                return (
                  rec.title.toLowerCase().includes(filter.toLowerCase()) ||
                  rec.description.toLowerCase().includes(filter.toLowerCase()) ||
                  rec.location.toLowerCase().includes(filter.toLowerCase()) 
                )                
              }).map((obj, index) => {
                  return <li>
                    <div className='each-server-order no-hover each-manage-order full-width'>
                      <div className='each-server-info'>
                        <div className='each-server-title full-width'>
                          {obj.title}
                        </div>
                        <div className='each-server-desc full-width'>
                          {obj.description}
                        </div>
                        {/* <div className="each-server-provisioning full-width">
                          24 hours provisioning
                        </div> */}
                      </div>
                      <div className='each-server-pricing-block'>
                        <div className='full-width from-text text-left'>
                          IP address
                        </div>
                        <div className='full-width server-price'>
                          {obj.ip_address}
                          <span className='server-per-month'> </span>
                        </div>
                      </div>
                      <div className='order-block'>
                        <Button
                          className='btn-primary'
                          style={{ width: "100px" }}
                          onClick={() => {
                            getServerDetails(obj.short_id)
                          }}>
                          Details
                        </Button>
                      </div>
                    </div>
                  </li>
                ;
              })}
            </ul>
          </div>
        </Col>
      </Row>
    </>
  ) : 'product_info' in activeObj ? (
    <>
      <Row>
        <Col span='24' className='p-0' style={{ backgroundColor: "#f0f2f5" }}>
          <Card
            className='mb-2'
            title={
              <Row>
                <Col span={16} className='d-inline-flex align-items-center'>
                  <Button
                    onClick={() => setActiveObj(null)}
                    type='link'
                    className='p-0'>
                    <IoArrowBack size={18} />
                  </Button>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <span style={{ fontSize: "18px" }}>Details</span>
                </Col>
                <Col span={8} className='text-end'>
                  {" "}
                  {/* <Button onClick={() => setActiveObj(null)}>Back</Button> */}
                  <Button>Rename</Button> <Button>Set Group</Button>{" "}
                </Col>
              </Row>
            }>
            <Row gutter={30}>
              <Col span={24}>
                <Row className='order-basic-info-list'>
                  <Col span={6}>Name:</Col>{" "}
                  <Col span={18}>
                    {" "}
                    {activeObj.host_name}
                  </Col>{" "}
                </Row>
                <Row className='order-basic-info-list'>
                  {" "}
                  <Col span={6}>ID</Col>{" "}
                  <Col span={18}>{activeObj.product_info.product_id} </Col>{" "}
                </Row>
                <Row className='order-basic-info-list'>
                  {" "}
                  <Col span={6}>CPU</Col>{" "}
                  <Col span={18}>
                    {" "}
                    {activeObj.product_info.chasis_info.name !== undefined
                      ? activeObj.product_info.chasis_info.name
                      : null}{" "}
                  </Col>{" "}
                </Row>
                <Row className='order-basic-info-list'>
                  {" "}
                  <Col span={6}>OS</Col> <Col span={18}> {
                    activeObj.os_type
                  } </Col>{" "}
                </Row>
                <Row className='order-basic-info-list'>
                  {" "}
                  <Col span={6}>Location</Col>{" "}
                  <Col span={18}> {activeObj.product_info.location} </Col>{" "}
                </Row>
                <Row className='order-basic-info-list'>
                  {" "}
                  <Col span={6}>Chasis</Col>{" "}
                  <Col span={18}>
                    {" "}
                    {activeObj.product_info.chasis_info.name}{" "}
                  </Col>{" "}
                </Row>

                <Row className='order-basic-info-list'>
                  {" "}
                  <Col span={6}>RAM size</Col>{" "}
                  <Col span={18}>{activeObj.product_info.ram.ram} </Col>{" "}
                </Row>
                <Row className='order-basic-info-list'>
                  {" "}
                  <Col span={6}>Upgrade RAM size</Col>{" "}
                  <Col span={18}> <IoPencilOutline /> Upgrade </Col>{" "}
                </Row>
                <Row className='order-basic-info-list'>
                  {" "}
                  <Col span={6}>HDDs info</Col>{" "}
                  <Col span={18}> 
                      <Row> 
                        <Col span={8}>Name</Col>
                        <Col span={4}>slot</Col>
                        <Col span={4} >Interface</Col>
                      </Row>
                      { activeObj.activehdd.filter(re => re.default === true ).map((hdslot)=>{
                      return <Row> 
                        <Col span={8}> { `${hdslot.capacity}GB  ${hdslot.protocol} ${hdslot.form_factor}"`    } </Col>
                        <Col span={4}>{hdslot.slot}</Col>
                        <Col span={4} > {hdslot.protocol}</Col>
                      </Row>
                      })}

                  </Col>{" "}
                </Row>
                <Row className='order-basic-info-list'>
                  {" "}
                  <Col span={6}>HDDs Upgrade</Col>{" "}
                  <Col span={18}> <Button onClick={()=>{
                    loadPage("updateDedicatedServer",dispatch);
                    sessionStorage.setItem("activeServerId",activeObj.short_id)
                  }} ><IoPencilOutline /> Upgrade </Button></Col>{" "}
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Card>
          <Card
            className='mb-2'
            title={
              <Row>
                {" "}
                <Col span={16}>Uplinks</Col>
              </Row>
            }>
            <Row>
              <Col span={20}>Public 10 Mbps without redundancy</Col> 
              <Col span={4}>Free of charge</Col>
            </Row>
          </Card>
          <Card
            className='mb-2'
            title={
              <Row>
                {" "}
                <Col span={16}>IP Addresses</Col>
                <Col span={8}>
                  {" "}
                  <Button>Refresh</Button> <Button>PTR Records</Button>
                  <Button>Add Network</Button>{" "}
                </Col>
              </Row>
            }>
            <Row>
              <Col span={4}>public/private</Col> <Col span={4}>CIDR</Col>
              <Col span={4}>Gateway</Col>
              <Col span={4}>Type</Col>
              <Col span={4}>Status</Col>
            </Row>
          </Card>
          <Card
            className='mb-2'
            title={
              <Row>
                {" "}
                <Col span={16}>Traffic Usage</Col>
                <Col span={8}>
                  {" "}
                  <Button>Day</Button> <Button>Week</Button>
                  <Button>Month</Button><Button>Year</Button>{" "}
                </Col>
              </Row>
            }>
            <Row>
              <Col span={4}>public</Col> <Col span={4}>private</Col>
            </Row>
            <Row>
                  To DO Chart to be shown here
            </Row>
          </Card>

          <Card
            className='mb-2'
            title={
              <Row>
                {" "}
                <Col span={16}>Power Management</Col>
                <Col span={8}>
                  {" "}
                  <Button>Refresh</Button>{" "}
                </Col>
              </Row>
            }>
              <Row > <Col span={4}>Power status </Col>
                      <Col span={4}><Tag>{activeObj.power_status} </Tag></Col>   </Row>
              <Row>
              <Col span={12}>
                <Row>Select power command</Row>
              <Radio.Group onChange={(e)=>{ setPower(e.target.value) ; setShowLogin(true);}} value={activeObj.power_status}>
                <Space direction="vertical">
                  <Radio value={"power_on"}>On</Radio>
                  <Radio value={"power_off"}>Off</Radio>
                  <Radio value={"reboot"}>Reboot</Radio>
                </Space>
              </Radio.Group></Col>
              <Col span={12}>
              <Row>Power distribution units</Row>
                <Card 
                  title={<Row> 
                  <Col span={8}>Port</Col>
                  <Col span={8}>Status</Col>
                  </Row>}>
                    {/* loop over following row*/}

                </Card>

              </Col>
              </Row>
          </Card>
          <Card
            className='mb-2'
            title={
              <Row>
                {" "}
                <Col span={16}>Services</Col>
                <Table columns={serviceCols} dataSource={activeObj.services} ></Table>
              </Row>
            }>
            
          </Card>
        </Col>
      </Row>
      <Modal visible={showLogin}
        closable 
        title={'Password required'}
        okText={''}
        onCancel={()=>setShowLogin(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowLogin(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  LoginFormSuccess(values);
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
          >
            Submit
          </Button>
        ]}
      >
          <Row>
          This is a dangerous action. Please enter your Customer Portal account password for the confirmation.
           {/* Do you really want to {power.replace("_"," ")} the system ? */}
            </Row>
            <br/>
            
                  <Form
                            form={form}
                            layout='vertical'
                            //onFinish={verify2fa ? {} : LoginFormSuccess}
                              >
                            {verify2fa ? (
                              <Form.Item
                                label='Enter Otp'
                                name='otp'
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter yourotp",
                                  },
                                ]}>
                                <Input className='form-control' />
                              </Form.Item>
                            ) : (
                              <>
                                <Form.Item
                                  label=''
                                  name='password'
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter your Password!",
                                    },
                                  ]}>
                                  <Input addonBefore={"Password"}
                                    type='password'
                                    className='form-control'
                                  />
                                </Form.Item>{" "}
                              </>
                            )}
            </Form>
      </Modal>
    </>
  ) : null;
};

export default DedicatedServersManage;
