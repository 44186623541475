import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  Space,
  Form,
  Input,
  Radio,
  notification,
  Select,
  message,
} from 'antd';
import { GET, PUT } from '../../common/crud';
import { Context } from '../../context';
import Countries from '../../assets/json/countries.json';

const currs = [{'_id' : 1 , 'name' : 'INR','symbol' : '₹'} , {'_id':2 , 'name' : 'USD','symbol' : '$'}];
const BasicProfile = (props) => {
  const [accountTypeValue, setAccountTypeValue] = useState(null);
  const [profileCurrency, setProfileCurrency] = useState(null);
  const [currenciesData, setCurrenciesData] = useState(currs);
  const [customer, setCustomer] = useState(null);

  const { state, dispatch } = useContext(Context);

  const { Option } = Select;

  const [basicProfileForm] = Form.useForm();

  const getCustomerData = () => {
    GET(`customers/${state.userId}`, state.token)
      .then((res) => {
        if (res.success === true) {
          setCustomer(res.data);
          sessionStorage.setItem('userData', JSON.stringify(res.data));
          console.log('Get res - ', res);
          dispatch({
            type: 'USER_DATA',
            payload: {
              userData: res.data,
            },
          });
        } else if (res.success === false) {
          message.error('Something went wrong! Please try again!!!');
        }
      })
      .catch((err) => {
        console.log('Get err - ', err);
      });
  };

  const editCustomer = (obj) => {
    // console.log(values);
    const cust = { ...customer, ...obj };
    // setCustomer(cust);
    PUT(`customers`, 'me', state.token, cust).then((res) => {
      console.log(res);
      getCustomerData();
      props.show(false);
    });
    console.log(cust);
  };

  const accountTypeOnChange = (e) => {
    setAccountTypeValue(e.target.value);
    console.log(e.target.value);
  };

  const basicProfileSuccess = (values) => {
    console.log('Basic Profile on Success - ', values);
    editCustomer(values);
  };

  const profileCurrencyOnChange = (e) => {
    setProfileCurrency(e.target.value);
    console.log(e.target);
  };

  const countriesList = Countries.map((country) => (
    <Option key={country.dialCode} value={country.name}>
      {country.name}
    </Option>
  ));

  useEffect(() => {
    getCustomerData();
    //getCurrency();
    return () => {};
  }, []);

  return (
    <Form
      form={basicProfileForm}
      name="control-hooks"
      onFinish={basicProfileSuccess}
      layout="vertical"
    >
      <Row>
        <Col span="24">
          <Row gutter={50}>
            <Col span="12">
              <Form.Item
                name="customer_type"
                label="Account type:"
                rules={[
                  { required: true, message: 'Please select account type' },
                ]}
              >
                <Radio.Group
                  onChange={accountTypeOnChange}
                  value={accountTypeValue}
                >
                  <Space direction="horizontal">
                    <Radio value="personal">Personal</Radio>
                    <Radio value="business">Business</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={"12"}>
              <Form.Item
                name="currency"
                label="Currency:"
                rules={[
                  { required: true, message: 'Please select currency type' },
                ]}
              >
                <Radio.Group
                  onChange={profileCurrencyOnChange}
                  value={profileCurrency}
                >
                  <Space direction="horizontal">
                    {currenciesData
                      ? currenciesData.map((item) => (
                          <Radio key={item._id} value={item.name}>
                            {item.symbol}
                          </Radio>
                        ))
                      : ''}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col></Row>
            <Row gutter={50}>
            <Col span="12">
              <Form.Item
                name={accountTypeValue === 'business' ? 'company_name' : 'display_name'}
                label={ accountTypeValue === 'business' ? "Business or Organization name" : "Display Name"}
                rules={[
                  {
                    required: true,
                    message: accountTypeValue === 'business' ? "Business or Organization name" : "Display Name",
                  },
                ]}
              >
                <Input placeholder={ accountTypeValue === 'business' ? 
                "Please enter Business or Organization name" : "Please enter Display Name"} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="gstin"
                label="Gst Number ( only for india )"
                rules={[{ required: accountTypeValue === 'business' }]}
              >
                <Input placeholder="Please enter your GST Number" disabled={accountTypeValue !== 'business'}/>
              </Form.Item>
            </Col> 
            </Row>
        </Col>
        <Col span="24">
          <Row gutter="50">
            <Col span="12">
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Please enter your name" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Please enter your name" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="phone"
                label="Phone Number (international format)"
                rules={[{ required: false }]}
              >
                <Input placeholder="Please enter your name" />
                <small className="text-gray">
                  <em>Ex: +91 1234567890</em>
                </small>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="email_temp"
                label="Email"
                //  rules={[{ required: true }]}
                initialValue={customer ? customer.email : ''}
              >
                <Input
                  placeholder={
                    customer ? customer.email : 'Please enter your email'
                  }
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span="24" className="subTitleBlock">
          Billing address:
        </Col>
        <Col span="24">
          <Row gutter="50">
            <Col span="12">
              <Form.Item
                label="Country"
                name={['billing_address', 'country']}
                rules={[{ required: true, message: 'Select country name' }]}
              >
                <Select
                  showSearch
                  placeholder="Select country"
                  optionLabelProp="children"
                >
                  {countriesList}
                </Select>
              </Form.Item>
            </Col>
            <Col span='12'>
                  <Form.Item
                    label='State'
                    name={["billing_address", "state"]}
                    rules={[{ required: true, message: "Enter state name" }]}>
                    <Input placeholder='Enter state name...' />
                  </Form.Item>
                </Col>
            <Col span="12">
              <Form.Item
                name={['billing_address', 'city']}
                label="City"
                rules={[{ required: true, message: 'Please enter city name' }]}
              >
                <Input placeholder="Please enter City" />
              </Form.Item>
            </Col>
            
            <Col span="12">
              <Form.Item
                name={['billing_address', 'postal_code']}
                label="Postal Code"
                rules={[
                  { required: true, message: 'Please enter Postal Code' },
                ]}
              >
                <Input placeholder="Please enter Postal Code" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name={['billing_address', 'line1']}
                label="Address Line1"
                rules={[
                  { required: true, message: 'Please enter Address line1' },
                ]}
              >
                <Input placeholder="Enter Address line1" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name={['billing_address', 'line2']}
                label="Address Line2"
                rules={[
                  { required: true, message: 'Please enter Address line2' },
                ]}
              >
                <Input placeholder="Enter Address line2" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="mt-1 pt-1 border-top text-end">
          {/* <Button type="default" htmlType="button" className="me-2">
            Cancel
          </Button> */}
          <Button type="primary" htmlType="submit" className="px-3 btn-success">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BasicProfile;
