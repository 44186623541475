import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Radio,
  Space,
  Button,
  Breadcrumb,
  Tooltip,
  Select,
  Table,
  Tag,
  message,
  notification,
  Checkbox,
  Form,
} from "antd";
import { GET, PUT } from "../../common/crud";
import "./profile.scss";
import { Context } from "../../context";
import Countries from "../../assets/json/countries.json";
const currs = [{'_id' : 1 , 'name' : 'INR','symbol' : '₹'} , {'_id':2 , 'name' : 'USD','symbol' : '$'}]
const ProfileEdit = (props) => {
  const [currenciesData, setCurrenciesData] = useState(currs);
  const { state, dispatch } = useContext(Context);
  const [initval, setInitVals] = useState({});
  const { Option } = Select;

  const customer = { ...props.customer };


  const countriesList = Countries.map((country) => (
    <Option key={country.dialCode} value={country.name}>
      {country.name}
    </Option>
  ));

  const triggerEditSubmit = (values) => {
    props.editCustomer(values);
    props.setEditProfile(false);
  };

  useEffect(() => {
    //getCurrencies();
    return () => {};
  }, []);

  return (
    <>
      <Row>
        <Col span='24' className='full-width page-header'>
          <Row align='middle'>
            <Col span='14' className='page-title '>
              Profile Edit
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter='20' className='m-2'>
        <Form layout='vertical' onFinish={triggerEditSubmit}>
          {/* Account type */}
          <Col span='24' className='mb-2'>
            <Card title='Account Type' className='profile-card full-width'>
              <Radio.Group
                value={customer ? customer.customer_type : ""}
                disabled
                style={{ fontSize: "20px" }}>
                <Space direction='horizontal'>
                  <Radio value='personal'>Personal</Radio>
                  <Radio value='business'>Business</Radio>
                </Space>
              </Radio.Group>
            </Card>
          </Col>
          {/* Currency */}
          <Col span='24' className='mb-2'>
            <Card title='Currency' className='profile-card full-width'>
              {currenciesData.length > 0 ? (
                <Radio.Group value={customer ? customer.currency : ""} disabled>
                  {currenciesData.map((item) => (
                    <Radio
                      key={item._id}
                      value={item.name}
                      style={{ fontSize: "20px" }}>
                      {item.symbol}
                    </Radio>
                  ))}
                </Radio.Group>
              ) : (
                ""
              )}
            </Card>
          </Col>

          {/* Email Subscription */}
          <Col span='24' className='mb-2'>
            <Card
              title='Email Subscription'
              className='profile-card full-width'>
              <Checkbox>Marketing Newsletter Subscription</Checkbox>
            </Card>
          </Col>

          {/* Primary contact information */}
          <Col span='24' className='mb-2'>
            <Card
              title='Primary contact information'
              className='profile-card full-width'>
              <Row gutter={30}>
                <Col span='12'>
                  <Form.Item
                    label='First Name'
                    name='first_name'
                    initialValue={customer ? customer.first_name : ""}
                    rules={[
                      { required: true, message: "Enter your first name" },
                    ]}>
                    <Input placeholder='Enter your first name...' />
                  </Form.Item>
                </Col>
                <Col span='12'>
                  <Form.Item
                    label='Last Name'
                    name='last_name'
                    initialValue={customer ? customer.last_name : ""}
                    rules={[
                      { required: true, message: "Enter your last name" },
                    ]}>
                    <Input placeholder='Enter your last name...' />
                  </Form.Item>
                </Col>
                <Col span='12'>
                  <Form.Item
                    label='Phone number (international format)'
                    name='phone'
                    initialValue={customer ? customer.phone : ""}
                    rules={[
                      { required: false, message: "Enter your phone number" },
                    ]}>
                    <Input placeholder='Enter your phone number...' />
                    <small>Eg: +91 1234567890</small>
                  </Form.Item>
                </Col>
                <Col span='12'>
                  <Form.Item
                    label='Email'
                    name='email_type'
                    initialValue={customer ? customer.email : ""}
                    rules={[{ required: true, message: "Enter your email" }]}>
                    <Input placeholder='Enter your email...' readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* Business information */}
          <Col span='24' className='mb-2'>
            <Card
              title='Business Information'
              className='profile-card full-width'>
              <Row gutter={30}>
                <Col span='24'>
                  <Form.Item
                    label='Name of company or organization'
                    name='display_name'
                    initialValue={customer ? customer.display_name : ""}
                    rules={[
                      { required: true, message: "Enter your first name" },
                    ]}>
                    <Input placeholder='Enter your Company name...' />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* Billing address */}
          <Col span='24' className='mb-2'>
            <Card title='Billing address' className='profile-card full-width'>
              <Row gutter={30}>
                <Col span='12'>
                  <Form.Item
                    label='Country'
                    name={["billing_address", "country"]}
                    initialValue={
                      "billing_address" in customer
                        ? customer.billing_address.country
                        : ""
                    }
                    rules={[
                      { required: true, message: "Select country name" },
                    ]}>
                    <Select
                      showSearch
                      placeholder='Select country'
                      optionLabelProp='children'>
                      {countriesList}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span='12'>
                  <Form.Item
                    label='State'
                    name={["billing_address", "state"]}
                    initialValue={
                      "billing_address" in customer
                        ? customer.billing_address.state
                        : ""
                    }
                    rules={[{ required: true, message: "Enter state name" }]}>
                    <Input placeholder='Enter state name...' />
                  </Form.Item>
                </Col>
                <Col span='12'>
                  <Form.Item
                    label='City'
                    name={["billing_address", "city"]}
                    initialValue={
                      "billing_address" in customer
                        ? customer.billing_address.city
                        : ""
                    }
                    rules={[{ required: true, message: "Enter city name" }]}>
                    <Input placeholder='Enter city name...' />
                  </Form.Item>
                </Col>
                <Col span='12'>
                  <Form.Item
                    label='Postal Code'
                    name={["billing_address", "postal_code"]}
                    rules={[{ required: true, message: "Enter Postal Code" }]}
                    initialValue={
                      "billing_address" in customer
                        ? customer.billing_address.postal_code
                        : ""
                    }>
                    <Input placeholder='Enter Postal Code...' />
                  </Form.Item>
                </Col>
                <Col span='12'>
                  <Form.Item
                    label='Address line1'
                    name={["billing_address", "line1"]}
                    rules={[{ required: true, message: "Enter Address line1" }]}
                    initialValue={
                      "billing_address" in customer
                        ? customer.billing_address.line1
                        : ""
                    }>
                    <Input placeholder='Enter Address line1' />
                  </Form.Item>
                </Col>
                <Col span='12'>
                  <Form.Item
                    label='Address line2'
                    name={["billing_address", "line2"]}
                    rules={[{ required: true, message: "Enter Address line2" }]}
                    initialValue={
                      "billing_address" in customer
                        ? customer.billing_address.line2
                        : ""
                    }>
                    <Input placeholder='Enter Address line2' />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <div className='final-order-pricing-block justify-content-end'>
            <Button
              type='default'
              className='me-2'
              onClick={() => props.setEditProfile(false)}>
              Cancel
            </Button>
            <Button type='primary' htmlType='submit'>
              Save
            </Button>
          </div>
        </Form>
      </Row>
    </>
  );
};

export default ProfileEdit;
