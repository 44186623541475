import { PAGE } from "./reducer/types";

export const url = "https://dev-api.ibee.ai/";

export const loadPage = (val, dispatch) => {
  document.getElementById("main").scrollTo(0, 0, "smooth");

  dispatch({
    type: PAGE,
    payload: {
      page: val,
    },
  });
};
