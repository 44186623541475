import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
  Button,
  Breadcrumb,
  Card,
  Radio,
  Tooltip,
  Tabs,
  Checkbox,
  Form,
  Select,
  Switch,
  DatePicker,
  Table,
  Modal,
  Tag,
  message,
  notification,
} from 'antd';

import { IoAdd, IoPencil, IoTrash, IoInformationCircle } from 'react-icons/io5';
import { FaRupeeSign } from 'react-icons/fa';

// Custom imports
import { Context } from '../../context';
import { BREADCRUMB } from '../../reducer/types';
import { loadPage, EditForm } from '../../SharedFunctions';
import { POST, apiURL, GET, PUT } from '../../common/crud';

const BillingOrders = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [cust, setCust] = useState(
    JSON.parse(sessionStorage.getItem('userData'))
  );
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [chasisSKU, setChasisSKU] = useState('');
  const [chasisTableData, setChasisTableData] = useState(null);
  const [ramSKU, setRamSKU] = useState(null);
  const [hddSKU, setHddSKU] = useState(null);
  const [stopModal, setStopModal] = useState(false);

  const { state, dispatch } = useContext(Context);
  const { Option } = Select;
  const { TextArea } = Input;

  //  Go to OrderDetail
  const goToOrderDeail = (id) => {
    sessionStorage.setItem('orderId', JSON.stringify(id));
    loadPage('orderDetail', dispatch);
  };

  // Table Columns
  const ordersCols = [
    {
      title: 'Date',
      dataIndex: 'order_date',
      key: 'order_date',
      width: '125px',
      render: (text, id) =>
        text ? new Date(text).toISOString().substring(0, 10) : null,
    },
    {
      title: 'Order#',
      dataIndex: '_id',
      key: '_id',
      render: (_, record) => (
        <Button
          type="link"
          className="p-0"
          onClick={() => goToOrderDeail(record._id)}
        >
          {record._id.slice(-6)}
        </Button>
      ),
    },
    {
      title: 'Last Invoice',
      dataIndex: 'last_invoice',
      key: 'last_invoice',
      render: (text, id) =>
        text ? new Date(text).toISOString().substring(0, 10) : null,
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      className: 'text-end',
      width: '150px',
      render: (e, record) => (
        <>
          <>
          {'currency_symbol' in record ? record.currency_symbol+" " : record.currency+" "}
            {record.payment_status === 'partial'
              ? record.amount_due
              : record.payment_status === 'pending' ||
                ['cart_abandoned', 'idle'].includes(record.order_status)
              ? record.cart_price
              : 0}
          </>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'order_status',
      key: 'order_status',
      width: 120,
      render: (text) => (
        <>
          <Tag color="orange">{text}</Tag>
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: 120,
      render: (e, record, text) => (
        <>
          {record.payment_status === 'paid'  ? (
            <Button
              type="default"
              size="small"
              onClick={() => setStopModal(record)}
              className={
                record.order_type === 'recurring' ? "w-100 text-center" : "w-100 text-center btn-info text-white"
              }
            >
               {record.order_type === 'recurring' ? 'Cancel' : 'Resume' }
            </Button>
          ) : record.payment_status !== 'paid' && record.payment_status !== 'paid_init' ? (
            <>
              <Button
                type="default"
                size="small"
                className="w-100 text-center"
                onClick={() => {
                    sessionStorage.setItem(
                      'checkout',
                      JSON.stringify({ ...record, co_type: 'cart_order' })
                    );
                    loadPage('checkout', dispatch);
                  }}
              >
                Pay Now
              </Button>
            </>
          ) : record.payment_status === 'pending' ? (
            <>
              <Button
                type="default"
                size="small"
                disabled
                className="w-100 text-center"
              >
                In Progress
              </Button>
            </>
          ) : record.payment_status === 'cancelled' ? (
            ''
          ) : record.payment_status === 'paid_init' ? (
            <span style={{fontSize: '14px', color: '#777', fontStyle: 'italic'}}>In Processing...</span>
          ) : (
record.payment_status
          )}
        </>
      ),
    },
  ];

  const getListOrders = () => {
    GET(`orders/customer/${state.userId}`, state.token).then((res) => {
      console.log(res.data);
      setTableData(res.data);
      let countidleorders = 0;
      res.data.map((obj) => {
        if (obj.order_status === 'idle') {
          countidleorders = countidleorders + 1;
        }
      });
      dispatch({
        type: 'ORDER_COUNT',
        payload: { orderCount: countidleorders },
      });
    });
  };

  const cancelOrders = () => {
    let stop = stopModal.order_type === "recurring" ? true : false;
    let id = stopModal._id;
    POST(`orders/recurring/${id}?stop=${stop}`, state.token, {}).then((res) => {
      console.log(res);
      setStopModal(false);
      getListOrders();
    });
  };

  // Show Order Details
  const goToOrderDetail = (id) => {
    loadPage('invoiceDetail', dispatch);
  };

  // Show Customer Detail
  const goToCustomerDetail = (id) => {
    loadPage('customerDetails', dispatch);
  };

  // Row Data on Edit
  const tableRowEdit = (key) => {
    console.log('Got Row ID - ', key);
    // EditForm(key, "addLocation", dispatch);
    setUpdateModal(true);
  };

  // Common onChange
  const commonOnChange = () => {};

  // Confirm Modal Delete
  const deleteItem = (id) => {
    setDeleteModal(true);
  };

  // Row Data on Delete
  const tableRowDelete = (key) => {
    console.log('Got Row Id, DELETE - ', key);

    // For Demo only Check & Delete
    const updatedTable =
      tableData !== null && tableData.length > 1
        ? tableData.splice(key, 1)
        : [];

    setTableData(updatedTable);
  };

  // Row Selection
  const rowSelection = {
    onChange: (e) => {
      console.log('Row selection on change - ', e);
    },
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  const showModal = () => {
    setStopModal(true);
  };

  const handleCancel = () => {
    setStopModal(false);
  };

  // Chasis SKU on change
  const chasisSKUOnChange = (value) => {
    setChasisSKU(value);
  };

  // RAM SKU onChange
  const ramSKUOnChange = (value) => {
    setRamSKU(value);
  };

  // HDD SKu onChange
  const hddSKUOnChange = (value) => {
    setHddSKU(value);
  };

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => loadPage('/', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>Billing Orders</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Yes Stop Recurring
  const onStopRecurring = () => {
    setStopModal(false);
    notification.success({
      message: 'Successfully your Order Number #ORD123456 stopped!',
    });
  };

  //  Cancel stop Recurring
  const cancelStopRecurring = () => {
    setStopModal(false);
  };

  useEffect(() => {
    // Bredcrumb
    getListOrders();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    // set Table Data
    // setTableData(processorTableData);

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Billing Orders
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24" className="mb-1">
          <Row gutter="30">
            <Col>
              <Select
                placeholder="Select Location"
                defaultValue={'All Locations'}
                onChange={commonOnChange}
                style={{ width: '200px' }}
              >
                <Option value="All Locations">All Locations</Option>
                <Option value="Hyderabad,India">Hyderabad, India</Option>
                <Option value="Mumbai,India">Mumbai, India</Option>
              </Select>
            </Col>
            <Col>
              <Select
                placeholder="Select Status"
                defaultValue="All"
                onChange={commonOnChange}
                style={{ width: '200px' }}
              >
                <Option value="All">All Payment Status</Option>
                <Option value="paid">Paid</Option>
                <Option value="payment failed">Payment Failed</Option>
                <Option value="not_paid">Not Paid</Option>
                <Option value="pending">Pending</Option>
                <Option value="cancelled">Cancelled</Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          <Table columns={ordersCols} dataSource={tableData} />
        </Col>
      </Row>

      <Modal
        title="Basic Modal"
        visible={stopModal}
        onCancel={handleCancel}
        okText="Yes Stop Recurring"
        okButtonProps={{ className: 'btn-primary', title: stopModal.order_type === 'single' ? ' Continue Recurring order '
        :  'Stop Recurring order ' }}
        onOk={() => cancelOrders()}
      >
        <Row>
          <Col style={{ fontSize: '20px' }}>
            Dear {cust ? cust.display_name : null}, are you sure, do you really want to{' '}
            <span className="text-danger">
              {stopModal.order_type === 'single' ? ' Continue with '
              :  'Stop Order  '} {stopModal ? stopModal._id.slice(-6) : null} from Next Month
            </span>
            . Once abandoned by system, this order cannot be revoked, you need to again
            create new order!
          </Col>
        </Row>
        <Row></Row>
      </Modal>
    </>
  );
};

export default BillingOrders;
