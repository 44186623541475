import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Modal,
  Table,
  Tooltip,
  Input,
  List,
  Tag,
  Form,
  Alert,
  Divider,
  notification,
} from 'antd';
import { ImGift } from 'react-icons/im';
import { BiTrashAlt, BiChevronDown, BiChevronUp } from 'react-icons/bi';
//import { Formik, Field, Form } from "formik";

// Custom imports
import './cart.scss';
import { Context } from '../../context';
import { BREADCRUMB, CART_ITEMS_NUMBER } from '../../reducer/types';
import { loadPage } from '../../SharedFunctions';
import { DELETE, POST, apiURL, GET, PUT } from '../../common/crud';
// Table Dummy Content

export const CartOrderDetails = (props) => {
  const [showMoreToggle, setShowMoreToggle] = useState(false);
  return (
    <div className="order-details-info">
      <div
        className="order-main-title full-width text-truncate"
        style={{ fontWeight: 700 }}
      >
        {props.mainTitle}
      </div>
      <div className="order-sub-title full-width text-truncate">
        {props.subTitle}
      </div>
      <div className="order-sub-sub-title full-width text-truncate">
        {props.subSubTitle && props.subSubTitle.length > 0 ? (
          <Button
            type="link"
            className="p-0"
            onClick={() => setShowMoreToggle(!showMoreToggle)}
          >
            {showMoreToggle ? (
              <>
                Show Less <BiChevronUp />
              </>
            ) : (
              <>
                Show More <BiChevronDown />
              </>
            )}
          </Button>
        ) : (
          ''
        )}
        {props.subSubTitle && showMoreToggle
          ? props.subSubTitle.map((res, ind) => (
              <List bordered size="small" style={{width: 300,}}>
                <List.Item>
                  {`${ind + 1}.`} &nbsp;Hostname: &nbsp;
                  <strong>{`${res}`}</strong>
                </List.Item>
              </List>
            ))
          : ''}
      </div>
    </div>
  );
};

export const CartPrice = (props) => {
  return (
    <div className="cart-price-block">
      <div className="cart-price">
        <span className="cart-price-number d-block">
          {props.symbol}
          {Number(props.price).toFixed(2)}
        </span>
        <span className="cart-price-month d-block"> / {props.duration}</span>
      </div>
    </div>
  );
};

const Cart = () => {
  // Modal
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [cartItems, setCartItems] = useState([]);
  const [data, setData] = useState([]);
  const [price, setPrice] = useState(
    {addon_price:0,product_price:0,cart_price:0,discountValue:0,total_tds : 0,
     addon_price_pre_tds:0,product_price_pre_tds:0,cart_price_pre_tds:0});
  const [priceSymbol, setPriceSymbol] = useState(null);
  const [discount, setDiscount] = useState({
    code: '',
    valid: false,
    value: 0,
    type: '',
  });
  const [discountError, setDiscountError] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const { state, dispatch } = useContext(Context);
  const cust = JSON.parse(sessionStorage.getItem('userData'));

  const deletecartItem = (id) => {
    console.log(('Delete record - ', id));
    setDeleteModal(true);
    setDeleteData([
      {
        _id: id,
      },
    ]);
  };

  const columns = [
    {
      title: 'Order Details',
      dataIndex: 'order_details',
      className: 'order-details-col',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      className: 'w-20 text-end',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      className: 'cart-quantity text-center w-10',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      className: 'text-end w-10',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      className: 'cart-actions text-right w-10',
    },
  ];

  const getCartItems = () => {
    GET(`cart/customer/${state.userId}?status=idle&currency=${state.fx}`, state.token)
      .then((res) => {
        let items = res.data.cart_items;
        console.log('get Cart items - ', items);
        setCartItems(items);

        dispatch({
          type: 'CART_COUNT',
          payload: { cartCount: items.length },
        });

        sessionStorage.setItem(
          'cart_items',
          JSON.stringify(res.data.cart_items)
        );
        const objData = res.data.cart_items;
        const dat = [];
        let [cptds , cp , pp , pptds , ap ,aptds,ttds,atds,ptds] = [0,0,0,0,0,0,0,0,0,0];
        objData.map((obj, index) => {
          setPriceSymbol(obj.symbol);
          if (obj.cart_status === 'idle') {
            ap = ap + obj.addon_price;
            pp = pp + obj.product_price;
            cp = cp + obj.cart_price;
            aptds = aptds + obj.addon_price_pre_tds;
            pptds = pptds + obj.product_price_pre_tds;
            cptds = cptds + obj.cart_price_pre_tds;
            ttds = ttds + obj.total_tds;
            atds = atds + obj.addon_tds;
            ptds = ptds + obj.product_tds;
            dat.push({
              key: index,
              order_details: (
                <CartOrderDetails
                  mainTitle={obj.product_info.title}
                  subTitle={`Location: ${obj.product_info.location}`}
                  //  subSubTitle={obj.products[0].category}
                  subSubTitle={
                    obj.product_info.host_names !== undefined
                      ? obj.product_info.host_names
                      : null
                  }
                />
              ),
              price: (
                <CartPrice
                  priceConv="USD => INR"
                  price={obj.cart_price_pre_tds}
                  duration="Month"
                  symbol={obj.symbol}
                />
              ),
              quantity: obj.quantity,
              amount: (
                <span
                  className="cart-price-number text-end"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {obj.symbol}
                  {obj.cart_price_pre_tds.toFixed(2)}{' '}
                </span>
              ),

              actions: (
                <>
                  <Tooltip title="Delete">
                    <Button
                      type="link"
                      className="delete-btn"
                      onClick={() => deletecartItem(obj._id)}
                    >
                      <BiTrashAlt />
                    </Button>
                  </Tooltip>
                </>
              ),
            });
          }
        });

        setData(dat);
        setPrice({addon_price:ap,product_price:pp,cart_price:cp,total_tds:ttds,discountValue:0,
          addon_price_pre_tds:aptds,product_price_pre_tds:pptds,cart_price_pre_tds:cptds});
      })
      .catch((err) => {
        console.log('Get err - ', err);
      });
  };

  const verifyDiscountCode = (values) => {
    const discBody = {
      code: values.code,
      currency_code: 'INR',
      location: 'Hyderabad',
      category: 'baremetal',
    };
    POST(`discounts/verify`, state.token, discBody).then((res) => {
      // setOrderobj(res.data);
      console.log('Discount res - ', res);

      if (res.valid === false) {
        setDiscountError(true);
      } else {
        setDiscountError(false);
        setTimeout(() => {
          setIsModalVisible(false);
        }, 1500);
      }

      if (res.type === 'Percentage') {
        const discountPrice = Number((res.discount * price.cart_price) / 100).toFixed(2);
        setPrice({ ...price , discountValue : discountPrice,
          cart_price : Number(price.cart_price - discountPrice).toFixed(2) ,});
        setDiscount({
          code: values.code,
          valid: res.success,
          value: discountPrice,
          type: res.type,
        });
      } else {
        setPrice({ ...price , discountValue : res.discount,
          cart_price : Number(price.cart_price - res.discount).toFixed(2) });
        setDiscount({
          code: values.code,
          valid: res.success,
          value: res.discount,
          type: res.type,
        });
      }
    });
  };

  const createToOrderItem = (gateway = 'razorpay') => {
    const list = [];
    cartItems.map((obj, ind) => {
      if (obj.cart_status === 'idle') {
        list.push(obj._id);
      }
    });
    const cartBody = {
      customer_id: state.userId,
      carts: list,
      currency : state.fx,
      discount_code: discount.valid ? discount.code : '',
      cart_price: Number.parseInt(price.cart_price),
      order_type: 'recurring',
    };
    POST(`invoices/${gateway}`, state.token, cartBody).then((res) => {
      // setOrderobj(res.data);
      console.log(res);
      sessionStorage.setItem(
        'checkout',
        //  JSON.stringify({ ...res, co_type: 'new_order' })
        res.order_id
      );
      loadPage('checkout', dispatch);
      dispatch({
        type: CART_ITEMS_NUMBER,
        payload: {
          cartItemNumber: 0,
        },
      });
    });
  };

  const closePormo = () => {
    setIsModalVisible(false);
    setDiscount({ valid: false, code: '' });
  };

  // Promocode Popup
  const showModal = () => {
    setIsModalVisible(true);
  };

  //  On Delete Success
  const onDeleteSuccess = () => {
    console.log(('Delete data - ', deleteData));
    setDeleteModal(false);
    deleteData.map((res) => {
      DELETE(`cart`, res._id, state.token).then((res) => {
        getCartItems();
      });
    });
    notification.success({
      message: 'Successfully Deleted!',
    });
  };

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => loadPage('/', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Cart</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Handle Cancel
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //  Clear Cart Items
  const clearCart = () => {
    if (cartItems.length > 0) {
      let allIds = [];
      cartItems.map((res) => {
        console.log('Cart items res - ', res);
        allIds.push({
          _id: res._id,
        });
      });
      console.log('All delete ids - ', allIds);
      setDeleteData(allIds);
      setDeleteModal(true);
    }
  };

  useEffect(() => {
    getCartItems();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      setDiscount({
        code: '',
        valid: false,
        value: 0,
        type: '',
      });
      setDiscountError(false);
    };
  }, []);

  return (
    <>
      {/* Page Header */}
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title">
              Cart - <span>Order</span>
            </Col>
            <Col span="10" flex="row" align="end">
              <Button
                type="default"
                className="d-inline-flex align-items-center"
                onClick={showModal}
              >
                <ImGift style={{ marginRight: '8px' }} />
                Apply promo code
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Page Content */}
      <Row align="stretch">
        <Col span="24" className="p-2">
          <Row>
            <Col span="24">
              <Table
                columns={columns}
                dataSource={data}
                className="cart-table"
                pagination={false}
                summary={() =>
                  cartItems.length > 0 ? (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          colSpan={3}
                          className="text-end py-1"
                        >
                          Sub Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="cart-price-number py-1">
                          {priceSymbol}
                          {Number(price.cart_price_pre_tds).toFixed(2)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="py-1">
                          &nbsp;
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                       <Table.Summary.Row>
                        <Table.Summary.Cell
                          colSpan={3}
                          className="text-end py-1"
                        >
                          Tax
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="cart-price-number py-1">
                          {priceSymbol}
                          {Number(price.total_tds).toFixed(2)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="py-1">
                          &nbsp;
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          colSpan={3}
                          className="text-end py-1"
                        >
                          Discount
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="cart-price-number py-1">
                          - {priceSymbol}
                          {Number(price.discountValue).toFixed(2)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="py-1">
                          &nbsp;
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          colSpan={3}
                          className="text-end py-1"
                        >
                          Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="cart-price-number py-1">
                          {priceSymbol}
                          {Number(price.cart_price).toFixed(2)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="py-1">
                          &nbsp;
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  ) : (
                    ''
                  )
                }
              />
            </Col>
          </Row>

          <Row>
            <Col span="24" className="mt-2 pt-2">
              <div className="full-width font-weight-bold fs-7">
                Each server includes:
              </div>
              <div className="full-width pt-1 fs-7">
                <ul className="">
                  <li>Instant setup, including OS install</li>
                  <li>
                    Self-service panel (Control Power, KVM, OS reinstall,
                    Billing and more)
                  </li>
                  <li>24/7 customer support</li>
                  <li>Instant Hardware Replacement</li>
                  <li>Power management (on/off/reboot)</li>
                  <li>Physical console access (KVM)</li>
                  <li>10 days money back guarantee — no questions asked</li>
                </ul>
              </div>
              <div className="full-width mt-2 fs-7">
                Note, that after the order is submitted your configuration can't
                be modified. Please make sure to pay for the order within 30
                days. Please also note that orders not being paid within 30 days
                will be deleted.
              </div>
              <div className="full-width mt-3">
                <Alert
                  message="Note, that after the order is submitted your configuration can't be modified. Please make sure to pay for the order within 30 days. Please also note that orders not being paid within 30 days will be deleted."
                  type="info"
                  showIcon
                  className="bg-light border"
                />
              </div>
              <div className="full-width mt-3">
                <Alert
                  message="If the cart items are not ordered in 30 days, the cart will be cleared automatically."
                  type="info"
                  color="gray"
                  showIcon
                  className="bg-light border"
                />
              </div>
            </Col>
          </Row>
          {cartItems.length > 0 ? (
            <Row className="border-top border-gray py-2 mt-3 justify-">
              <Col span="6">
                <Button type="default" onClick={() => clearCart()}>
                  Clear Cart
                </Button>
              </Col>
              <Col span="18" className="text-end">
                <Button
                  type="default"
                  className="me-2"
                  onClick={() => loadPage('dsOrder', dispatch)}
                >
                  Continue Shopping
                </Button>
                <Button
                  type="default"
                  className="btn-primary"
                  onClick={() => {
                    createToOrderItem('razorpay');
                  }}
                >
                  Pay &nbsp; {priceSymbol} {price.cart_price}
                </Button>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
      <Modal
        title="Apply promo code"
        onCancel={handleCancel}
        visible={isModalVisible}
        footer={null}
      >
        <Row>
          {!discount.valid ? (
            <>
              {discountError ? (
                <Col span="24" className="mb-2">
                  <Alert message="Invalid Code" type="error" showIcon />
                </Col>
              ) : (
                ''
              )}
              <Col span="24">
                <Form
                  name="basic"
                  onFinish={verifyDiscountCode}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row>
                    <Col span="24">
                      <Form.Item
                        label="Enter Promocode"
                        name="code"
                        initialValue={discount.code}
                      >
                        <Input placeholder="Enter promocode here.." />
                      </Form.Item>
                    </Col>
                    <Col span="24">
                      <Divider className="m-0" />
                    </Col>
                    <Col className="mt-2">
                      <Button
                        type="default"
                        htmlType="button"
                        onClick={() => setIsModalVisible(false)}
                      >
                        Cancel
                      </Button>
                      <Button htmlType="submit" className="ms-2 btn-primary">
                        Verify
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </>
          ) : (
            <Col span="24" className="my-2">
              <Tag
                closable
                color={discount.valid ? 'green' : 'red'}
                onClose={closePormo}
                className="d-inline-flex align-items-center p-1"
                style={{ fontSize: '18px' }}
              >
                {discount.code}
              </Tag>
            </Col>
          )}
        </Row>
      </Modal>
      {/* Delete Modal */}
      <Modal
        title="Delete Confirmation!"
        visible={deleteModal}
        onOk={onDeleteSuccess}
        closable
        onCancel={() => setDeleteModal(false)}
      >
        Do you really want to delete this cart item(s)
      </Modal>
    </>
  );
};

export default Cart;
