import {
  Badge,
  Button,
  Col,
  Layout,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Tooltip,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { IoBusSharp, IoCart, IoLogOutOutline } from 'react-icons/io5';
import logo from '../../assets/images/ibee-hosting-logo.png';
import { GET } from '../../common/crud';
import BasicProfile from '../../components/ProfileAccount/basicProfile';
import { Context } from '../../context';
import { loadPage } from '../../SharedFunctions';
// Custom imports
import './header.scss';

const Header = (props) => {
  const { Header } = Layout;
  const [cartItems, setCartItems] = useState([]);
  const { state, dispatch } = useContext(Context);
  const [showUnpaid, setShowunpaid] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [renderContent, setRenderContent] = useState(null);
  const [basicProfileModal, setBasicProfileModal] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const getCartItems = () => {
    GET(`cart/customer/${state.userId}`, state.token)
      .then((res) => {
        if ('num_found' in res.data) {
          let items = res.data.cart_items;
          setCartItems(items);
          sessionStorage.setItem('cart_items', JSON.stringify(items));
          console.log(items);
          dispatch({
            type: 'CART_COUNT',
            payload: { cartCount: items.length },
          });
        } else if (res.success === false) {
          message.error('Something went wrong! Please try again!!!');
        }
      })
      .catch((err) => {
        //  setLoading(false);
        console.log('Get err - ', err);
      });
  };

  const getListOrders = () => {
    GET(`orders/customer/${state.userId}`, state.token).then((res) => {
      console.log('Order by Customer - ', res.data);
      setAllOrders(res.data);
      const cr = [];
      res.data.map((obj) => {
        if (obj.order_status === 'idle') {
          cr.push(
            <Row
              gutter={30}
              className={state.orderCount > 1 ? 'border-bottom py-1' : ''}
              style={{ width: '420px', alignItems: 'center' }}
            >
              <Col span="12">
                Unpaid order: {new Date(obj.invoice_date).toLocaleDateString()}
              </Col>
              <Col span="6" className="text-end">
                {'amount_paid' in obj && obj.amount_paid === 0
                  ? Number(obj.cart_price)
                  : Number(obj.amount_due)}
                {'currency_symbol' in obj ? " "+obj.currency_symbol : " "+obj.currency}
              </Col>
              <Col span="6" className="text-end">
                <Button
                  type="default"
                  onClick={() => {
                    sessionStorage.setItem(
                      'checkout',
                      //  JSON.stringify({ ...obj, co_type: 'cart_order' })
                      obj._id
                    );
                    setPopoverVisible(false);
                    loadPage('checkout', dispatch);
                  }}
                >
                  Pay
                </Button>
              </Col>
            </Row>
          );
        }
      });
      setRenderContent(cr);
      dispatch({
        type: 'ORDER_COUNT',
        payload: { orderCount: cr.length },
      });
    });
  };

  const logoutNow = () => {
    dispatch({
      type: 'LOGOUT_SUCCESS',
    });
    window.location.reload();
  };

  const cancelBasicProfileModal = () => {
    setBasicProfileModal(false);
  };

  const getCustomerData = () => {
    GET(`customers/${state.userId}`, state.token)
      .then((res) => {
        if (res.success === true) {
          sessionStorage.setItem('userData', JSON.stringify(res.data));
          dispatch({
            type: 'USER_DATA',
            payload: {
              userData: res.data,
            },
          });
          setBasicProfileModal(
            res.data.email === res.data.display_name
          );
          console.log('Get res - ', res);
        } else if (res.success === false) {
          message.error('Something went wrong! Please try again!!!');
        }
      })
      .catch((err) => {
        //  setLoading(false);
        console.log('Get err - ', err);
      });
  };

  useEffect(() => {
    getCustomerData();
    getCartItems();
    getListOrders();


    console.log('Header state - ', state);
  }, [state.cartItemNumber]);

  const menu = (
    <Menu onClick="">
      <Menu.Item key="1">Logout</Menu.Item>
      <Menu.Item key="2">2nd menu item</Menu.Item>
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header
        className="full-width header px-0"
        style={{ backgroundColor: '#fff' }}
      >
        <Row>
          <Col span="4">
            <div className="full-width branding-block">
              <a href="/" className="branding-link" title="IBEE Hosting">
                <img src={logo} alt="IBEE Hosting" />
              </a>
            </div>
          </Col>
          <Col span="14" className="pt-15 px-2">
            <div className="full-width">
              {state.breadcrumb ? state.breadcrumb : ''}
            </div>
          </Col>
          <Col
            span="1"
            className="d-flex align-items-center justify-content-center"
          ></Col>
          <Col span="4">
            <div className="d-flex align-items-center justify-content-end position-relative h-100">
              {state.orderCount > 0 ? (
                <Popover
                  placement="bottomRight"
                  title={null}
                  content={renderContent}
                  trigger="click"
                  visible={popoverVisible}
                  onVisibleChange={() => setPopoverVisible(!popoverVisible)}
                >
                  <Button
                    type="link"
                    title="Cart"
                    className="header-cart line-height-1"
                    onClick={() => {
                      setShowunpaid(showUnpaid ? false : true);
                      getListOrders();
                      setPopoverVisible(true);
                    }}
                    style={{ lineHeight: '0.6' }}
                  >
                    <Badge
                      count={state.orderCount}
                      showZero={false}
                      size={'small'}
                      color={'#F26522'}
                      children={
                        <IoBusSharp
                          size={19}
                          style={{ color: 'var(--bs-gray-600)', lineHeight: 1 }}
                        />
                      }
                    />
                  </Button>
                </Popover>
              ) : (
                ''
              )}
              <Button
                type="link"
                title="Cart"
                //className='header-cart line-height-1'
                onClick={() => {
                  loadPage('cart', dispatch);
                  getCartItems();
                }}
                style={{ lineHeight: '0.6' }}
              >
                <Badge
                  count={state.cartCount}
                  showZero={false}
                  size={'small'}
                  color={'#F26522'}
                  children={
                    <IoCart
                      size={19}
                      style={{ color: 'var(--bs-gray-600)', lineHeight: 1 }}
                    />
                  }
                ></Badge>
              </Button>
              <span className="text-gray">
                <em>Welcome</em>
              </span>{' '}
              <Button
                type="link"
                className="p-0"
                onClick={() => loadPage('profileAccount', dispatch)}
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  marginLeft: '8px',
                }}
              >
                {state.userData ? state.userData.display_name : 'Guest'}
              </Button>
            </div>
          </Col>
          <Col span="1">
            <Tooltip title="Logout">
              <Button
                className="mx-1"
                shape="circle"
                icon={
                  <IoLogOutOutline
                    size={18}
                    style={{ marginTop: '-4px', color: 'var(--bs-gray-600)' }}
                  />
                }
                onClick={() => logoutNow()}
              />
            </Tooltip>
          </Col>
        </Row>
      </Header>
      <Modal
        title="Please fill your Information"
        visible={basicProfileModal}
        footer={null}
        onCancel={cancelBasicProfileModal}
        width={800}
      >
        <BasicProfile show={setBasicProfileModal} />
      </Modal>
    </>
  );
};

export default Header;
