import {
  Breadcrumb,
  Button,
  Col,
  Modal,
  Radio,
  Row,
  Slider,
  Space,
  Typography,
  Checkbox,
  Select,
} from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { BiRupee, BiDollar, BiEuro } from 'react-icons/bi';
import { ImGift } from 'react-icons/im';
import { GET } from '../../common/crud';
import { Context } from '../../context';
import { BREADCRUMB } from '../../reducer/types';
import { loadPage } from '../../SharedFunctions';
// Custom imports
import './dedicatedServerOrder.scss';

// Dummy for testing
const ramJ = ['32', '64', '128', '256', '512', '1024'];
const coresJ = ['2', '4', '8', '16', '32', '64'];

const DedicatedServersOrder = () => {
  const [filterOption, setFilterOption] = useState('all');
  const [products, setProducts] = useState([]);
  const [filterloc, setFilterloc] = useState('All');
  const [filterram, setFilterram] = useState([5, 100]);
  const [filterproc, setFilterProc] = useState([5, 100]);
  const [filterPrice, setFilterprice] = useState([0, 100000]);
  const [allLoations, setAllLocations] = useState([]);
  const [available,setAvailable] = useState(false);
  // Modal
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { Title } = Typography;

  const { state, dispatch } = useContext(Context);

  // RAM Slider Steps
  const ramSteps = { 5: 8, 20: 16, 35: 32, 50: 64, 65: 128, 80: 256, 100: 512 };

  // Cores Slider Steps
  const coresSteps = { 5: 1, 20: 2, 35: 4, 50: 8, 65: 16, 80: 32, 100: 64 };
  // Tooltip Formater
  const ramTooltip = (e) => {
    console.log('formater - ', e);
    const gbRam = ramJ.map((ramNumb, ind) => {
      if (e === ind + 1) {
        console.log('format values -', ramNumb);
        return ramNumb;
      }
    });

    const abc = `${gbRam}`;

    return abc.replaceAll(',', '');
  };

  const coresTooltip = (e) => {
    const corJVal = coresJ.map((cor, ind) => {
      if (e === ind + 1) {
        return cor;
      }
    });

    const corVal = `${corJVal}`;

    return corVal.replaceAll(',', '');
  };

  const getAllLocations = () => {
    GET(`masterdata/locations`).then((res) => {
      console.log(res, res.data);
      setAllLocations(res.data.locations);
    });
  };

  // Price Marks
  const priceMarks = {
    20000: '20,000',
    520000: '5,20,000',
  };

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setFilterOption(e.target.value);
  };

  // Promocode Popup
  const showModal = () => {
    setIsModalVisible(true);
  };

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => loadPage('/', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Dedicated Server - <em>Order</em>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const goToServerConfig = () => {
    loadPage('serverConfiguration', dispatch);
  };

  const closePormo = () => {
    setIsModalVisible(false);
  };

  const getProducts = () => {
    GET('products?category=baremetal', state.token)
      .then((res) => {
        console.log(res.data);
        setProducts(res.data ? res.data.products : []);
        sessionStorage.setItem('products', JSON.stringify(res.data.products));
      })
      .catch((err) => {
        setProducts([]);
      });
  };

  useEffect(() => {
    getAllLocations();
    getProducts();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {};
  }, []);

  useEffect(() => {
    console.log(filterloc, filterproc, filterram, filterPrice);
  }, [filterloc, filterproc, filterram, filterPrice, state.fx]);

  return (
    <>
      <Row className="page-bg" align="stretch">
        <Col span="17">
          <Row>
            {/* Page Header */}
            <Col span="24" className="full-width page-header">
              <Row align="middle">
                <Col span="14" className="page-title">
                  Dedicated Servers - <span>Order</span>
                </Col>
                <Col span="10" flex="row" align="end">
                  {/* <Button type='default' onClick={showModal}>
                <ImGift style={{ marginRight: "10px" }} />
                Apply Promocode
              </Button>
              <Select placeholder={"Select Location"}  onChange={(value) => setFilterloc(value)}
              value={"Location:"+filterloc}>
                    <Select.Option value={"All"}>All</Select.Option>
                    <Select.Option value={"Hyderabad"}>Hyderabad</Select.Option>
                    <Select.Option value={"Dallas"}>Dallas</Select.Option>
              </Select>*/}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ position: 'realtvie', height: 'calc(100% - 70px)' }}>
            {/* Page Content */}
            <Col span="24" className="bg-white">
              <div className="full-width order-list-block">
                <ul className="order-list list-unstyled full-width mb-0">
                  {products
                    .filter((rec) => {
                      return (
                        rec.category === 'baremetal' && (
                          available || 
                          'Available' in rec.chasis_status ||
                          'Racked' in rec.chasis_status ) &&
                        rec.price > filterPrice[0] &&
                        rec.price < filterPrice[1] &&
                        (filterloc === rec.location || filterloc === 'All') &&
                        rec.chasis_info.processor_qty >=
                          coresSteps[filterproc[0]] &&
                        rec.chasis_info.processor_qty <=
                          coresSteps[filterproc[1]] &&
                        rec.chasis_info.ram.max_slots * rec.ram.size >=
                          ramSteps[filterram[0]] &&
                        rec.chasis_info.ram.max_slots * rec.ram.size <=
                          ramSteps[filterram[1]]
                      );
                    })
                    .map((pro, index) => {
                      return pro.category === 'baremetal' ? (
                        <li>
                          <Button
                            type="link"
                            className="each-server-order full-width"
                            onClick={() => {
                              goToServerConfig();
                              sessionStorage.setItem(
                                'activeProduct', JSON.stringify(pro._id)
                              );
                            }}
                          >
                            <div className="each-server-info">
                              <div className="each-server-title full-width">
                                {pro.title}
                                {/* JSON.stringify(pro.chasis_info)*/}
                              </div>
                              <div className="each-server-desc full-width">
                                {pro.description} / Location: {pro.location}
                              </div>
                              {/* <div className="each-server-provisioning full-width">
                          24 hours provisioning
                        </div> */}
                            </div>
                            <div className="each-server-pricing-block">
                              <div className="full-width">
                                <div className="full-width from-text text-left">
                                  from
                                </div>
                                <div className="full-width server-price">
                                  <span className="server-price-number">
                                    {pro.prices[state.fx+"_sym"] + " "+pro.prices[state.fx+"_with_tds"] }
                                  </span>
                                  <span className="server-per-month">
                                    {' '}
                                    / Month
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="order-block">
                              <Button
                                className="btn-primary"
                                onClick={() => {}}
                              >
                                Order Now
                              </Button>
                            </div>
                          </Button>
                        </li>
                      ) : null;
                    })}
                </ul>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span="1" />
        <Col span="6">
          <Row align="stretch">
            <Col
              span="24"
              className="ibee-theme bg-white"
              style={{ borderLeft: '1px solid #eee' }}
            >
              <div className="full-width" >
                <div className="full-width border-bottom filter-page-header page-header">
                  <h5 onMouseOut={()=>setAvailable( !available) }> Filters </h5>
                </div>
                <div className="full-width p-2 border-bottom">
                  <div className="ful-width pb-1">Currency</div>
                  <div className="full-width">
                    <Radio.Group
                      onChange={(e) => {
                        dispatch({
                          type: 'FX',
                          payload: { fx : e.target.value },
                        });   }}
                      value={state.fx}
                    >
                      <Radio value={'INR'}>INR </Radio>
                      <Radio value={'USD'}>USD </Radio>
                      <Radio value={'EUR'}>EUR </Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="full-width p-2 border-bottom">
                  <div className="ful-width pb-1">Locations</div>
                  <div className="full-width">
                    <Radio.Group
                      onChange={(e) => setFilterloc(e.target.value)}
                      value={filterloc}
                    >
                      <Radio value={'All'}> {'All'}</Radio>
                      {allLoations.map((obj) => {
                        return <Radio value={obj.name}> {obj.name}</Radio>;
                      })}
                    </Radio.Group>
                  </div>
                </div>
                <div className="full-width p-2 border-bottom">
                  <div className="ful-width pb-1">Price Range</div>
                  <div className="full-width px-1">
                    <Slider
                      range
                      marks={{
                        0: '0k',
                        20000: '20k',
                        40000: '40k',
                        60000: '60k',
                        80000: '80k',
                        100000: '100k',
                      }}
                      min={0}
                      max={100000}
                      step={5000}
                      defaultValue={filterPrice}
                      value={filterPrice}
                      onChange={(value) => setFilterprice(value)}
                      //onAfterChange={(value)=>setFilterprice(value)}
                      className="customSlider"
                    />
                  </div>
                </div>

                <div className="full-width p-2 border-bottom">
                  <div className="ful-width pb-1">Max RAM (in GB)</div>
                  <div className="full-width">
                    <Slider
                      range
                      marks={ramSteps}
                      tooltipVisible={false}
                      step={null}
                      defaultValue={[5, 100]}
                      value={filterram}
                      onChange={(value) =>
                        setFilterram([
                          value[0] ? value[0] : 5,
                          value[1] ? value[1] : 100,
                        ])
                      }
                      //onAfterChange={(value)=>setFilterprice(value)}
                      className="customSlider"
                    />
                  </div>
                </div>
                <div className="full-width p-2">
                  <div className="ful-width pb-1">
                    No. of Cores in Processor
                  </div>
                  <div className="full-width">
                    <Slider
                      range
                      marks={coresSteps}
                      tooltipVisible={false}
                      step={null}
                      defaultValue={[5, 100]}
                      value={filterproc}
                      onChange={(value) =>
                        setFilterProc([
                          value[0] ? value[0] : 5,
                          value[1] ? value[1] : 100,
                        ])
                      }
                      //onAfterChange={(value)=>setFilterprice(value)}
                      className="customSlider"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DedicatedServersOrder;
