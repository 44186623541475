export const RaidAvailableSpace = (dNum,dSize,raid) => {
    if( dNum > 0 && dSize > 0 && raid >= 0 ) {

        var total = -1;

        if(raid === 0 && dNum >= 1)
            total = dNum * dSize;

        if(raid === 1 && dNum === 2)
            total = dSize;

        if(raid === 10 && dNum >= 4 && (dNum % 2) === 0 )
            total = (dSize * dNum) / 2;

        if(raid === 5 && dNum >= 3)
            total = (dSize * dNum) - dSize;

        if(raid === 6 && dNum >= 4)
            total = (dSize * dNum) - dSize * 2;

        if(raid === 50 && dNum >= 6)
            total = ((dSize * (dNum/2)) - dSize ) * 2;

        if(raid === 60 && dNum >= 8)
            total = ((dSize * (dNum/2)) - dSize*2 ) * 2;

        //availableSpace.innerText = total >=0 ? total : 'err';
        //totalSpace.innerText = dNum * dSize;
        //updateBar(total);
        var availableSpace = dNum * dSize;
		var percent = (availableSpace/100);
		var availablePercent = parseInt(total/percent); 
        return {"total" : total , "available" : availablePercent }
}

};

export const RaidOptions = (diskNum) => {
    
    let enableRaidOption = [];
    let dNum = diskNum;
    if(dNum === 1) {
        enableRaidOption = [0];
    } 

    if(dNum === 2) {
        enableRaidOption = [0, 1];
    } 

    if(dNum !== 2 && dNum >= 3) {
        enableRaidOption = [0, 5];
    }

    if(dNum >= 4) {
        if(dNum%2 === 0) enableRaidOption = [6, 10];
        if(dNum%2 === 1) enableRaidOption = [6];
    }

    if(dNum >= 5 && dNum > 4) {
        if(dNum%2 === 0) enableRaidOption = [0, 5, 6];
    }

    if(dNum >= 6) {
        enableRaidOption  = [0, 50];
    }

    if(dNum >= 8) {
        enableRaidOption = [0, 60];
    }
    return enableRaidOption;
};

// 

export function raid0(slots) { //traditional raid 0 calculation
    var drives = slots.slice(); //slice clones array and new ref goes to drives (optional here)
    drives.sort(function (a, b) {
        return a.capacity - b.capacity
    }); // slots array ascending (must be for xraid5&6 calcs & smallest_hdd)
    var used = 0, protection = 0, unused = 0;
    var num_hdd = drives.length;  // number of drives
    var smallest_hdd = drives[0].capacity; // smallest drive is index 0 because slots are sorted (smallest first)
    var ts = 0; //totalsize
    for (var i = 0; i < num_hdd; i++) { //summing up the size of the drives (to get base 10 gigabyte size of all drives)
        ts += drives[i].capacity;
    }
    used = num_hdd * smallest_hdd;
    protection = 0;
    unused = ts - (used + protection);
    return [used, unused, protection];
}
export function raid1(slots) { //traditional raid 1 calculation
    var drives = slots.slice(); //slice clones array and new ref goes to drives (optional here)
    drives.sort(function (a, b) {
        return a.capacity - b.capacity
    }); // slots array ascending (must be for xraid5&6 calcs & smallest_hdd)
    var used = 0, protection = 0, unused = 0;
    var num_hdd = drives.length; // number of drives
    var smallest_hdd = drives[0].capacity; // smallest drive is index 0 because slots are sorted (smallest first)
    var ts = 0; //totalsize
    for (var i = 0; i < num_hdd; i++) { //summing up the size of the drives (to get base 10 gigabyte size of all drives)
        ts += drives[i].capacity;
    }
    used = smallest_hdd;
    protection = smallest_hdd * (num_hdd - 1);
    unused = ts - (used + protection);
    return [used, unused, protection];
}
export function raid5(slots) { //traditional raid 5 calculation
    var drives = slots.slice(); //slice clones array and new ref goes to drives (optional here)
    drives.sort(function (a, b) {
        return a.capacity - b.capacity
    }); // slots array ascending (must be for xraid5&6 calcs & smallest_hdd)
    var used = 0, protection = 0, unused = 0;
    var num_hdd = drives.length; // number of drives
    //l("r5calc: num_hdd: " + num_hdd);
    var smallest_hdd = drives[0].capacity; // smallest drive is index 0 because slots are sorted (smallest first)
    var parity = 1;
    var ts = 0; //totalsize
    for (var i = 0; i < num_hdd; i++) { //summing up the size of the drives (to get base 10 gigabyte size of all drives)
        ts += drives[i].capacity;
    }
    used = smallest_hdd * (num_hdd - parity);
    protection = smallest_hdd * parity;
    unused = ts - (used + protection);
    return [used, unused, protection];
}
export function raid6(slots) { //traditional raid 6 calculation
    var drives = slots.slice(); //slice clones array and new ref goes to drives (optional here)
    drives.sort(function (a, b) {
        return a.capacity - b.capacity
    }); // slots array ascending (must be for xraid5&6 calcs & smallest_hdd)
    var used = 0, protection = 0, unused = 0;
    var num_hdd = drives.length; // number of drives
    var smallest_hdd = drives[0].capacity; // smallest drive is index 0 because slots are sorted (smallest first)
    var parity = 2;
    var ts = 0; //totalsize
    for (var i = 0; i < num_hdd; i++) { //summing up the size of the drives (to get base 10 gigabyte size of all drives)
        ts += drives[i].capacity;
    }
    used = smallest_hdd * (num_hdd - parity);
    protection = smallest_hdd * parity;
    unused = ts - (used + protection);
    return [used, unused, protection];
}
export function raid10(slots) { //traditional raid 10 calculation
    var drives = slots.slice(); //slice clones array and new ref goes to drives (optional here)
    drives.sort(function (a, b) {
        return a.capacity - b.capacity
    }); // slots array ascending (must be for xraid5&6 calcs & smallest_hdd)
    var used = 0, protection = 0, unused = 0;
    var num_hdd = drives.length; // number of drives
    var smallest_hdd = drives[0].capacity; // smallest drive is index 0 because slots are sorted (smallest first)
    var ts = 0; //totalsize
    for (var i = 0; i < num_hdd; i++) { //summing up the size of the drives (to get base 10 gigabyte size of all drives)
        ts += drives[i].capacity;
    }
    used = smallest_hdd * Math.floor(num_hdd / 2); // use math floor to drop any odd drives if allow odd raid10s
    protection = used;
    unused = ts - (used + protection);
    return [used, unused, protection];
}
