import { Alert, Button, Checkbox, Col, Form, Input, message, Row } from "antd";
import "antd/dist/antd.css";
import Layout from "antd/lib/layout/layout";
import { useEffect, useReducer, useState } from "react";
import { IoMail } from "react-icons/io5";
import "./App.scss";
import logo from "./assets/images/ibee-hosting-logo.png";
import { apiURL } from "./common/crud";
import AdminModule from "./components/AdminModule";
import ResetPassword from "./components/Users/resetPassword";
import { Context } from "./context";
import { initialState, reducer } from "./reducer";
import { LOGIN_SUCCESS } from "./reducer/types";

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [signedIn, setSignedIn] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginEmail, setLoginEmail] = useState(null);
  const [formToggle, setFormToggle] = useState("showLogin");
  const [verify2fa, setVerify2fa] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [signupError, setSignupError] = useState(null);
  const [showSignupSuccess, setShowSignupSuccess] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const [form] = Form.useForm();

  const switchForm = (val) => {
    setFormToggle(val);
  };

  const LoginFormSuccess = (values) => {
    console.log("Login values", values);
    setLoginError(null);
    // setLoginErr(null);
    setLoading(true);
    setLoginEmail(values.username);
    const bodyData = {
      email: values.username,
      password: values.password,
      client_id: "user_portal",
    };

    let formBody = [];
    Object.keys(bodyData).map((key) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(bodyData[key]);
      return formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join("&");

    console.log("Form Body - ", formBody);
    fetch(`${apiURL}/oauth2/token`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Login res - ", data);
        setLoading(false);
        if (data.detail) {
          setLoginError(data.detail);
          // setLoginErr(data.detail);
        } else if (data.access_token === null && data.to_do === "verify2fa") {
          setVerify2fa(true);
        } else {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              token: data.access_token,
              userId: data.id,
            },
          });
          setSignedIn(true);
          console.log("State - ", state);
        }
      })
      .catch((err) => {
        console.log("Login err - ", err);
      });
  };

  const callVerify2fa = (values) => {
    console.log("Login values", values);
    // setLoginErr(null);
    setLoading(true);

    const bodyData = {
      email: loginEmail,
      otp: values.otp,
      client_id: "user_portal",
    };

    let formBody = [];
    Object.keys(bodyData).map((key) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(bodyData[key]);
      return formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join("&");
    setLoginError(null);

    console.log("Form Body - ", formBody);
    fetch(`${apiURL}/oauth2/verify2fa`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Login res - ", data);
        setLoading(false);
        if (data.detail) {
          // setLoginErr(data.detail);
        } else {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              token: data.access_token,
              userId: data.id,
            },
          });
          setSignedIn(true);
          console.log("State - ", state);
        }
      })
      .catch((err) => {
        console.log("Login err - ", err);
      });
  };

  //  Terms & Conditions in change
  const termsOnChange = (e) => {
    console.log("Terms conditions checked - ", e);
    setTermsChecked(e.target.checked);
  };

  const ForgotPasswordFormSuccess = (values) => {
    console.log("forgot form values - ", values);
    setLoginError(null);

    fetch(
      `${apiURL}/users/forgot-password?email=${
        values.username
      }&client_id=${"user_portal"}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: "",
      }
    )
      .then((res) => {
        if (res.status === 200) {
          message.success("Reset password Email Sent successfully!");
          setFormToggle("showLogin");
        } else if (res.status === 404) {
          message.error("Invalid credentials!!!");
        }
      })
      .catch((err) => {
        console.log("Login error - ", err.details);
      });
  };

  const handleRegister = (values) => {
    setSignupError(null);
    setLoginError(null);
    setLoading(true);
    console.log(values);
    const bodyData = {
      email: values.username,
    };

    let formBody = [];
    Object.keys(bodyData).map((key) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(bodyData[key]);
      return formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join("&");
    setLoginError(null);
    console.log("Form Body - ", formBody);
    fetch(`${apiURL}/oauth2/register`, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((res) => {
        console.log("Login res - ", res.json());
        setLoading(false);
        console.log("State - ", state);
        if (res.status === 200) {
          setShowSignupSuccess(true);
        } else if (res.status === 401) {
          setSignupError("Email already exits");
        }
      })
      .catch((err) => {
        console.log("Login err - ", err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const userId = sessionStorage.getItem("userId");

    const urlParam = window.location.pathname;
    console.log("url path = ", urlParam.split("/"));
    if (
      urlParam &&
      (urlParam.split("/")[2] === "invite" ||
        urlParam.split("/")[2] === "forgot")
    ) {
      console.log("Show forgot passwrod fomr");
      sessionStorage.setItem("passTok", urlParam.split("/")[3]);
      dispatch({
        type: "LOGOUT_SUCCESS",
      });
      setFormToggle("resetPassword");
    }

    console.log("State - ", state);
    if (token && userId && token !== undefined && userId !== undefined) {
      // setToken(token)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token,
          userId,
        },
      });
      setSignedIn(true);
    }
  }, []);

  if (signedIn) {
    return (
      <>
        {/* <Context.Provider value={{ apiUrl, token }}>
        <AdminModule />
      </Context.Provider> */}
        <Context.Provider value={{ state: state, dispatch: dispatch }}>
          <AdminModule />
        </Context.Provider>
      </>
    );
  } else {
    return (
      <Layout
        align='center'
        style={{ minHeight: "100vh" }}
        className='d-flex align-items-center justify-content-center full-width'>
        <Row className='w-100'>
          <Col
            span='18'
            offset='3'
            className='bg-white shadow rounded-3'
            style={{ overflow: "hidden" }}>
            {/* <Row className="">
                <Col span="24">
                  <div className="full-width text-center branding-logo p-0 py-2 bg-light">
                    <img src={logo} className="logo" alt="IBEE Hosting" />
                  </div>
                </Col>
              </Row> */}
            <Row align-items='stretch' className='my-2'>
              <Col span='12'>
                {formToggle === "showLogin" ? (
                  <>
                    {/* Login Starts */}
                    <div className='full-width login-block px-3 '>
                      <div className='full-width text-start branding-logo pb-2'>
                        <img
                          src={logo}
                          className='logo d-inline-block'
                          alt='IBEE Hosting'
                        />
                      </div>
                      <div className='login-signup-header full-width'>
                        <div className='login-signup-main-title full-width'>
                          Login Now
                        </div>
                        <div className='login-signup-short-info full-width'>
                          Dear user, log in to access the Self Service Panel.
                        </div>
                      </div>
                      <div className='full-width login-form-block'>
                        {loginError ? (
                          <div className='full-width mb-2 text-start'>
                            <Alert
                              description={loginError}
                              type='error'
                              closable
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className='full-width'>
                          <Form
                            form={form}
                            layout='vertical'
                            onFinish={
                              verify2fa ? callVerify2fa : LoginFormSuccess
                            }
                            onFinishFailed={onFinishFailed}>
                            {verify2fa ? (
                              <Form.Item
                                label='Enter Otp'
                                name='otp'
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter yourotp",
                                  },
                                ]}>
                                <Input className='form-control' />
                              </Form.Item>
                            ) : (
                              <>
                                <Form.Item
                                  label='Email Address'
                                  name='username'
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter your Email Id!",
                                    },
                                  ]}>
                                  <Input className='form-control' />
                                </Form.Item>
                                <Form.Item
                                  label='Password'
                                  name='password'
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter your Password!",
                                    },
                                  ]}>
                                  <Input
                                    type='password'
                                    className='form-control'
                                  />
                                </Form.Item>{" "}
                              </>
                            )}
                            <Form.Item className='flex'>
                              <div className='d-flex align-items-center justify-content-start'>
                                <Button
                                  htmlType='submit'
                                  disabled={loading}
                                  className='loginBtn align-self-center btn-primary'
                                  type='primary'
                                  // onClick={() => loginNow()}
                                >
                                  Login
                                </Button>
                                <span className='signup-block'>
                                  or{" "}
                                  <Button
                                    type='link'
                                    className='joinNowLink'
                                    onClick={() => setFormToggle("showSignup")}>
                                    Signup{" "}
                                  </Button>
                                </span>
                              </div>
                            </Form.Item>
                            <Form.Item className='mb-0'>
                              <div className='full-width d-flex justify-content-start'>
                                <Button
                                  type='link'
                                  className='p-0'
                                  onClick={() =>
                                    setFormToggle("showForgotPassword")
                                  }>
                                  Forgot Password?
                                </Button>
                              </div>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                    </div>
                    {/* Login Starts */}
                  </>
                ) : formToggle === "showForgotPassword" ? (
                  <>
                    {/* Forgot Password Starts */}
                    <div className='full-width signup-block px-3'>
                      <div className='login-signup-header full-width'>
                        <div className='login-signup-main-title full-width'>
                          Forgot Password
                        </div>
                        <div className='login-signup-short-info full-width'>
                          To reset your password, please enter your user email.
                        </div>
                      </div>

                      <div className='full-width login-form-block'>
                        <Form
                          form={form}
                          layout='vertical'
                          onFinish={ForgotPasswordFormSuccess}>
                          <Form.Item label='Email Address' name='username'>
                            <Input className='form-control' />
                          </Form.Item>

                          <Form.Item className='flex'>
                            <div className='d-flex align-items-center justify-content-start'>
                              <Button
                                htmlType='submit'
                                disabled={loading}
                                className='loginBtn align-self-center btn-primary'
                                type='primary'
                                // onClick={() => loginNow()}
                              >
                                Send
                              </Button>
                              <span className='signup-block'>
                                or{" "}
                                <Button
                                  type='link'
                                  className='joinNowLink'
                                  onClick={() => setFormToggle("showSignup")}>
                                  Signup{" "}
                                </Button>
                              </span>
                            </div>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>

                    {/* Forgot Password Ends */}
                  </>
                ) : formToggle === "resetPassword" ? (
                  <ResetPassword />
                ) : formToggle === "showSignup" ? (
                  <>
                    {/* Signup Starts */}
                    <div className='full-width signup-block px-3'>
                      <div className='login-signup-header full-width'>
                        <div className='login-signup-main-title full-width'>
                          Signup Now
                        </div>
                        <div className='login-signup-short-info full-width'>
                          To create new account, please enter your user email
                          address.
                        </div>
                      </div>

                      {/* Signup Form */}
                      {!showSignupSuccess ? (
                        <div className='full-width login-form-block'>
                          <div className=''>
                            {signupError ? (
                              <Alert
                                showIcon
                                className='text-start mb-2'
                                type='error'
                                message={signupError}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <Form
                            form={form}
                            layout='vertical'
                            onFinish={handleRegister}>
                            <Form.Item
                              label='Email Address'
                              name='username'
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your email to Singup",
                                },
                              ]}>
                              <Input className='form-control' />
                            </Form.Item>

                            <Form.Item
                              name='termsConditions'
                              className='text-start mb-1'
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value
                                      ? Promise.resolve()
                                      : Promise.reject(
                                          new Error(
                                            "Please Check Terms & Conditions to Join"
                                          )
                                        ),
                                },
                              ]}
                              valuePropName='checked'>
                              <Checkbox className='justify-content-start fs-7 signupCheckbox'>
                                By checking this box you confirm that you have
                                read and understood our{" "}
                                <a href='#'>Privacy policy</a>,
                                <a href='#'>Website Terms of Use</a>, the
                                General Terms and Conditions and the policies at{" "}
                                <a href='#'>https://www.ibee.ai/legal</a>
                              </Checkbox>
                            </Form.Item>

                            <Form.Item
                              name='newsLetter'
                              className='text-start mb-1'>
                              <Checkbox
                                onChange=''
                                className='justify-content-start fs-7 signupCheckbox'>
                                I want to receive a marketing newsletter
                              </Checkbox>
                            </Form.Item>

                            <Form.Item className='flex'>
                              <div className='d-flex align-items-center justify-content-start'>
                                <Button
                                  htmlType='submit'
                                  disabled={loading}
                                  className='loginBtn align-self-center btn-primary'
                                  type='primary'
                                  // onClick={() => loginNow()}
                                >
                                  Join
                                </Button>
                                <span className='signup-block'>
                                  or{" "}
                                  <Button
                                    type='link'
                                    className='joinNowLink'
                                    onClick={() => setFormToggle("showLogin")}>
                                    Login{" "}
                                  </Button>
                                </span>
                              </div>
                            </Form.Item>
                          </Form>
                        </div>
                      ) : (
                        <div className='full-width text-start'>
                          <div className='full-width p-2 success-light-bg signup-email-sent'>
                            <div className='email-sent-icon'>
                              <IoMail />
                            </div>
                            <div className='email-sent-info'>
                              <div className='full-width big'>
                                We have sent you email!
                              </div>
                              <div className='full-width'>
                                To proceed further, please click the link sent
                                to your email to create account.
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Signup Ends */}
                  </>
                ) : null}
              </Col>
              <Col span='12' className='border-start'>
                <div className='full-width px-3 text-left'>
                  <ul className='login-signup-benfits list-unstyled full-width mb-0'>
                    <li>
                      <div className='each-login-signup-benefit full-width'>
                        <div className='each-benefit-icon'></div>
                        <div className='each-benefit-content'>
                          40 Gbps private and public redundant network
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='each-login-signup-benefit full-width'>
                        <div className='each-benefit-icon'></div>
                        <div className='each-benefit-content'>
                          Cloud integration
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='each-login-signup-benefit full-width'>
                        <div className='each-benefit-icon'></div>
                        <div className='each-benefit-content'>
                          Designed for clusters
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='each-login-signup-benefit full-width'>
                        <div className='each-benefit-icon'></div>
                        <div className='each-benefit-content'>
                          FREE cross-continental network
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    );
  }
};

export default App;
