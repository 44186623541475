import React, {useEffect, useState} from 'react';
import {notification} from 'antd';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

export default function CheckoutForm( props ) {
  const stripe = useStripe();
  const elements = useElements();

  const [stripeMessage, setstripeMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setstripeMessage('Payment succeeded!');
          break;
        case 'processing':
          setstripeMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setstripeMessage('Your payment was not successful, please try again.');
          break;
        default:
          setstripeMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const data = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: 'http://localhost:3000',
      },
      redirect: 'if_required',
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (data.error) {
      setstripeMessage(`An unexpected error occured. , ${data.error.stripeMessage}`);
      setIsLoading(false);
    }
    if (data.paymentIntent && data.paymentIntent.status === 'succeeded') {
      const razorBody = {paymentId: data.paymentIntent.id,
        signature: data.paymentIntent.status, gateway: 'stripe',
      };

      props.postCaptuePayment(props.inv_id, props.stripe_id, razorBody);
      setstripeMessage('Success');
      notification.success('Payment succesful');
    }
    console.log(data, props );
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}
        </span>
      </button>
      {/* Show any error or success stripeMessages */}
      {stripeMessage && <div id="payment-stripeMessage">{stripeMessage}</div>}
    </form>
  );
}
