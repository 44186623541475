import React, { useEffect, useContext, useState } from 'react';
import { Layout, Row, Col, Result, BackTop } from 'antd';
import { IoArrowUp } from 'react-icons/io5';
// Custom imports
import Header from '../Header';
import Footer from '../Footer';
import SideNavigation from '../SideNavigation';
import DedicatedServersManage from '../DedicatedServers/serversManage.jsx';
import DedicatedServersOrder from '../DedicatedServers/index.jsx';
import CloudServersManage from '../CloudServersManage';
import CloudServersImages from '../CloudServersImages';
import CloudServersVolumes from '../CloudServersVolumes';
import { Context } from '../../context';
import ServerConfiguration from '../DedicatedServers/serverconfig.jsx';
import Cart from '../Cart';
import ProfileAccount from '../ProfileAccount/index.jsx';
import Dashboard from '../Dashboard';
import ProfileContacts from '../ProfileAccount/profileContacts.jsx';
import ProfileGroups from '../ProfileAccount/profilegroups.jsx';
import ProfileSSHKeys from '../ProfileAccount/profilessh.jsx';
import CloudStorage from '../CloudStorage';
import Invoices from '../Cart/invoices.jsx';
import InvoiceDetail from '../Cart/invoiceDetail.jsx';
import BillingOrders from '../Billing/billingorders';
import CheckOut from '../Cart/checkout.jsx';
import UpgradeCheckOut from '../Cart/upgradeCheckout';
import OrderDetail from '../Billing/orderDetail';
import UpdateDedicatedServer from '../DedicatedServers/updateDedicatedServer';

const AdminModule = () => {
  const { state, dispatch } = useContext(Context);
  const { Sider, Content } = Layout;

  const page = {
    dashboard: <Dashboard />,
    dsManage: <DedicatedServersManage />,
    dsOrder: <DedicatedServersOrder />,
    ccCreateManage: <CloudServersManage />,
    ccImages: <CloudServersImages />,
    ccVolumes: <CloudServersVolumes />,
    serverConfiguration: <ServerConfiguration />,
    cart: <Cart />,
    profileAccount: <ProfileAccount />,
    profileContacts: <ProfileContacts />,
    profileGroups: <ProfileGroups />,
    profileSSHKeys: <ProfileSSHKeys />,
    cloudStorage: <CloudStorage />,
    billingInvoices: <Invoices />,
    invoiceDetail: <InvoiceDetail />,
    billingOrders: <BillingOrders />,
    checkout: <CheckOut />,
    orderDetail: <OrderDetail />,
    updateDedicatedServer: <UpdateDedicatedServer />,
    upCheckout : <UpgradeCheckOut/>
  };

  const style = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: '#1088e9',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
  };

  useEffect(() => {
    console.log('AdminModule state - ', state.page);
    return () => {};
  }, [state.page]);

  return (
    <Layout className="full-width main">
      <Header />

      <Layout className="full-width">
        <Row
          align="stretch"
          className="full-width"
          style={{ overflowY: 'auto' }}
          id="main"
        >
          <Col span="4" style={{ backgroundColor: '#fff' }}>
            <SideNavigation />
          </Col>
          <Col span="20" className="p-2">
            <Row>
              <Col span="24" className="bg-white">
                {page[state.page] || (
                  <Result
                    status="warning"
                    title="You don't have permissions to view page"
                    extra=""
                  />
                )}
              </Col>
            </Row>

            <BackTop>
              <div style={style}>
                <IoArrowUp />
              </div>
            </BackTop>
          </Col>
        </Row>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default AdminModule;
