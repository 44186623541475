import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  PAGE,
  BREADCRUMB,
  CART_ITEMS_NUMBER,FX
} from "./types";

const localUserId = sessionStorage.getItem("userId");
const localToken = sessionStorage.getItem("token");
const localUserName = sessionStorage.getItem("userName");

export const initialState = {
  isAuthenticated: false,
  token: null,
  userId: null,
  userName: null,
  //  page: "dsManage",
  page: "dashboard",
  breadcrumb: null,
  userData: null,
  cartCount: 0,
  orderCount: 0,
  fx: 'INR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    //Login Success
    case LOGIN_SUCCESS:
      sessionStorage.setItem("token", action.payload.token);
      sessionStorage.setItem("userId", action.payload.userId);
      sessionStorage.setItem("userName", action.payload.userName);
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        userId: action.payload.userId,
        userName: action.payload.userName,
      };

    case "USER_DATA":
      return {
        ...state,
        userData: action.payload.userData,
      };
    // Logout Success
    case LOGOUT_SUCCESS:
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("userName");

      return {
        ...state,
        token: null,
        userId: null,
        userName: null,
      };

    //Update Breadcrumb
    case BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.payload.breadcrumb,
      };

    // Set Page
    case PAGE:
      return {
        ...state,
        page: action.payload.page,
      };

    //  Cart Items Number
    case "CART_COUNT":
      return {
        ...state,
        cartCount: action.payload.cartCount,
      };

    case "ORDER_COUNT":
      return {
        ...state,
        orderCount: action.payload.orderCount,
      };

    case "FX":
      return {
        ...state,
        fx : action.payload.fx,
      }

    default:
      return {
        ...state,
      };
  }
};
