import React, {useState, useContext, useEffect} from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Tooltip,
  Select,
  Table,
  Tag,
  message,
} from 'antd';
import {FaRupeeSign, FaCloudDownloadAlt, FaDollarSign} from 'react-icons/fa';
import {
  IoAdd,
  IoPencil,
  IoTrash,
  IoInformationCircle,
  IoCheckmarkCircle,
  IoAlertCircle,
  IoArrowBack,
} from 'react-icons/io5';

// Custom imports
import {Context} from '../../context';
import {BREADCRUMB} from '../../reducer/types';
import {loadPage, EditForm} from '../../SharedFunctions';
import {POST, apiURL, GET, PUT, DELETE} from '../../common/crud';

const Invoices = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [cust, setCust] = useState(
      JSON.parse(sessionStorage.getItem('userData')),
  );
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [chasisSKU, setChasisSKU] = useState('');
  const [chasisTableData, setChasisTableData] = useState(null);
  const [ramSKU, setRamSKU] = useState(null);
  const [hddSKU, setHddSKU] = useState(null);
  const [detailObj, setDetailObj] = useState(null);

  const {state, dispatch} = useContext(Context);
  const {Option} = Select;
  const {TextArea} = Input;

  const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
  };

  const getDuedays = (invoice_date) => {
    const diffinMs = Date.now() - new Date(invoice_date);
    const days = parseInt(diffinMs / (1000 * 60 * 60 * 24));
    if (days > 0) return `OVERDUE BY ${days} DAYS`;
    if (days === 0) return `DUE TODAY`;
    if (days < 0) return `DUE IN ${days} DAYS`;
  };

  // Table Columns
  const ordersCols = [
    {
      title: 'Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: '125px',
      render: (text, id) =>
        text ? new Date(text).toISOString().substring(0, 10) : null,
    },
    {
      title: 'Invoice#',
      dataIndex: 'invoice_serial',
      key: 'invoice_serial',
      render: (e, record) => (
        <Button
          type="link"
          className="p-0"
          onClick={() => goToOrderDetail(record)}
        >
          {
                          record._id.substr(record._id.length - 5)
                        }
        </Button>
      ),
    },
    {
      title: 'Billing Period',
      dataIndex: 'last_invoice',
      key: 'last_invoice',
      render: (e, record) =>
        record.last_invoice ?
          new Date(record.last_invoice).toISOString().substring(0, 10) +
            ' to ' +
            addDays(new Date(record.last_invoice), 30)
                .toISOString()
                .substring(0, 10) :
          null,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      className: 'text-end',
      width: '150px',
      render: (text, record) => (
        <>
          {text !== 'NA' && text !== 'N/A' ? (
            <>
              {'currency_symbol' in record ? record.currency_symbol+" " : record.currency+" "}
              {Number(text ).toFixed(2)}
            </>
          ) : (
            {text}
          )}
        </>
      ),
    },
    {
      title: 'Balance due',
      dataIndex: 'amount_due',
      key: 'amount_due',
      className: 'text-end',
      width: '150px',
      render: (text, record) => (
        <>
          {text !== 'NA' && text !== 'N/A' ? (
            <>
              {'currency_symbol' in record ? record.currency_symbol+" " : record.currency+" "}
              {text }
            </>
          ) : (
            {text}
          )}
        </>
      ),
    },
    {
      title: 'Pay Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (e, record) => (
        <>
          {record.payment_status === 'paid' ?
            record.payment_status :
            getDuedays(record.invoice_date)}
          {/* record.amount_due === 0 ? (
            <Tag color="green">{record.payment_status}</Tag>
          ) : record.amount_due > 0 ? (
            <>
              <Button className="order-now-btn"
                         onClick={()=>  {
                          loadPage("checkout", dispatch);
                          sessionStorage.setItem("checkout",JSON.stringify( { ...record , co_type : "invoice" }) );
                         } }>
                          Pay { record.amount_due/100}
                         </Button>
            </>
          ) : record.payment_status === 'payment failed' || record.payment_status === "pending" ? (
            <>
              <Tag color="orange">{record.payment_status}</Tag>
            </>
          ) : (
            ''
          ) */}
        </>
      ),
    },
    {
      title: 'Inv status',
      dataIndex: 'inv_status',
      key: 'inv_status',
      width: 100,
      render: (e, record) => record.inv_status,
    },
  ];

  // Show Order Details
  const goToOrderDetail = async (record) => {
   sessionStorage.setItem('invoiceId', record._id);
   loadPage('invoiceDetail', dispatch);
  };

  // Show Customer Detail
  const goToCustomerDetail = (id) => {
    loadPage('customerDetails', dispatch);
  };

  // Row Data on Edit
  const tableRowEdit = (key) => {
    console.log('Got Row ID - ', key);
    // EditForm(key, "addLocation", dispatch);
    setUpdateModal(true);
  };

  // Create Location SUCCESS
  const createNewLocationSuccess = () => {};

  // Create Location FAILED
  const createNewLocationFailed = () => {};

  // Common onChange
  const commonOnChange = () => {};

  // Confirm Modal Delete
  const deleteItem = (id) => {
    setDeleteModal(true);
  };

  // Row Data on Delete
  const tableRowDelete = (key) => {
    console.log('Got Row Id, DELETE - ', key);

    // For Demo only Check & Delete
    const updatedTable =
      tableData !== null && tableData.length > 1 ?
        tableData.splice(key, 1) :
        [];

    setTableData(updatedTable);
  };

  // Row Selection
  const rowSelection = {
    onChange: (e) => {
      console.log('Row selection on change - ', e);
    },
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  const showModal = () => {
    setUpdateModal(true);
  };

  const handleCancel = () => {
    setUpdateModal(false);
  };

  // Chasis SKU on change
  const chasisSKUOnChange = (value) => {
    setChasisSKU(value);
  };

  // RAM SKU onChange
  const ramSKUOnChange = (value) => {
    setRamSKU(value);
  };

  // HDD SKu onChange
  const hddSKUOnChange = (value) => {
    setHddSKU(value);
  };

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => loadPage('/', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>Invoices</Breadcrumb.Item>
    </Breadcrumb>
  );

  const getInvoices = () => {
    GET(`invoices/customer/${state.userId}`, state.token)
        .then((res) => {
          console.log(res.data);
          setTableData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  //  Currency Symbol
  const currencySymbol = (txt, color) => {
    switch (txt) {
      case 'INR':
        return (<FaRupeeSign color={color ? color : '#777'} />)
      case 'USD' :
        return (<FaDollarSign color={color ? color : '#777'} />)
      case 'SGD' :
        return (<FaDollarSign  color={color ? color : '#777'} />)
      case 'AUD' :
        return (<FaDollarSign  color={color ? color : '#777'} />)
      default:
        break;
    }
  }

  useEffect(() => {
    // Bredcrumb
    getInvoices();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });


    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return detailObj === null ? (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Invoices
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24" className="mb-1">
          <Row gutter="30">
            <Col span="12">
              <Row gutter="30">
                <Col>
                  <Select
                    placeholder="Select Location"
                    defaultValue={'All Locations'}
                    onChange={commonOnChange}
                    style={{width: '200px'}}
                  >
                    <Option value="All Locations">All Locations</Option>
                    <Option value="Hyderabad,India">Hyderabad, India</Option>
                    <Option value="Mumbai,India">Mumbai, India</Option>
                  </Select>
                </Col>
                <Col>
                  <Select
                    placeholder="Select Status"
                    defaultValue="All"
                    onChange={commonOnChange}
                    style={{width: '200px'}}
                  >
                    <Option value="All">All Payment Status</Option>
                    <Option value="paid">paid</Option>
                    <Option value="payment failed">payment failed</Option>
                    <Option value="not paid">not paid</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col span="12" className="text-end">
              Period:&nbsp;&nbsp;
              <Select
                placeholder="Select Year"
                defaultValue={'2021'}
                onChange={commonOnChange}
                style={{width: '120px'}}
                className="text-start"
              >
                <Option value="2021">2021</Option>
                <Option value="2022">2020</Option>
              </Select>
              <Select
                placeholder="Select Month"
                defaultValue={'March'}
                onChange={commonOnChange}
                style={{width: '120px'}}
                className="text-start"
              >
                <Option value="January">January</Option>
                <Option value="February">February</Option>
                <Option value="March">March</Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          <Table columns={ordersCols} dataSource={tableData} />
        </Col>
      </Row>
    </>
  ) : (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col
              span="14"
              className="page-title d-inline-flex aling-items-center"
            >
              <Button
                type="link"
                size="small"
                onClick={() => setDetailObj(null)}
              >
                <IoArrowBack size="20" />
              </Button>
              Invoice Detail
            </Col>
            <Col span="10" className="text-end">
              <Button type="default" size="medium" className="btn-success">
                <FaCloudDownloadAlt className="me-1" color="#fff" /> Download
                Invoice
              </Button>

              {detailObj.invoice.payment_status !== 'paid' &&
              detailObj.invoice.payment_status !== 'refund_initiated' ? (
                <Button
                  className="btn-primary ms-2"
                  onClick={() => {
                    sessionStorage.setItem(
                        'checkout',
                        JSON.stringify({...detailObj.order, co_type: 'cart_order'}),
                    );
                    loadPage('checkout', dispatch);
                  }}
                >
                  Pay {detailObj.invoice.amount_due }
                </Button>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24 mb-2">
          <Row span="40">
            <Col span="11">
              {detailObj ? (
                <ul className="list-unstyled full-width customer-details-list">
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">Company Name:</span>
                      <span className="each-customer-details">
                        IBEE Solutions
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">
                        Customer Name:
                      </span>
                      <span className="each-customer-details">
                        {cust ? cust.display_name : ''}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width text-truncate">
                      <span className="each-customer-title">Email:</span>
                      <span className="each-customer-details">
                        <Tooltip title="Verified">
                          <IoCheckmarkCircle
                            color="var(--bs-success)"
                            size="18"
                          />
                        </Tooltip>
                        {cust ? cust.email : null}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">Phone:</span>
                      <span className="each-customer-details">
                        <Tooltip title="Not Verified">
                          <IoAlertCircle color="var(--bs-warning)" size="18" />
                        </Tooltip>
                        {cust ? cust.phone : 'N/A'}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">GST:</span>
                      <span className="each-customer-details"> {'To Do'}</span>
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">
                        KYC Verification:
                      </span>
                      <span className="each-customer-details">
                        <Tooltip title="Verified">
                          <IoCheckmarkCircle
                            color="var(--bs-success)"
                            size="18"
                          />
                        </Tooltip>{' '}
                        {'To Do'}
                      </span>
                    </div>
                  </li>
                </ul>
              ) : (
                ''
              )}
            </Col>

            <Col span="9" offset="4">
              <ul className="list-unstyled full-width customer-details-list">
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Invoice Nos.:</span>
                    <span className="each-customer-details">
                      {detailObj.invoice.invoice_serial}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Invoice Date:</span>
                    <span className="each-customer-details">
                      {detailObj.invoice.invoice_date.split('T')[0]}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Payment Status</span>
                    <span className="each-customer-details">
                      {detailObj.invoice.payment_status === 'paid' ? (
                        <Tag color="green">
                          {detailObj.invoice.payment_status}
                        </Tag>
                      ) : (
                        <Tag color="red">
                          {detailObj.invoice.payment_status}
                        </Tag>
                      )}
                    </span>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          {/* <Table
            columns={ordersCols}
            dataSource={tableData}
            pagination={false}
          /> */}
          {detailObj ? (
            <div className="ant-table">
              <div className="ant-table-container">
                <div className="ant-table-content" style={{fontSize: '14px'}}>
                  <table
                    className="w-100 table-striped"
                    style={{tableLayout: 'auto'}}
                  >
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell">Item Description</th>
                        <th className="ant-table-cell" width="70">
                          Qty.
                        </th>
                        <th className="ant-table-cell" width="100">
                          Item Price/Unit
                        </th>
                        <th className="ant-table-cell" width="100">
                          Discount
                        </th>
                        <th className="ant-table-cell text-end" width="120">
                          Total Tax
                        </th>
                        <th className="ant-table-cell text-end" width="130">
                          Sub Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="ant-table-tbody">
                      {detailObj.order.cart_items.map((item, ind) => (
                        <>
                        <tr>
                          <td className="ant-table-cell">
                            <strong>
                              {ind + 1}. {item.product_info.title}
                            </strong>
                          </td>
                          <td className="ant-table-cell">{item.quantity}</td>
                          <td className="ant-table-cell text-end">
                            {item.product_info.base_currency_symbol}{' '}
                            {item.product_info.price}
                          </td>
                          <td className="ant-table-cell">
                            {item.product_info.base_currency_symbol}{' '}
                          {detailObj.invoice.discount_value}
                          </td>
                          <td className="ant-table-cell text-end">
                            {item.product_info.base_currency_symbol}{' '}
                            {Number(item.product_info.price_with_tds) - Number(item.product_info.price)}
                          </td>
                          <td className="ant-table-cell text-end">
                           {item.product_info.base_currency_symbol}{' '}
                            {item.product_info.price_with_tds}
                          </td>
                        </tr>
                         {
                           item.addons && item.addons.length > 0 ?
                            item.addons.map((addon) => (
                              <tr>
                                <td className="ant-table-cell ps-3">
                                  <span className='fst-italic'>
                                    {addon.addon_info.category.toUpperCase()}
                                  </span>
                                  {' '}{addon.addon_info.title ? `: ${addon.addon_info.title}` : ''}
                                </td>
                                <td className="ant-table-cell">
                                  {addon.quantity}
                                </td>
                                <td className="ant-table-cell text-end">
                                  {addon.addon_info.base_currency_symbol} {addon.addon_info.price}
                                </td>
                                <td className="ant-table-cell">
                                  {addon.addon_info.base_currency_symbol}
                                  {detailObj.invoice.discount_value}
                                </td>
                                <td className="ant-table-cell text-end">
                                  {addon.addon_info.base_currency_symbol}
                                  {Number(addon.addon_info.price_with_tds - addon.addon_info.price).toFixed(2)}
                                </td>
                                <td className="ant-table-cell text-end">
                                  {addon.addon_info.base_currency_symbol}
                                  {addon.addon_info.price_with_tds}
                                </td>
                              </tr>
                            )) : ''
                          }
                        </>
                      ))}
                      <tr style={{fontSize: '17px'}}>
                        <td className="ant-table-cell"></td>
                        <td className="ant-table-cell"></td>
                        <td className="ant-table-cell text-end"></td>
                        <td colSpan={2} className="ant-table-cell text-end">
                          <strong>Grand Total:</strong>
                        </td>
                        <td className="ant-table-cell text-end">
                          {currencySymbol(detailObj.order.currency)}{' '}

                          <strong  style={{lineHeight: 1}}>{detailObj.order.cart_price}</strong>
                        </td>
                      </tr>
                       <tr style={{fontSize: '17px'}}>
                        <td className="ant-table-cell"></td>
                        <td className="ant-table-cell"></td>
                        <td className="ant-table-cell text-end"></td>
                        <td className="ant-table-cell text-end"  colSpan={2}>
                          <strong>Amount Paid:</strong>
                        </td>
                        <td className="ant-table-cell text-end">
                          {currencySymbol(detailObj.order.currency)}{' '}

                          <strong  style={{lineHeight: 1}}>{detailObj.invoice.amount_paid}</strong>
                        </td>
                      </tr>
                      <tr style={{fontSize: '17px'}}>
                        <td className="ant-table-cell"></td>
                        <td className="ant-table-cell"></td>
                        <td className="ant-table-cell text-end"></td>
                        <td colSpan={2} className="ant-table-cell text-end text-danger">
                          <strong>Amount Due:</strong>
                        </td>
                        <td className="ant-table-cell text-end text-danger">
                          {currencySymbol(detailObj.order.currency, '#dc3545')}{' '}
                          <strong style={{lineHeight: 1}}>{detailObj.invoice.amount_due}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </Col>
        <Col span="24" className="mt-2">
          <div className="full-width mb-2">
            <strong>Offline payment Bank details</strong>
          </div>
          <div className="full-width">
            Account Name: IBEE SOFTWARE SOLUTIONS PRIVATE LIMITED <br />
            Account No.: 020205001561
            <br />
            Bank Name: ICICI Bank
            <br />
            IFSC Code: ICIC0000202
            <br />
            Branch: Punjagutta, Hyderabad
            <br />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Invoices;
