import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Select,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Space,
  Tag,
  message,
} from 'antd';
import React, { useContext, useEffect, useState,useCallback } from 'react';
import { BiRupee } from 'react-icons/bi';
import { BsInfoCircleFill } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa';
import { ImGift } from 'react-icons/im';
import { IoCart, IoAdd, IoRemoveOutline } from 'react-icons/io5';
import { GET, POST } from '../../common/crud';
import { getHostname } from '../../common/pophostname';
import {raid0,raid1,raid5,raid6,raid10 } from '../../common/raid';
import { Context } from '../../context';
import { BREADCRUMB } from '../../reducer/types';
import { loadPage } from '../../SharedFunctions';

// Custom Imports
// raid space calc copied from ht tps: //rd config urator.net gea r.com/ra id/js/lib rary.js
import './serverConfiguration.scss';

const ServerConfiguration = () => {
  const [selectRAM, setSelectRAM] = useState(null);
  const [hostNames, setHostNames] = useState([getHostname()]);
  const [selectOS, setSelectOS] = useState(null);
  const [selectRaid, setSelectRaid] = useState('RAID0');
  const [load,setLoad] = useState(false);
  const [selectHDD, setSelectHDD] = useState([]);
  const [selectIpAddress, setSelectIpAddress] = useState(null);
  const [selectIpq, setSelectIpq] = useState(0);
  const [selectBandwidth, setSelectBandwidth] = useState(null);
  const [selectportspeed, setSelectportSpeed] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [ramaddons, setramAddons] = useState([]);
  const [hddaddons, sethddAddons] = useState([]);
  const [ipaddons, setipAddons] = useState([]);
  const [osaddons, setosAddons] = useState([]);
  const [portaddons, setportAddons] = useState([]);
  const [raidaddons, setraidAddons] = useState([]);
  const [selectedR, setSelectedR] = useState(0);
  const [bandwidthaddons, setbandwidthAddons] = useState([]);
  const [localCart, setLocalCart] = useState(0);
  const [similarProds,setSimilarProds] = useState([]);
  const [activeProduct,setactiveProduct] = useState(null);
  // Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { Option } = Select;
  const { state, dispatch } = useContext(Context);
  const activeProductId = JSON.parse(sessionStorage.getItem('activeProduct'));
  window.raid0 = raid0;
  window.raid1 = raid1;
  window.raid5 = raid5;
  window.raid6 = raid6;
  window.raid10 = raid10;
  const defaultHddInterface = {
    name: null,
    price: 0,
    price_with_tds: 0,
    default: false,
  };
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => loadPage('/', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => loadPage('dsOrder', dispatch)}
        >
          Dedicated Server
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Server Configuration</Breadcrumb.Item>
    </Breadcrumb>
  );

  const getServerAddons = (chid) => {
    const li = ['os', 'ram','hdd','location'];

    li.map((obj, i) => {
    GET(`server-addons/${obj}/${chid}`, state.token)
      .then((res) => {
        console.log(res.data);
        if(obj === 'ram')
        setramAddons(res.data.server_addons);
        if(obj === 'hdd'){
          sethddAddons(res.data.server_addons);
          //computeRaidSpace();  
        }
      if (obj === 'os') setosAddons(res.data.server_addons);
      if(obj === 'location') setSimilarProds(res.data.server_addons);
      })
      .catch((err) => {});
    })
  }

  const getOtherAddons = ( achdds,location) => {
    const li = [ 'bandwidth', 'ipaddress', 'raid', 'portspeed'];
    let temp = [];
    li.map((obj, i) => {
      GET(`add-ons/${obj}?location=${location}`, state.token)
        .then((res) => {
          if (obj === 'bandwidth') {
            setbandwidthAddons(res.data.addons);
          if(res.data.addons.length > 0)
            setSelectBandwidth( JSON.stringify(res.data.addons[0])  ) ; 
          }
          if (obj === 'ipaddress') setipAddons(res.data.addons);
          if (obj === 'raid'){ 
            setraidAddons(res.data.addons); 
            computeRaidSpace( res.data.addons , achdds );
          }
          if (obj === 'portspeed') setportAddons(res.data.addons);
        })
        .catch((err) => {});
    });
  };

  const ramChange = (e) => {
    setSelectRAM(e.target.value);
    computeLocalPrice();
    console.log(e.target.value);
  };

  const osChange = (e) => {
    setSelectOS(e.target.value);
    computeLocalPrice();
    // setLocalCart({ ...localCart , addons : e.target.value       });
    console.log(e.target.value);
  };

  const portChange = (e) => {
    setSelectportSpeed(e.target.value);
    computeLocalPrice();
    // setLocalCart({ ...localCart , addons : e.target.value       });
    console.log(e.target.value);
  };

  const ipChange = (value) => {
    setSelectIpAddress(value);
    computeLocalPrice();
    console.log(value);
  };

  const raidChange = (e) => {
    setSelectRaid(e.target.value);
    computeLocalPrice();
  };

  const bandwidthChange = (e) => {
    setSelectBandwidth(e.target.value);
    computeLocalPrice();
    console.log(e.target.value);
  };

  const updateHostNames = (value, ind) => {
    const hosts = [...hostNames];
    hosts[ind] = value;
    console.log(value, ind, hosts);
    setHostNames(hosts);
  };

  const computeLocalPrice = () => {
    const prop = activeProduct ? activeProduct.prices[state.fx+'_with_tds'] : 0;
    const os = JSON.parse(selectOS);
    const hdd = selectHDD;
    let hddp = 0;
    selectHDD.map((obj) => {
      if (obj.default !== true && obj.selected === true)
        hddp = hddp + obj.prices[state.fx+'_with_tds'];
    });
    const ram = JSON.parse(selectRAM);
    let raidsel = raidaddons.find(re=> re.raid.toLowerCase() === selectRaid.toLowerCase() );
    const raid = raidsel ? raidsel : null; 
    const ip =
      selectIpq > activeProduct.ip_included
        ? JSON.parse(selectIpAddress)
        : null;
    const bandwidth = JSON.parse(selectBandwidth);
    const portspeed = JSON.parse(selectportspeed);
    const osp = os ? os.prices[state.fx+'_with_tds'] : 0;
    const ramp = ram ? ram.prices[state.fx+'_with_tds'] : 0;
    const ipp = ip ? ip.prices[state.fx+'_with_tds'] * (selectIpq-activeProduct.ip_included) : 0;
    const psp = portspeed ? portspeed.prices[state.fx+'_with_tds'] : 0;
    //const raidp = raid ? raid.price_with_tds : 0;
    const bandp = bandwidth ? bandwidth.prices[state.fx+'_with_tds'] : 0;
    setLocalCart(prop + osp + ramp + hddp + ipp + bandp + psp);
    console.log(localCart, prop, osp, ramp, hddp, ipp, bandp);
  };


  const submitCart = () => {
    const addonsTemp = [];
    const os = JSON.parse(selectOS);
    const hdd = selectHDD;
    const ram = JSON.parse(selectRAM);
    let raidsel = raidaddons.find(re=> re.raid.toLowerCase() === selectRaid.toLowerCase() );
    const raid = raidsel ? raidsel : null ;
    const ip =
      selectIpq > activeProduct.ip_included
        ? JSON.parse(selectIpAddress)
        : null;
    const bandwidth = JSON.parse(selectBandwidth);
    const portspeed = JSON.parse(selectportspeed);
    if (bandwidth) {
      addonsTemp.push({
        addon_id: bandwidth._id,
        category: 'bandwidth',
        quantity: 1,
        price: bandwidth.prices[state.fx+'_with_tds'],
        addon_info: { ...bandwidth },
      });
    }
    if (portspeed) {
      addonsTemp.push({
        addon_id: portspeed._id,
        category: 'portspeed',
        quantity: 1,
        price: portspeed.prices[state.fx+'_with_tds'],
        addon_info: { ...portspeed },
      });
    }
    if (ip) {
      addonsTemp.push({
        addon_id: ip._id,
        category: 'ipaddress',
        quantity: selectIpq - activeProduct.ip_included,
        price: ip.prices[state.fx+'_with_tds'],
        addon_info: { ...ip },
      });
    }
    if (raid) {
      addonsTemp.push({
        addon_id: raid._id,
        category: 'raid',
        quantity: 1,
        price: raid.prices[state.fx+'_with_tds'],
        addon_info: { ...raid },
      });
    }
    if (os) {
      addonsTemp.push({
        addon_id: os._id,
        category: 'os',
        quantity: os.quantity,
        price: os.prices[state.fx+'_with_tds'],
        addon_info: { ...os },
      });
    }
    if (ram) {
      addonsTemp.push({
        addon_id: ram._id,
        category: 'ram',
        quantity: ram.quantity,
        price: ram.prices[state.fx+'_with_tds'],
        addon_info: { ...ram },
      });
    }
    if (hdd) {
      hdd.map((obj) => {
        if (obj.default !== true && obj.selected === true && '_id' in obj)
          addonsTemp.push({
            addon_id: obj._id,
            category: 'hdd',
            quantity: 1,
            price: obj.prices[state.fx+'_with_tds'],
            addon_info: { ...obj },
          });
      });
    }
    const cartBody = {
      customer_id: state.userId,
      product_id: activeProduct._id,
      quantity: quantity,
      category: 'baremetal',
      currency : state.fx,
      price: activeProduct.prices[state.fx+'_with_tds'],
      product_info: { ...activeProduct },
      host_names: hostNames,
      addons: addonsTemp,
    };
    POST('cart', state.token, cartBody).then((res) => {
      console.log(res);
      loadPage('cart', dispatch);
    });
  };


  const computeRaidSpace =( addons,selecthdd )=>{

    let thdd =  selecthdd.filter(obj=> 'capacity' in obj &&
    obj.selected === true || obj.default === true);
    if(thdd !== undefined && thdd.length > 0) {
      let traidaddons = [];
      addons.map((ra)=>{
        let v = window[ra.raid.toLowerCase()]( thdd);   
        let rak = {...ra , ...{used : v[0] , unused : v[1] , protection : v[2] } };
        traidaddons.push(rak);
      })
      setraidAddons(traidaddons);
    }
  }

  const getProductByid = (id) =>{
    GET(`products/${id}`, state.token)
    .then((res) => {
      console.log(res.data);
      if( "product" in res.data ){
        setactiveProduct(res.data.product);
        //sessionStorage.setItem('activeProduct',JSON.stringify(res.data.product));
        getServerAddons(res.data.product._id);
        getOtherAddons(res.data.product.hdds,res.data.product.location);
        //selectedR(res.data.product.hdd.slots);
        setSelectHDD(res.data.product.hdds);
        setSelectedR(res.data.product.hdd.slots);
        setSelectIpq(res.data.product.ip_included);
        setSelectRaid('RAID0');
        selectRAM(null);
      }
      else{
        message.open("Unknown Error")
        setIsModalVisible(false);
      }
    })
    .catch((err) => {});
  }

  useEffect(() => {

    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    }); 
    if(activeProduct === null)
        getProductByid(activeProductId);
    if(activeProduct !== null) {
      if(activeProduct._id !== activeProductId)
      getProductByid(activeProductId);
      computeLocalPrice();
    }
    return () => {};
  }, [activeProductId,activeProduct]);


  useEffect(() => {
    if(activeProduct !== null){
    computeLocalPrice();
    computeRaidSpace(raidaddons,selectHDD); }
  }, [
    activeProduct,
    selectOS,
    selectRAM,
    selectHDD,
    selectIpAddress,
    selectRaid,
    selectBandwidth,
    selectportspeed,
    selectIpq,
  ]);

  return (
    <>
      {/* Page Header */}
      { activeProduct !== null ?
      <Row>
        <Col span="24" className="pb-5 mb-2 page-bg">
          <Row className="server-config-section-row mb-2">
            <Col span="24">
              <div className="full-width server-config-section" >
                <div className="server-config-options full-width p-2">
                  <div className="config-radio-label select-server ps-0">
                    <div className="config-server-details">
                      <div
                        className="config-server-title full-width text-truncate"
                        style={{ fontSize: '18px' }}
                      >
                        {activeProduct
                          ? activeProduct.title
                          : ' product title goes here'}
                      </div>
                      <div className="config-server-info full-width text-truncate">
                        {activeProduct
                          ? activeProduct.description
                          : ' product description goes here'}
                      </div>
                    </div>
                    <div className="config-price-block">
                      <div className="config-price-text text-end">
                        {activeProduct
                          ? `${activeProduct.prices[state.fx+'_sym']}
                           ${activeProduct.prices[state.fx+'_with_tds']}`
                          : ''}{' '}
                        <span> / Month</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="server-config-section-row mb-2">
            <Col span="24">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Location:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group value={activeProductId} 
                  onChange={(e)=> setIsModalVisible(e.target.value) }>
                    <Space direction="vertical">
                      { similarProds.map((obj)=>{
                      return <Radio
                        value={obj._id}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            {obj.location}
                          </div>
                          <div
                            className={
                              obj.prices[ state.fx+'_with_tds'] === 'Free of charge'
                                ? 'config-location-price free'
                                : 'config-location-price'
                            }
                          >
                            <div className="config-price-text text-end">
                              {obj.prices[ state.fx+'_sym'] }{' '}
                              {obj.prices[ state.fx+'_with_tds']  }{' '}
                              <span> / Month</span>
                            </div>
                          </div>
                        </div>
                      </Radio> })}
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="server-config-section-row mb-2">
            <Col span="24">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose RAM Size:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={ramChange} value={selectRAM}>
                    <Space direction="vertical">
                      <Radio
                        value={null}
                        checked
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            {activeProduct.ram.slots * activeProduct.ram.size +
                              ' GB ' +
                              activeProduct.ram.technology}
                          </div>
                          <div className="config-location-price free">
                            {'FREE of charge'}
                          </div>
                        </div>
                      </Radio>
                      {ramaddons.sort((a, b) => a.price > b.price ? 1 : -1).map((ao, index) => {
                        return ao.category === 'ram' ? (
                          <Radio
                            value={JSON.stringify(ao)}
                            className="selectRadioBtn full-width"
                          >
                            <div className="config-radio-label select-location">
                              <div className="config-location-title text-truncate">
                                {ao.size + ' GB ' + ao.technology}
                              </div>
                              <div className="config-location-price free">
                                <div className="config-price-text text-end">
                                {ao.prices[ state.fx+'_sym'] }{' '}
                                {ao.prices[ state.fx+'_with_tds']  }{' '}
                                  <span> / Month</span>
                                </div>
                              </div>
                            </div>
                          </Radio>
                        ) : null;
                      })}
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="server-configur-helper-info align-items-center">
                <BsInfoCircleFill /> You can always configure your server with
                additional RAM. Please note that changes in the standard
                configurations of RAM and HDD can delay server deployment for 1
                business day and options may vary depending on the Location
              </div>
            </Col>
          </Row>
          <Row className="server-config-section-row mb-2">
            <Col span="24">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose Operating System:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={osChange} value={selectOS}>
                    <Space direction="vertical">
                      <Radio
                        value={null}
                        checked
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            {'Without operating system'}
                          </div>
                          <div className="config-location-price free">
                            {'FREE of charge'}
                          </div>
                        </div>
                      </Radio>
                      {osaddons.sort((a, b) => a.price > b.price ? 1 : -1).map((ao, index) => {
                        return ao.category === 'os' ? (
                          <Radio
                            value={JSON.stringify(ao)}
                            className="selectRadioBtn full-width"
                          >
                            <div className="config-radio-label select-location">
                              <div className="config-location-title text-truncate">
                                {ao.title + ' ' + ao.brand + ' ' + ao.ostype}
                              </div>
                              <div
                                className={
                                  ao.prices[state.fx+'_with_tds'] === 0
                                    ? 'config-location-price free'
                                    : 'config-location-price'
                                }
                              >
                                <div className="config-price-text text-end">
                                  {ao.prices[state.fx+'_with_tds'] === 0 ? (
                                    <span
                                      className="text-dark"
                                      style={{ fontSize: '18px' }}
                                    >
                                      FREE of charge
                                    </span>
                                  ) : (
                                    <>
                                    {ao.prices[ state.fx+'_sym'] }{' '}
                                    {ao.prices[ state.fx+'_with_tds']  }{' '}
                                      <span> / Month</span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Radio>
                        ) : null;
                      })}
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col span="8"></Col>
          </Row>
          <Row className="server-config-section-row mb-2">
            <Col span="24" >
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  <Row gutter={30}>
                    <Col span={8}> RAID Settings : </Col>
                    <Col span={8}>
                      {/* Add or remove disks to change settings */}
                    </Col>
                    <Col span={8} className="text-end">
                      {selectedR  < activeProduct.chasis_info.hdd.max_slots ? (
                        <Button
                          onClick={() => {
                            setSelectedR(
                              selectedR < activeProduct.chasis_info.hdd.max_slots
                                ? selectedR + 1
                                : selectedR
                            );
                            const selhdd = [...selectHDD];
                            const temp = hddaddons.find(
                              (ha) =>
                                ha.master_id === activeProduct.hdd.master_id
                            );
                            let tmp = temp ? temp : { failed: true };
                            selhdd[selectedR] = {
                              ...selhdd[selectedR],
                              ...tmp,
                            };
                            setSelectHDD(selhdd);
                          }}
                        >
                          Add Disk
                        </Button>
                      ) : null}
                      {selectedR > activeProduct.hdd.slots ? (
                        <Button
                          onClick={() => {
                            const selhdd = [...selectHDD];
                            selhdd[selectedR - 1] = {
                              slot: selectedR - 1,
                              ...defaultHddInterface,
                            };
                            setSelectHDD(selhdd);
                            setSelectedR(
                              selectedR > activeProduct.hdd.slots
                                ? selectedR - 1
                                : selectedR
                            );
                          }}
                        >
                          Remove Disk
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={raidChange} value={selectRaid}>
                    <Space direction="vertical">
                      {raidaddons
                        .filter((rec) => {
                          if (
                            activeProduct.chasis_info.location === rec.location
                          ) {
                            if (rec.raid === 'RAID0') return rec;
                            if (selectedR === 2 && ['RAID1'].includes(rec.raid))
                              return rec;
                            if (selectedR >= 3 && ['RAID5'].includes(rec.raid))
                              return rec;
                            if (
                              selectedR >= 4 &&
                              selectedR % 2 === 0 &&
                              ['RAID10'].includes(rec.raid)
                            )
                              return rec;
                            if (selectedR >= 4 && ['RAID6'].includes(rec.raid))
                              return rec;
                            if (selectedR >= 6 && ['RAID50'].includes(rec.raid))
                              return rec;
                            if (selectedR >= 8 && ['RAID60'].includes(rec.raid))
                              return rec;
                          }
                        }).sort((a, b) => Number(a.raid.replace('RAID','') )
                         > Number(b.raid.replace('RAID','')) ? 1 : -1)
                        .map((ao, index) => {
                          return ao.category === 'raid' ? (
                            <Radio
                              key={JSON.stringify(ao)}
                              value={ao.raid}
                              className="selectRadioBtn "
                            >
                              <div className="config-radio-label select-location">
                                <div className="config-location-title text-truncate">
                                  {' '}
                                  {ao.raid}{' '}
                                  { 
                                      `( size: ${ao.used},
                                       redundancy: ${ao.protection} )`
                                  }
                                </div>
                                <div className="config-location-price free">
                                  {'prices' in ao && ao.prices[state.fx+'_with_tds'] !== 0
                                    ? <>{ao.prices[ state.fx+'_sym'] }{' '}
                                    {ao.prices[ state.fx+'_with_tds']  }{' '}</>
                                    : '   FREE of Charge '}
                                </div>{' '}
                              </div>
                            </Radio>
                          ) : null;
                        })}
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="server-config-section-row mb-2 page-bg">
            <Col span="12" className="">
              <ul className="selected-slots-list list-unstyled full-width mb-0">
                {selectHDD.map((obj, index) => {
                  return obj.slot >= selectedR ? null : (
                    <li>
                      <div className="each-selected-slot full-width">
                        {
                          obj.default === true || obj.selected === true ? (
                            <>
                              <div className="each-selected-slot-header full-width">
                                <div className="selected-slot-number">
                                  {'SLOT:' + obj.slot}
                                </div>
                                <div className="selected-slot-number text-end">
                                  {obj.form_factor} &quot; {' ' + obj.protocol}
                                </div>
                              </div>
                              <div className="each-selected-slot-info-block full-width">
                                <div className="each-selected-slot-info full-width">
                                  <div className="full-width d-flex justify-content-between align-items-end">
                                    <div className="align-self-start">
                                      <div className="full-width">
                                        {obj.capacity + ' GB ' + obj.protocol}
                                      </div>
                                      <div className="full-width">
                                        {obj.price === 0 ? (
                                          'FREE of charge'
                                        ) : (
                                          <>
                                            <strong className="red-text">
                                              {obj.prices[state.fx+'_sym']}{' '}
                                              {obj.prices[state.fx+'_with_tds']}
                                            </strong>
                                            <span> / Month</span>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="align-self-end ps-2">
                                      {obj.selected === true ? (
                                        <Button
                                          htmlType="button"
                                          className="slot-selecting-btn ms-2"
                                          onClick={() => {
                                            const selhdd = [...selectHDD];
                                            selhdd[obj.slot] = {
                                              ...selhdd[obj.slot],
                                              selected: false,
                                            };
                                            setSelectHDD(selhdd);
                                          }}
                                        >
                                          <FaDownload /> Eject drive
                                        </Button>
                                      ) : null}
                                    </div>
                                  </div>
                                  {/* <div className="full-width disabled-select d-flex justify-content-between align-items-center">
                                    <div className="align-self-start"></div>
                                  </div>
                                  <div className="full-width d-flex justify-content-between align-items-center">
                                    <div className="align-self-start"></div>
                                    <div className="align-self-end"></div>
                                  </div> */}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="each-selected-slot-header full-width">
                                <div className="slot-number">
                                  {'SLOT:' + obj.slot}
                                </div>
                                <div className="slot-title text-center">
                                  EMPTY STORAGE DRIVE SLOT
                                </div>
                                <div className="slot-form-factor text-end">
                                  {obj.form_factor} &quot; {' ' + obj.protocol}
                                </div>
                              </div>
                              <div className="each-selected-slot-info-block full-width text-end">
                                <Button
                                  htmlType="button"
                                  className="slot-selecting-btn ms-2"
                                  onClick={() => {
                                    const selhdd = [...selectHDD];
                                    selhdd[obj.slot] = {
                                      ...selhdd[obj.slot],
                                      selected: true,
                                    };
                                    setSelectHDD(selhdd);
                                  }}
                                >
                                  <FaDownload />
                                  {obj.capacity + ' GB ' + obj.protocol} &quot;
                                  {obj.form_factor}
                                </Button>
                                {/*  <Button
                                  disabled
                                  htmlType="button"
                                  className="slot-selecting-btn ms-2"
                                >
                                  <FaDownload /> Assign drive
                                </Button> */}
                              </div>
                            </>
                          )
                          /* <>
                                  <Select
                                    disabled
                                    value={obj.master_id}
                                    placeholder={'Please Add Disk Here'}
                                    onChange={(value) => {
                                      const selhdd = [...selectHDD];
                                      const temp = hddaddons.find(
                                        (ha) => ha.master_id === value
                                      );
                                      let tmp = temp ? temp : { failed: true };
                                      selhdd[obj.slot] = {
                                        ...selhdd[obj.slot],
                                        ...tmp,
                                      };
                                      setSelectHDD(selhdd);
                                    }}
                                  >
                                    {hddaddons.map((ao, index) => {
                                      return ao.category === 'hdd' ? (
                                        <Option
                                          key={ao._id}
                                          value={ao.master_id}
                                        >
                                          {ao.capacity + ' GB ' + ao.protocol}
                                        </Option>
                                      ) : null;
                                    })}
                                  </Select>
                                    <Button onClick={()=>{
                                      const selhdd =  [...selectHDD];
                                      selhdd[obj.slot] ={ 'slot' : obj.slot , ...defaultHddInterface };
                                      setSelectHDD( selhdd);
                                    }}>
                                        Remove Drive
                                    </Button>
                                  </>*/
                        }
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col span="1" />
            <Col span="11" className="">
              <div className="full-width bg-white" style={{ opacity: '0.4' }}>
                <div className="selected-drive-list block">
                  <div className="selected-drive-header full-width">
                    RAID 0 - 4TB - Static - ToDo
                  </div>
                  <div className="selected-table-block full-width">
                    <ul className="selected-table-grid full-width list-unstyled mb-0">
                      <li>
                        <div className="selected-table-row grid-header full-width">
                          <div className="selected-drive-title">
                            <span className="selected-drive-title-text">
                              Mount point
                            </span>
                          </div>
                          <div className="selected-filesystem">File System</div>
                          <div className="selected-size">Size</div>
                          <div className="selected-action-block"></div>
                        </div>
                      </li>
                      <li>
                        <div className="selected-table-row full-width">
                          <div className="selected-drive-title">
                            <span className="icon bg-gray"></span>
                            <span className="selected-drive-title-text">
                              Free Space
                            </span>
                          </div>
                          <div className="selected-filesystem">File System</div>
                          <div className="selected-size">4 TB</div>
                          <div className="selected-action-block">
                            <Button
                              htmlType="button"
                              className="selected-add-btn"
                              disabled
                            >
                              <IoAdd />
                            </Button>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="selected-table-row full-width">
                          <div className="selected-drive-title">
                            <span className="icon bg-gray"></span>
                            <span className="selected-drive-title-text">
                              Free Space
                            </span>
                          </div>
                          <div className="selected-filesystem">File System</div>
                          <div className="selected-size">4 TB</div>
                          <div className="selected-action-block">
                            <Button
                              htmlType="button"
                              className="selected-add-btn"
                            >
                              <IoAdd />
                            </Button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/*<Row className="server-config-section-row mb-2 page-bg">
            <Col span="12" className="">
              <ul className="selected-slots-list list-unstyled full-width mb-0">
              {Array(
              selectedR > activeProduct.hdd.slots
                ? selectedR - activeProduct.hdd.slots
                : 0
            )
              .fill(0)
              .map((obj, index) => {
                return (
                <li>
                  <div className="each-selected-slot full-width unselected">
                    <div className="each-selected-slot-header full-width">
                      <div className="slot-number">SLOT:{activeProduct.hdd.slots+index}</div>
                      <div className="slot-title text-center">
                        EMPTY STORAGE DRIVE SLOT
                      </div>
                      <div className="slot-form-factor text-end">3.5" SAS</div>
                    </div>
                    <div className="each-selected-slot-info-block full-width text-end">
                      <Button
                        htmlType="button"
                        className="slot-selecting-btn ms-2"
                      >
                        <FaDownload /> 2 TB SATA 3.5"
                      </Button>
                      <Button
                        htmlType="button"
                        className="slot-selecting-btn ms-2"
                      >
                        <FaDownload /> Assign drive
                      </Button>
                    </div>
                  </div>
                </li>
                );
              })}
              </ul>
            </Col>
            </Row>*/}
          <Row className="server-config-section-row mb-2">
            <Col span="24">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  {'Public uplink port'}
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={portChange} value={selectportspeed}>
                    <Space direction="vertical">
                      <Radio
                        value={null}
                        checked
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            {` ${activeProduct.port_speed} Mbps `}
                          </div>
                          <div className="config-location-price free">
                            {'FREE of charge'}
                          </div>
                        </div>
                      </Radio>
                      {portaddons.map((ao, index) => {
                        return ao.category === 'portspeed' &&
                          activeProduct.port_speed !== ao.port_speed ? (
                          <Radio
                            value={JSON.stringify(ao)}
                            className="selectRadioBtn full-width"
                          >
                            <div className="config-radio-label select-location">
                              <div className="config-location-title text-truncate">
                                {` ${ao.port_speed} Mbps `}
                              </div>
                              <div className="config-location-price free">
                                <div className="config-price-text text-end">
                                  {ao.prices[state.fx+'_with_tds'] === 0 ? (
                                    'FREE of charge'
                                  ) : (
                                    <>
                                      {ao.prices[ state.fx+'_sym'] }{' '}
                                      {ao.prices[ state.fx+'_with_tds']  }{' '}
                                      <span> / Month</span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Radio>
                        ) : null;
                      })}
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="server-config-section-row mb-2">
            <Col span="24">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width p-2">
                  <Row>
                    <Col span="15" className="">
                      Bandwidth Plan
                    </Col>
                    <Col span="5">Overage</Col>
                    <Col span="4">Commit</Col>
                  </Row>
                </div>
                <div
                  className="server-config-options full-width px-2"
                  style={{ backgroundColor: '#efffef' }}
                >
                  {bandwidthaddons.map((ao, index) => {
                    return ao.category === 'bandwidth' ? (
                      <Row align="center">
                        <Col span="15" className="bandwidth-grid">
                          <Radio.Group
                            onChange={bandwidthChange}
                            value={selectBandwidth}
                          >
                            <Radio
                              value={JSON.stringify(ao)}
                              className="selectRadioBtn full-width"
                            >
                              <div className="config-radio-label select-location">
                                <div className="config-location-title text-truncate">
                                  {`By traffic amount ${ao.freequota} GB`}
                                </div>
                                <div className="config-location-price free"></div>
                                <div className="config-location-price free"></div>
                              </div>
                            </Radio>
                          </Radio.Group>
                        </Col>
                        <Col
                          span="5"
                          className="d-inline-flex align-self-center"
                        >
                          <span className="text-danger font-weight-bold">
                            <strong>
                            {ao.prices[ state.fx+'_sym'] }{' '}
                              {ao.prices[ state.fx+'_with_tds']  }{' '}
                            </strong>
                          </span>{' '}
                          / GB * Month
                        </Col>
                        <Col
                          span="4"
                          className="d-inline-flex align-self-center"
                        >
                          FREE of charge
                        </Col>
                      </Row>
                    ) : null;
                  })}
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="server-configur-helper-info align-items-center p-2">
                <BsInfoCircleFill /> This plan includes{' '}
                {activeProduct.bandwidth} GB. Overage is billed additionally.
              </div>
            </Col>
          </Row>
          <Row className="server-config-section-row mb-2">
            <Col span="24">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Set public IP addresses count:
                </div>
                <div className="server-config-options full-width">
                  <div className="full-width p-2 d-flex align-items-center">
                    {ipaddons
                      .filter(
                        (rec) =>
                          activeProduct.chasis_info.location === rec.location
                      )
                      .map((ao, index) => {
                        return ao.category === 'ipaddress' ? (
                          <>
                            <div
                              className="w-50 float-left qty-block position-relative"
                              style={{ left: 0, top: 0 }}
                            >
                              <InputNumber
                                addonAfter={
                                  <Button
                                    type="link"
                                    htmlType="button"
                                    className="p-0 text-dark"
                                    onClick={() => {
                                      ipChange(JSON.stringify(ao));
                                      setSelectIpq(
                                        selectIpq < 8
                                          ? selectIpq + 1
                                          : selectIpq
                                      );
                                    }}
                                  >
                                    <IoAdd title="Min 1 Max 5" />
                                  </Button>
                                }
                                addonBefore={
                                  <Button
                                    type="link"
                                    htmlType="button"
                                    className="p-0 text-dark"
                                    onClick={() => {
                                      setSelectIpq(
                                        selectIpq > 1
                                          ? selectIpq - 1
                                          : selectIpq
                                      );
                                    }}
                                  >
                                    <IoRemoveOutline title="Min 1 Max 5" />
                                  </Button>
                                }
                                value={selectIpq}
                                min={1}
                                max={8}
                                controls={false}
                                onChange={(value) => {
                                  //ipChange(JSON.stringify(ao))
                                  setSelectIpq(value);
                                }}
                                className="quatityTextInput"
                              />
                            </div>
                            <div
                              className="w-50 float-right text-end"
                              style={{ paddingRight: '8px' }}
                            >
                              <span style={{ fontSize: '18px' }}>
                                {selectIpq <= activeProduct.ip_included ? (
                                  'FREE of charge'
                                ) : (
                                  <>
                                    <strong className="red-text">
                                      {ao.prices[ state.fx+'_sym']}{' '}
                                      {(selectIpq - activeProduct.ip_included) *
                                        ao.prices[ state.fx+'_with_tds']}
                                        </strong>
                                    <span style={{ fontSize: '15px' }}>
                                      / Month
                                    </span>
                                  </>
                                )}

                                {/* {selectIpq <= activeProduct.ip_included
                                  ? 'FREE of charge'
                                  : activeProduct.base_currency_symbol +
                                    ' ' +
                                    (selectIpq - activeProduct.ip_included) *
                                      ao.price_with_tds +
                                    '/ Month'
                                    }{' '} */}
                              </span>
                            </div>
                          </>
                        ) : null;
                      })}
                  </div>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="server-configur-helper-info align-items-center">
                <BsInfoCircleFill /> You can choose up to 8 public IP addresses
                for your server.
              </div>
            </Col>
          </Row>

          <Row className="server-config-section-row mb-2">
            <Col span="24">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  <Row gutter={30}>
                    <Col span="24">
                      <div className="config-server-details">Quantity:</div>
                      <div className="config-price-block position-relative">
                        <div className="qty-block">
                          <InputNumber
                            addonAfter={
                              <Button
                                type="link"
                                htmlType="button"
                                className="p-0 text-dark"
                                onClick={() => {
                                  setQuantity(
                                    quantity < 5 ? quantity + 1 : quantity
                                  );
                                  setHostNames([...hostNames, getHostname()]);
                                }}
                              >
                                <IoAdd title="Min 1 Max 5" />
                              </Button>
                            }
                            addonBefore={
                              <Button
                                type="link"
                                htmlType="button"
                                className="p-0 text-dark"
                                onClick={() => {
                                  setQuantity(
                                    quantity > 1 ? quantity - 1 : quantity
                                  );
                                  setHostNames([
                                    ...hostNames.splice(
                                      0,
                                      quantity > 1 ? quantity - 1 : quantity
                                    ),
                                  ]);
                                }}
                              >
                                <IoRemoveOutline title="Min 1 Max 5" />
                              </Button>
                            }
                            value={quantity}
                            min={1}
                            max={5}
                            controls={false}
                            onChange={(value) => {
                              setQuantity(value);
                              setHostNames([...hostNames.splice(0, value - 1)]);
                            }}
                            className="quatityTextInput"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="server-config-options full-width">
                  {Array(quantity)
                    .fill(0)
                    .map((obj, index) => {
                      return (
                        <Row
                          gutter={30}
                          className="p-2"
                          key={index}
                          align="stretch"
                        >
                          <Col
                            span={2}
                            className="text-end d-inline-flex align-self-center justify-content-end"
                            style={{ fontSize: '20px' }}
                          >
                            {' '}
                            {index + 1}{' '}
                          </Col>
                          {/*  <Col span={6}> Name </Col> */}
                          <Col span={22}>
                            <Input
                              placeholder={'Host Name-' + (index + 1)}
                              value={hostNames[index]}
                              onChange={(e) => {
                                updateHostNames(e.target.value, index);
                              }}
                              style={{
                                fontSize: '20px',
                                padding: '10px 20px 12px',
                              }}
                            ></Input>
                          </Col>
                        </Row>
                      );
                    })}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row> : null}

      <div className="final-order-pricing-block">
        <div className="final-order-info">
          <div className="full-width order-info-title">
            Total Price:{' '}
            <span className="" style={{ color: '#ca0606' }}>
              { activeProduct && activeProduct.prices[state.fx+'_sym']  }
              <strong>{localCart * quantity}</strong>
            </span>{' '}
            <span className="text-info multiply text-danger">*</span> + FREE
            Setup
          </div>
          <div className="full-width order-misc-info">
            1 server will be deployed in 24 hours
          </div>
        </div>
        <div className="final-order-actions-block d-flex align-items-center justify-content-end">
          {/* <Button
            type='default'
            className='d-inline-flex align-items-center'
            onClick={showModal}>
            <ImGift />
            &nbsp; Apply promo code
          </Button> */}
          <Button type="default">Cancel</Button>
          <Button
            type="default"
            className="btn-primary"
            onClick={() => submitCart()}
          >
            <IoCart />
            &nbsp; Add to Cart
          </Button>
        </div>
      </div>

      { <Modal
        title='Change Product and configuration'
        onCancel={()=> setIsModalVisible(false)}
        visible={isModalVisible}
        footer={null}>
        <Row>
          <Col span='24'>
            Do you really want to change product and configuration to different location ?
          </Col>
          <Col span='24' className='my-2'>
          <Button  type='default' onClick={()=> setIsModalVisible(false)}> Cancel</Button>
            <Button  type='primary' onClick={() => 
              { sessionStorage.setItem('activeProduct', JSON.stringify(isModalVisible) );
                setIsModalVisible(false);
              } }>Ok</Button>
          </Col>
        </Row>
      </Modal> }
    </>
  );
};

export default ServerConfiguration;
