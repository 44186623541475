export const periodictable = [
  {"atomicNumber":1,"symbol":"H","name":"Hydrogen","atomicMass":"1.00794(4)","cpkHexColor":"FFFFFF","electronicConfiguration":"1s1","electronegativity":2.2,"atomicRadius":37,"ionRadius":"","vanDelWaalsRadius":120,"ionizationEnergy":1312,"electronAffinity":-73,"oxidationStates":"-1, 1","standardState":"gas","bondingType":"diatomic","meltingPoint":14,"boilingPoint":20,"density":0.0000899,"groupBlock":"nonmetal","yearDiscovered":1766},
  {"atomicNumber":2,"symbol":"He","name":"Helium","atomicMass":"4.002602(2)","cpkHexColor":"D9FFFF","electronicConfiguration":"1s2","electronegativity":"","atomicRadius":32,"ionRadius":"","vanDelWaalsRadius":140,"ionizationEnergy":2372,"electronAffinity":0,"oxidationStates":"","standardState":"gas","bondingType":"atomic","meltingPoint":"","boilingPoint":4,"density":0.0001785,"groupBlock":"noble gas","yearDiscovered":1868},
  {"atomicNumber":3,"symbol":"Li","name":"Lithium","atomicMass":"6.941(2)","cpkHexColor":"CC80FF","electronicConfiguration":"[He] 2s1","electronegativity":0.98,"atomicRadius":134,"ionRadius":"76 (+1)","vanDelWaalsRadius":182,"ionizationEnergy":520,"electronAffinity":-60,"oxidationStates":1,"standardState":"solid","bondingType":"metallic","meltingPoint":454,"boilingPoint":1615,"density":0.535,"groupBlock":"alkali metal","yearDiscovered":1817},
  {"atomicNumber":4,"symbol":"Be","name":"Beryllium","atomicMass":"9.012182(3)","cpkHexColor":"C2FF00","electronicConfiguration":"[He] 2s2","electronegativity":1.57,"atomicRadius":90,"ionRadius":"45 (+2)","vanDelWaalsRadius":"","ionizationEnergy":900,"electronAffinity":0,"oxidationStates":2,"standardState":"solid","bondingType":"metallic","meltingPoint":1560,"boilingPoint":2743,"density":1.848,"groupBlock":"alkaline earth metal","yearDiscovered":1798},
  {"atomicNumber":5,"symbol":"B","name":"Boron","atomicMass":"10.811(7)","cpkHexColor":"FFB5B5","electronicConfiguration":"[He] 2s2 2p1","electronegativity":2.04,"atomicRadius":82,"ionRadius":"27 (+3)","vanDelWaalsRadius":"","ionizationEnergy":801,"electronAffinity":-27,"oxidationStates":"1, 2, 3","standardState":"solid","bondingType":"covalent network","meltingPoint":2348,"boilingPoint":4273,"density":2.46,"groupBlock":"metalloid","yearDiscovered":1807},
  {"atomicNumber":6,"symbol":"C","name":"Carbon","atomicMass":"12.0107(8)","cpkHexColor":909090,"electronicConfiguration":"[He] 2s2 2p2","electronegativity":2.55,"atomicRadius":77,"ionRadius":"16 (+4)","vanDelWaalsRadius":170,"ionizationEnergy":1087,"electronAffinity":-154,"oxidationStates":"-4, -3, -2, -1, 1, 2, 3, 4","standardState":"solid","bondingType":"covalent network","meltingPoint":3823,"boilingPoint":4300,"density":2.26,"groupBlock":"nonmetal","yearDiscovered":"Ancient"},
  {"atomicNumber":7,"symbol":"N","name":"Nitrogen","atomicMass":"14.0067(2)","cpkHexColor":"3050F8","electronicConfiguration":"[He] 2s2 2p3","electronegativity":3.04,"atomicRadius":75,"ionRadius":"146 (-3)","vanDelWaalsRadius":155,"ionizationEnergy":1402,"electronAffinity":-7,"oxidationStates":"-3, -2, -1, 1, 2, 3, 4, 5","standardState":"gas","bondingType":"diatomic","meltingPoint":63,"boilingPoint":77,"density":0.001251,"groupBlock":"nonmetal","yearDiscovered":1772},
  {"atomicNumber":8,"symbol":"O","name":"Oxygen","atomicMass":"15.9994(3)","cpkHexColor":"FF0D0D","electronicConfiguration":"[He] 2s2 2p4","electronegativity":3.44,"atomicRadius":73,"ionRadius":"140 (-2)","vanDelWaalsRadius":152,"ionizationEnergy":1314,"electronAffinity":-141,"oxidationStates":"-2, -1, 1, 2","standardState":"gas","bondingType":"diatomic","meltingPoint":55,"boilingPoint":90,"density":0.001429,"groupBlock":"nonmetal","yearDiscovered":1774},
  {"atomicNumber":9,"symbol":"F","name":"Fluorine","atomicMass":"18.9984032(5)","cpkHexColor":9e+51,"electronicConfiguration":"[He] 2s2 2p5","electronegativity":3.98,"atomicRadius":71,"ionRadius":"133 (-1)","vanDelWaalsRadius":147,"ionizationEnergy":1681,"electronAffinity":-328,"oxidationStates":-1,"standardState":"gas","bondingType":"atomic","meltingPoint":54,"boilingPoint":85,"density":0.001696,"groupBlock":"halogen","yearDiscovered":1670},
  {"atomicNumber":10,"symbol":"Ne","name":"Neon","atomicMass":"20.1797(6)","cpkHexColor":"B3E3F5","electronicConfiguration":"[He] 2s2 2p6","electronegativity":"","atomicRadius":69,"ionRadius":"","vanDelWaalsRadius":154,"ionizationEnergy":2081,"electronAffinity":0,"oxidationStates":"","standardState":"gas","bondingType":"atomic","meltingPoint":25,"boilingPoint":27,"density":0.0009,"groupBlock":"noble gas","yearDiscovered":1898},
  {"atomicNumber":11,"symbol":"Na","name":"Sodium","atomicMass":"22.98976928(2)","cpkHexColor":"AB5CF2","electronicConfiguration":"[Ne] 3s1","electronegativity":0.93,"atomicRadius":154,"ionRadius":"102 (+1)","vanDelWaalsRadius":227,"ionizationEnergy":496,"electronAffinity":-53,"oxidationStates":"-1, 1","standardState":"solid","bondingType":"metallic","meltingPoint":371,"boilingPoint":1156,"density":0.968,"groupBlock":"alkali metal","yearDiscovered":1807},
  {"atomicNumber":12,"symbol":"Mg","name":"Magnesium","atomicMass":"24.3050(6)","cpkHexColor":"8AFF00","electronicConfiguration":"[Ne] 3s2","electronegativity":1.31,"atomicRadius":130,"ionRadius":"72 (+2)","vanDelWaalsRadius":173,"ionizationEnergy":738,"electronAffinity":0,"oxidationStates":"1, 2","standardState":"solid","bondingType":"metallic","meltingPoint":923,"boilingPoint":1363,"density":1.738,"groupBlock":"alkaline earth metal","yearDiscovered":1808},
  {"atomicNumber":13,"symbol":"Al","name":"Aluminum","atomicMass":"26.9815386(8)","cpkHexColor":"BFA6A6","electronicConfiguration":"[Ne] 3s2 3p1","electronegativity":1.61,"atomicRadius":118,"ionRadius":"53.5 (+3)","vanDelWaalsRadius":"","ionizationEnergy":578,"electronAffinity":-43,"oxidationStates":"1, 3","standardState":"solid","bondingType":"metallic","meltingPoint":933,"boilingPoint":2792,"density":2.7,"groupBlock":"metal","yearDiscovered":"Ancient"},
  {"atomicNumber":14,"symbol":"Si","name":"Silicon","atomicMass":"28.0855(3)","cpkHexColor":"F0C8A0","electronicConfiguration":"[Ne] 3s2 3p2","electronegativity":1.9,"atomicRadius":111,"ionRadius":"40 (+4)","vanDelWaalsRadius":210,"ionizationEnergy":787,"electronAffinity":-134,"oxidationStates":"-4, -3, -2, -1, 1, 2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1687,"boilingPoint":3173,"density":2.33,"groupBlock":"metalloid","yearDiscovered":1854},
  {"atomicNumber":15,"symbol":"P","name":"Phosphorus","atomicMass":"30.973762(2)","cpkHexColor":"FF8000","electronicConfiguration":"[Ne] 3s2 3p3","electronegativity":2.19,"atomicRadius":106,"ionRadius":"44 (+3)","vanDelWaalsRadius":180,"ionizationEnergy":1012,"electronAffinity":-72,"oxidationStates":"-3, -2, -1, 1, 2, 3, 4, 5","standardState":"solid","bondingType":"covalent network","meltingPoint":317,"boilingPoint":554,"density":1.823,"groupBlock":"nonmetal","yearDiscovered":1669},
  {"atomicNumber":16,"symbol":"S","name":"Sulfur","atomicMass":"32.065(5)","cpkHexColor":"FFFF30","electronicConfiguration":"[Ne] 3s2 3p4","electronegativity":2.58,"atomicRadius":102,"ionRadius":"184 (-2)","vanDelWaalsRadius":180,"ionizationEnergy":1000,"electronAffinity":-200,"oxidationStates":"-2, -1, 1, 2, 3, 4, 5, 6","standardState":"solid","bondingType":"covalent network","meltingPoint":388,"boilingPoint":718,"density":1.96,"groupBlock":"nonmetal","yearDiscovered":"Ancient"},
  {"atomicNumber":17,"symbol":"Cl","name":"Chlorine","atomicMass":"35.453(2)","cpkHexColor":"1FF01F","electronicConfiguration":"[Ne] 3s2 3p5","electronegativity":3.16,"atomicRadius":99,"ionRadius":"181 (-1)","vanDelWaalsRadius":175,"ionizationEnergy":1251,"electronAffinity":-349,"oxidationStates":"-1, 1, 2, 3, 4, 5, 6, 7","standardState":"gas","bondingType":"covalent network","meltingPoint":172,"boilingPoint":239,"density":0.003214,"groupBlock":"halogen","yearDiscovered":1774},
  {"atomicNumber":18,"symbol":"Ar","name":"Argon","atomicMass":"39.948(1)","cpkHexColor":"80D1E3","electronicConfiguration":"[Ne] 3s2 3p6","electronegativity":"","atomicRadius":97,"ionRadius":"","vanDelWaalsRadius":188,"ionizationEnergy":1521,"electronAffinity":0,"oxidationStates":"","standardState":"gas","bondingType":"atomic","meltingPoint":84,"boilingPoint":87,"density":0.001784,"groupBlock":"noble gas","yearDiscovered":1894},
  {"atomicNumber":19,"symbol":"K","name":"Potassium","atomicMass":"39.0983(1)","cpkHexColor":"8F40D4","electronicConfiguration":"[Ar] 4s1","electronegativity":0.82,"atomicRadius":196,"ionRadius":"138 (+1)","vanDelWaalsRadius":275,"ionizationEnergy":419,"electronAffinity":-48,"oxidationStates":1,"standardState":"solid","bondingType":"metallic","meltingPoint":337,"boilingPoint":1032,"density":0.856,"groupBlock":"alkali metal","yearDiscovered":1807},
  {"atomicNumber":20,"symbol":"Ca","name":"Calcium","atomicMass":"40.078(4)","cpkHexColor":"3DFF00","electronicConfiguration":"[Ar] 4s2","electronegativity":1,"atomicRadius":174,"ionRadius":"100 (+2)","vanDelWaalsRadius":"","ionizationEnergy":590,"electronAffinity":-2,"oxidationStates":2,"standardState":"solid","bondingType":"metallic","meltingPoint":1115,"boilingPoint":1757,"density":1.55,"groupBlock":"alkaline earth metal","yearDiscovered":"Ancient"},
  {"atomicNumber":21,"symbol":"Sc","name":"Scandium","atomicMass":"44.955912(6)","cpkHexColor":"E6E6E6","electronicConfiguration":"[Ar] 3d1 4s2","electronegativity":1.36,"atomicRadius":144,"ionRadius":"74.5 (+3)","vanDelWaalsRadius":"","ionizationEnergy":633,"electronAffinity":-18,"oxidationStates":"1, 2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1814,"boilingPoint":3103,"density":2.985,"groupBlock":"transition metal","yearDiscovered":1876},
  {"atomicNumber":22,"symbol":"Ti","name":"Titanium","atomicMass":"47.867(1)","cpkHexColor":"BFC2C7","electronicConfiguration":"[Ar] 3d2 4s2","electronegativity":1.54,"atomicRadius":136,"ionRadius":"86 (+2)","vanDelWaalsRadius":"","ionizationEnergy":659,"electronAffinity":-8,"oxidationStates":"-1, 2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1941,"boilingPoint":3560,"density":4.507,"groupBlock":"transition metal","yearDiscovered":1791},
  {"atomicNumber":23,"symbol":"V","name":"Vanadium","atomicMass":"50.9415(1)","cpkHexColor":"A6A6AB","electronicConfiguration":"[Ar] 3d3 4s2","electronegativity":1.63,"atomicRadius":125,"ionRadius":"79 (+2)","vanDelWaalsRadius":"","ionizationEnergy":651,"electronAffinity":-51,"oxidationStates":"-1, 2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":2183,"boilingPoint":3680,"density":6.11,"groupBlock":"transition metal","yearDiscovered":1803},
  {"atomicNumber":24,"symbol":"Cr","name":"Chromium","atomicMass":"51.9961(6)","cpkHexColor":"8A99C7","electronicConfiguration":"[Ar] 3d5 4s1","electronegativity":1.66,"atomicRadius":127,"ionRadius":"80 (+2*)","vanDelWaalsRadius":"","ionizationEnergy":653,"electronAffinity":-64,"oxidationStates":"-2, -1, 1, 2, 3, 4, 5, 6","standardState":"solid","bondingType":"metallic","meltingPoint":2180,"boilingPoint":2944,"density":7.14,"groupBlock":"transition metal","yearDiscovered":"Ancient"},
  {"atomicNumber":25,"symbol":"Mn","name":"Manganese","atomicMass":"54.938045(5)","cpkHexColor":"9C7AC7","electronicConfiguration":"[Ar] 3d5 4s2","electronegativity":1.55,"atomicRadius":139,"ionRadius":"67 (+2)","vanDelWaalsRadius":"","ionizationEnergy":717,"electronAffinity":0,"oxidationStates":"-3, -2, -1, 1, 2, 3, 4, 5, 6, 7","standardState":"solid","bondingType":"metallic","meltingPoint":1519,"boilingPoint":2334,"density":7.47,"groupBlock":"transition metal","yearDiscovered":1774},
  {"atomicNumber":26,"symbol":"Fe","name":"Iron","atomicMass":"55.845(2)","cpkHexColor":"E06633","electronicConfiguration":"[Ar] 3d6 4s2","electronegativity":1.83,"atomicRadius":125,"ionRadius":"78 (+2*)","vanDelWaalsRadius":"","ionizationEnergy":763,"electronAffinity":-16,"oxidationStates":"-2, -1, 1, 2, 3, 4, 5, 6","standardState":"solid","bondingType":"metallic","meltingPoint":1811,"boilingPoint":3134,"density":7.874,"groupBlock":"transition metal","yearDiscovered":"Ancient"},
  {"atomicNumber":27,"symbol":"Co","name":"Cobalt","atomicMass":"58.933195(5)","cpkHexColor":"F090A0","electronicConfiguration":"[Ar] 3d7 4s2","electronegativity":1.88,"atomicRadius":126,"ionRadius":"74.5 (+2*)","vanDelWaalsRadius":"","ionizationEnergy":760,"electronAffinity":-64,"oxidationStates":"-1, 1, 2, 3, 4, 5","standardState":"solid","bondingType":"metallic","meltingPoint":1768,"boilingPoint":3200,"density":8.9,"groupBlock":"transition metal","yearDiscovered":"Ancient"},
  {"atomicNumber":28,"symbol":"Ni","name":"Nickel","atomicMass":"58.6934(4)","cpkHexColor":"50D050","electronicConfiguration":"[Ar] 3d8 4s2","electronegativity":1.91,"atomicRadius":121,"ionRadius":"69 (+2)","vanDelWaalsRadius":163,"ionizationEnergy":737,"electronAffinity":-112,"oxidationStates":"-1, 1, 2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1728,"boilingPoint":3186,"density":8.908,"groupBlock":"transition metal","yearDiscovered":1751},
  {"atomicNumber":29,"symbol":"Cu","name":"Copper","atomicMass":"63.546(3)","cpkHexColor":"C88033","electronicConfiguration":"[Ar] 3d10 4s1","electronegativity":1.9,"atomicRadius":138,"ionRadius":"77 (+1)","vanDelWaalsRadius":140,"ionizationEnergy":746,"electronAffinity":-118,"oxidationStates":"1, 2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1358,"boilingPoint":3200,"density":8.92,"groupBlock":"transition metal","yearDiscovered":"Ancient"},
  {"atomicNumber":30,"symbol":"Zn","name":"Zinc","atomicMass":"65.38(2)","cpkHexColor":"7D80B0","electronicConfiguration":"[Ar] 3d10 4s2","electronegativity":1.65,"atomicRadius":131,"ionRadius":"74 (+2)","vanDelWaalsRadius":139,"ionizationEnergy":906,"electronAffinity":0,"oxidationStates":2,"standardState":"solid","bondingType":"metallic","meltingPoint":693,"boilingPoint":1180,"density":7.14,"groupBlock":"transition metal","yearDiscovered":1746},
  {"atomicNumber":31,"symbol":"Ga","name":"Gallium","atomicMass":"69.723(1)","cpkHexColor":"C28F8F","electronicConfiguration":"[Ar] 3d10 4s2 4p1","electronegativity":1.81,"atomicRadius":126,"ionRadius":"62 (+3)","vanDelWaalsRadius":187,"ionizationEnergy":579,"electronAffinity":-29,"oxidationStates":"1, 2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":303,"boilingPoint":2477,"density":5.904,"groupBlock":"metal","yearDiscovered":1875},
  {"atomicNumber":32,"symbol":"Ge","name":"Germanium","atomicMass":"72.64(1)","cpkHexColor":"668F8F","electronicConfiguration":"[Ar] 3d10 4s2 4p2","electronegativity":2.01,"atomicRadius":122,"ionRadius":"73 (+2)","vanDelWaalsRadius":"","ionizationEnergy":762,"electronAffinity":-119,"oxidationStates":"-4, 1, 2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1211,"boilingPoint":3093,"density":5.323,"groupBlock":"metalloid","yearDiscovered":1886},
  {"atomicNumber":33,"symbol":"As","name":"Arsenic","atomicMass":"74.92160(2)","cpkHexColor":"BD80E3","electronicConfiguration":"[Ar] 3d10 4s2 4p3","electronegativity":2.18,"atomicRadius":119,"ionRadius":"58 (+3)","vanDelWaalsRadius":185,"ionizationEnergy":947,"electronAffinity":-78,"oxidationStates":"-3, 2, 3, 5","standardState":"solid","bondingType":"metallic","meltingPoint":1090,"boilingPoint":887,"density":5.727,"groupBlock":"metalloid","yearDiscovered":"Ancient"},
  {"atomicNumber":34,"symbol":"Se","name":"Selenium","atomicMass":"78.96(3)","cpkHexColor":"FFA100","electronicConfiguration":"[Ar] 3d10 4s2 4p4","electronegativity":2.55,"atomicRadius":116,"ionRadius":"198 (-2)","vanDelWaalsRadius":190,"ionizationEnergy":941,"electronAffinity":-195,"oxidationStates":"-2, 2, 4, 6","standardState":"solid","bondingType":"metallic","meltingPoint":494,"boilingPoint":958,"density":4.819,"groupBlock":"nonmetal","yearDiscovered":1817},
  {"atomicNumber":35,"symbol":"Br","name":"Bromine","atomicMass":"79.904(1)","cpkHexColor":"A62929","electronicConfiguration":"[Ar] 3d10 4s2 4p5","electronegativity":2.96,"atomicRadius":114,"ionRadius":"196 (-1)","vanDelWaalsRadius":185,"ionizationEnergy":1140,"electronAffinity":-325,"oxidationStates":"-1, 1, 3, 4, 5, 7","standardState":"liquid","bondingType":"covalent network","meltingPoint":266,"boilingPoint":332,"density":3.12,"groupBlock":"halogen","yearDiscovered":1826},
  {"atomicNumber":36,"symbol":"Kr","name":"Krypton","atomicMass":"83.798(2)","cpkHexColor":"5CB8D1","electronicConfiguration":"[Ar] 3d10 4s2 4p6","electronegativity":"","atomicRadius":110,"ionRadius":"","vanDelWaalsRadius":202,"ionizationEnergy":1351,"electronAffinity":0,"oxidationStates":2,"standardState":"gas","bondingType":"atomic","meltingPoint":116,"boilingPoint":120,"density":0.00375,"groupBlock":"noble gas","yearDiscovered":1898},
  {"atomicNumber":37,"symbol":"Rb","name":"Rubidium","atomicMass":"85.4678(3)","cpkHexColor":"702EB0","electronicConfiguration":"[Kr] 5s1","electronegativity":0.82,"atomicRadius":211,"ionRadius":"152 (+1)","vanDelWaalsRadius":"","ionizationEnergy":403,"electronAffinity":-47,"oxidationStates":1,"standardState":"solid","bondingType":"metallic","meltingPoint":312,"boilingPoint":961,"density":1.532,"groupBlock":"alkali metal","yearDiscovered":1861},
  {"atomicNumber":38,"symbol":"Sr","name":"Strontium","atomicMass":"87.62(1)","cpkHexColor":"00FF00","electronicConfiguration":"[Kr] 5s2","electronegativity":0.95,"atomicRadius":192,"ionRadius":"118 (+2)","vanDelWaalsRadius":"","ionizationEnergy":550,"electronAffinity":-5,"oxidationStates":2,"standardState":"solid","bondingType":"metallic","meltingPoint":1050,"boilingPoint":1655,"density":2.63,"groupBlock":"alkaline earth metal","yearDiscovered":1790},
  {"atomicNumber":39,"symbol":"Y","name":"Yttrium","atomicMass":"88.90585(2)","cpkHexColor":"94FFFF","electronicConfiguration":"[Kr] 4d1 5s2","electronegativity":1.22,"atomicRadius":162,"ionRadius":"90 (+3)","vanDelWaalsRadius":"","ionizationEnergy":600,"electronAffinity":-30,"oxidationStates":"1, 2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1799,"boilingPoint":3618,"density":4.472,"groupBlock":"transition metal","yearDiscovered":1794},
  {"atomicNumber":40,"symbol":"Zr","name":"Zirconium","atomicMass":"91.224(2)","cpkHexColor":"94E0E0","electronicConfiguration":"[Kr] 4d2 5s2","electronegativity":1.33,"atomicRadius":148,"ionRadius":"72 (+4)","vanDelWaalsRadius":"","ionizationEnergy":640,"electronAffinity":-41,"oxidationStates":"1, 2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":2128,"boilingPoint":4682,"density":6.511,"groupBlock":"transition metal","yearDiscovered":1789},
  {"atomicNumber":41,"symbol":"Nb","name":"Niobium","atomicMass":"92.90638(2)","cpkHexColor":"73C2C9","electronicConfiguration":"[Kr] 4d4 5s1","electronegativity":1.6,"atomicRadius":137,"ionRadius":"72 (+3)","vanDelWaalsRadius":"","ionizationEnergy":652,"electronAffinity":-86,"oxidationStates":"-1, 2, 3, 4, 5","standardState":"solid","bondingType":"metallic","meltingPoint":2750,"boilingPoint":5017,"density":8.57,"groupBlock":"transition metal","yearDiscovered":1801},
  {"atomicNumber":42,"symbol":"Mo","name":"Molybdenum","atomicMass":"95.96(2)","cpkHexColor":"54B5B5","electronicConfiguration":"[Kr] 4d5 5s1","electronegativity":2.16,"atomicRadius":145,"ionRadius":"69 (+3)","vanDelWaalsRadius":"","ionizationEnergy":684,"electronAffinity":-72,"oxidationStates":"-2, -1, 1, 2, 3, 4, 5, 6","standardState":"solid","bondingType":"metallic","meltingPoint":2896,"boilingPoint":4912,"density":10.28,"groupBlock":"transition metal","yearDiscovered":1778},
  {"atomicNumber":43,"symbol":"Tc","name":"Technetium","atomicMass":[98],"cpkHexColor":"3B9E9E","electronicConfiguration":"[Kr] 4d5 5s2","electronegativity":1.9,"atomicRadius":156,"ionRadius":"64.5 (+4)","vanDelWaalsRadius":"","ionizationEnergy":702,"electronAffinity":-53,"oxidationStates":"-3, -1, 1, 2, 3, 4, 5, 6, 7","standardState":"solid","bondingType":"metallic","meltingPoint":2430,"boilingPoint":4538,"density":11.5,"groupBlock":"transition metal","yearDiscovered":1937},
  {"atomicNumber":44,"symbol":"Ru","name":"Ruthenium","atomicMass":"101.07(2)","cpkHexColor":"248F8F","electronicConfiguration":"[Kr] 4d7 5s1","electronegativity":2.2,"atomicRadius":126,"ionRadius":"68 (+3)","vanDelWaalsRadius":"","ionizationEnergy":710,"electronAffinity":-101,"oxidationStates":"-2, 1, 2, 3, 4, 5, 6, 7, 8","standardState":"solid","bondingType":"metallic","meltingPoint":2607,"boilingPoint":4423,"density":12.37,"groupBlock":"transition metal","yearDiscovered":1827},
  {"atomicNumber":45,"symbol":"Rh","name":"Rhodium","atomicMass":"102.90550(2)","cpkHexColor":"0A7D8C","electronicConfiguration":"[Kr] 4d8 5s1","electronegativity":2.28,"atomicRadius":135,"ionRadius":"66.5 (+3)","vanDelWaalsRadius":"","ionizationEnergy":720,"electronAffinity":-110,"oxidationStates":"-1, 1, 2, 3, 4, 5, 6","standardState":"solid","bondingType":"metallic","meltingPoint":2237,"boilingPoint":3968,"density":12.45,"groupBlock":"transition metal","yearDiscovered":1803},
  {"atomicNumber":46,"symbol":"Pd","name":"Palladium","atomicMass":"106.42(1)","cpkHexColor":6985,"electronicConfiguration":"[Kr] 4d10","electronegativity":2.2,"atomicRadius":131,"ionRadius":"59 (+1)","vanDelWaalsRadius":163,"ionizationEnergy":804,"electronAffinity":-54,"oxidationStates":"2, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1828,"boilingPoint":3236,"density":12.023,"groupBlock":"transition metal","yearDiscovered":1803},
  {"atomicNumber":47,"symbol":"Ag","name":"Silver","atomicMass":"107.8682(2)","cpkHexColor":"C0C0C0","electronicConfiguration":"[Kr] 4d10 5s1","electronegativity":1.93,"atomicRadius":153,"ionRadius":"115 (+1)","vanDelWaalsRadius":172,"ionizationEnergy":731,"electronAffinity":-126,"oxidationStates":"1, 2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1235,"boilingPoint":2435,"density":10.49,"groupBlock":"transition metal","yearDiscovered":"Ancient"},
  {"atomicNumber":48,"symbol":"Cd","name":"Cadmium","atomicMass":"112.411(8)","cpkHexColor":"FFD98F","electronicConfiguration":"[Kr] 4d10 5s2","electronegativity":1.69,"atomicRadius":148,"ionRadius":"95 (+2)","vanDelWaalsRadius":158,"ionizationEnergy":868,"electronAffinity":0,"oxidationStates":2,"standardState":"solid","bondingType":"metallic","meltingPoint":594,"boilingPoint":1040,"density":8.65,"groupBlock":"transition metal","yearDiscovered":1817},
  {"atomicNumber":49,"symbol":"In","name":"Indium","atomicMass":"114.818(3)","cpkHexColor":"A67573","electronicConfiguration":"[Kr] 4d10 5s2 5p1","electronegativity":1.78,"atomicRadius":144,"ionRadius":"80 (+3)","vanDelWaalsRadius":193,"ionizationEnergy":558,"electronAffinity":-29,"oxidationStates":"1, 2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":430,"boilingPoint":2345,"density":7.31,"groupBlock":"metal","yearDiscovered":1863},
  {"atomicNumber":50,"symbol":"Sn","name":"Tin","atomicMass":"118.710(7)","cpkHexColor":668080,"electronicConfiguration":"[Kr] 4d10 5s2 5p2","electronegativity":1.96,"atomicRadius":141,"ionRadius":"112 (+2)","vanDelWaalsRadius":217,"ionizationEnergy":709,"electronAffinity":-107,"oxidationStates":"-4, 2, 4","standardState":"solid","bondingType":"metallic","meltingPoint":505,"boilingPoint":2875,"density":7.31,"groupBlock":"metal","yearDiscovered":"Ancient"},
  {"atomicNumber":51,"symbol":"Sb","name":"Antimony","atomicMass":"121.760(1)","cpkHexColor":"9E63B5","electronicConfiguration":"[Kr] 4d10 5s2 5p3","electronegativity":2.05,"atomicRadius":138,"ionRadius":"76 (+3)","vanDelWaalsRadius":"","ionizationEnergy":834,"electronAffinity":-103,"oxidationStates":"-3, 3, 5","standardState":"solid","bondingType":"metallic","meltingPoint":904,"boilingPoint":1860,"density":6.697,"groupBlock":"metalloid","yearDiscovered":"Ancient"},
  {"atomicNumber":52,"symbol":"Te","name":"Tellurium","atomicMass":"127.60(3)","cpkHexColor":"D47A00","electronicConfiguration":"[Kr] 4d10 5s2 5p4","electronegativity":2.1,"atomicRadius":135,"ionRadius":"221 (-2)","vanDelWaalsRadius":206,"ionizationEnergy":869,"electronAffinity":-190,"oxidationStates":"-2, 2, 4, 5, 6","standardState":"solid","bondingType":"metallic","meltingPoint":723,"boilingPoint":1261,"density":6.24,"groupBlock":"metalloid","yearDiscovered":1782},
  {"atomicNumber":53,"symbol":"I","name":"Iodine","atomicMass":"126.90447(3)","cpkHexColor":940094,"electronicConfiguration":"[Kr] 4d10 5s2 5p5","electronegativity":2.66,"atomicRadius":133,"ionRadius":"220 (-1)","vanDelWaalsRadius":198,"ionizationEnergy":1008,"electronAffinity":-295,"oxidationStates":"-1, 1, 3, 5, 7","standardState":"solid","bondingType":"covalent network","meltingPoint":387,"boilingPoint":457,"density":4.94,"groupBlock":"halogen","yearDiscovered":1811},
  {"atomicNumber":54,"symbol":"Xe","name":"Xenon","atomicMass":"131.293(6)","cpkHexColor":"429EB0","electronicConfiguration":"[Kr] 4d10 5s2 5p6","electronegativity":"","atomicRadius":130,"ionRadius":"48 (+8)","vanDelWaalsRadius":216,"ionizationEnergy":1170,"electronAffinity":0,"oxidationStates":"2, 4, 6, 8","standardState":"gas","bondingType":"atomic","meltingPoint":161,"boilingPoint":165,"density":0.0059,"groupBlock":"noble gas","yearDiscovered":1898},
  {"atomicNumber":55,"symbol":"Cs","name":"Cesium","atomicMass":"132.9054519(2)","cpkHexColor":"57178F","electronicConfiguration":"[Xe] 6s1","electronegativity":0.79,"atomicRadius":225,"ionRadius":"167 (+1)","vanDelWaalsRadius":"","ionizationEnergy":376,"electronAffinity":-46,"oxidationStates":1,"standardState":"solid","bondingType":"metallic","meltingPoint":302,"boilingPoint":944,"density":1.879,"groupBlock":"alkali metal","yearDiscovered":1860},
  {"atomicNumber":56,"symbol":"Ba","name":"Barium","atomicMass":"137.327(7)","cpkHexColor":"00C900","electronicConfiguration":"[Xe] 6s2","electronegativity":0.89,"atomicRadius":198,"ionRadius":"135 (+2)","vanDelWaalsRadius":"","ionizationEnergy":503,"electronAffinity":-14,"oxidationStates":2,"standardState":"solid","bondingType":"metallic","meltingPoint":1000,"boilingPoint":2143,"density":3.51,"groupBlock":"alkaline earth metal","yearDiscovered":1808},
  {"atomicNumber":57,"symbol":"La","name":"Lanthanum","atomicMass":"138.90547(7)","cpkHexColor":"70D4FF","electronicConfiguration":"[Xe] 5d1 6s2","electronegativity":1.1,"atomicRadius":169,"ionRadius":"103.2 (+3)","vanDelWaalsRadius":"","ionizationEnergy":538,"electronAffinity":-48,"oxidationStates":"2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1193,"boilingPoint":3737,"density":6.146,"groupBlock":"lanthanoid","yearDiscovered":1839},
  {"atomicNumber":58,"symbol":"Ce","name":"Cerium","atomicMass":"140.116(1)","cpkHexColor":"FFFFC7","electronicConfiguration":"[Xe] 4f1 5d1 6s2","electronegativity":1.12,"atomicRadius":"","ionRadius":"102 (+3)","vanDelWaalsRadius":"","ionizationEnergy":534,"electronAffinity":-50,"oxidationStates":"2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1071,"boilingPoint":3633,"density":6.689,"groupBlock":"lanthanoid","yearDiscovered":1803},
  {"atomicNumber":59,"symbol":"Pr","name":"Praseodymium","atomicMass":"140.90765(2)","cpkHexColor":"D9FFC7","electronicConfiguration":"[Xe] 4f3 6s2","electronegativity":1.13,"atomicRadius":"","ionRadius":"99 (+3)","vanDelWaalsRadius":"","ionizationEnergy":527,"electronAffinity":-50,"oxidationStates":"2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1204,"boilingPoint":3563,"density":6.64,"groupBlock":"lanthanoid","yearDiscovered":1885},
  {"atomicNumber":60,"symbol":"Nd","name":"Neodymium","atomicMass":"144.242(3)","cpkHexColor":"C7FFC7","electronicConfiguration":"[Xe] 4f4 6s2","electronegativity":1.14,"atomicRadius":"","ionRadius":"129 (+2)","vanDelWaalsRadius":"","ionizationEnergy":533,"electronAffinity":-50,"oxidationStates":"2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1294,"boilingPoint":3373,"density":7.01,"groupBlock":"lanthanoid","yearDiscovered":1885},
  {"atomicNumber":61,"symbol":"Pm","name":"Promethium","atomicMass":[145],"cpkHexColor":"A3FFC7","electronicConfiguration":"[Xe] 4f5 6s2","electronegativity":1.13,"atomicRadius":"","ionRadius":"97 (+3)","vanDelWaalsRadius":"","ionizationEnergy":540,"electronAffinity":-50,"oxidationStates":3,"standardState":"solid","bondingType":"metallic","meltingPoint":1373,"boilingPoint":3273,"density":7.264,"groupBlock":"lanthanoid","yearDiscovered":1947},
  {"atomicNumber":62,"symbol":"Sm","name":"Samarium","atomicMass":"150.36(2)","cpkHexColor":"8FFFC7","electronicConfiguration":"[Xe] 4f6 6s2","electronegativity":1.17,"atomicRadius":"","ionRadius":"122 (+2)","vanDelWaalsRadius":"","ionizationEnergy":545,"electronAffinity":-50,"oxidationStates":"2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1345,"boilingPoint":2076,"density":7.353,"groupBlock":"lanthanoid","yearDiscovered":1853},
  {"atomicNumber":63,"symbol":"Eu","name":"Europium","atomicMass":"151.964(1)","cpkHexColor":"61FFC7","electronicConfiguration":"[Xe] 4f7 6s2","electronegativity":1.2,"atomicRadius":"","ionRadius":"117 (+2)","vanDelWaalsRadius":"","ionizationEnergy":547,"electronAffinity":-50,"oxidationStates":"2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1095,"boilingPoint":1800,"density":5.244,"groupBlock":"lanthanoid","yearDiscovered":1901},
  {"atomicNumber":64,"symbol":"Gd","name":"Gadolinium","atomicMass":"157.25(3)","cpkHexColor":"45FFC7","electronicConfiguration":"[Xe] 4f7 5d1 6s2","electronegativity":1.2,"atomicRadius":"","ionRadius":"93.8 (+3)","vanDelWaalsRadius":"","ionizationEnergy":593,"electronAffinity":-50,"oxidationStates":"1, 2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1586,"boilingPoint":3523,"density":7.901,"groupBlock":"lanthanoid","yearDiscovered":1880},
  {"atomicNumber":65,"symbol":"Tb","name":"Terbium","atomicMass":"158.92535(2)","cpkHexColor":"30FFC7","electronicConfiguration":"[Xe] 4f9 6s2","electronegativity":1.2,"atomicRadius":"","ionRadius":"92.3 (+3)","vanDelWaalsRadius":"","ionizationEnergy":566,"electronAffinity":-50,"oxidationStates":"1, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1629,"boilingPoint":3503,"density":8.219,"groupBlock":"lanthanoid","yearDiscovered":1843},
  {"atomicNumber":66,"symbol":"Dy","name":"Dysprosium","atomicMass":"162.500(1)","cpkHexColor":"1FFFC7","electronicConfiguration":"[Xe] 4f10 6s2","electronegativity":1.22,"atomicRadius":"","ionRadius":"107 (+2)","vanDelWaalsRadius":"","ionizationEnergy":573,"electronAffinity":-50,"oxidationStates":"2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1685,"boilingPoint":2840,"density":8.551,"groupBlock":"lanthanoid","yearDiscovered":1886},
  {"atomicNumber":67,"symbol":"Ho","name":"Holmium","atomicMass":"164.93032(2)","cpkHexColor":"00FF9C","electronicConfiguration":"[Xe] 4f11 6s2","electronegativity":1.23,"atomicRadius":"","ionRadius":"90.1 (+3)","vanDelWaalsRadius":"","ionizationEnergy":581,"electronAffinity":-50,"oxidationStates":3,"standardState":"solid","bondingType":"metallic","meltingPoint":1747,"boilingPoint":2973,"density":8.795,"groupBlock":"lanthanoid","yearDiscovered":1878},
  {"atomicNumber":68,"symbol":"Er","name":"Erbium","atomicMass":"167.259(3)","cpkHexColor":0,"electronicConfiguration":"[Xe] 4f12 6s2","electronegativity":1.24,"atomicRadius":"","ionRadius":"89 (+3)","vanDelWaalsRadius":"","ionizationEnergy":589,"electronAffinity":-50,"oxidationStates":3,"standardState":"solid","bondingType":"metallic","meltingPoint":1770,"boilingPoint":3141,"density":9.066,"groupBlock":"lanthanoid","yearDiscovered":1842},
  {"atomicNumber":69,"symbol":"Tm","name":"Thulium","atomicMass":"168.93421(2)","cpkHexColor":"00D452","electronicConfiguration":"[Xe] 4f13 6s2","electronegativity":1.25,"atomicRadius":"","ionRadius":"103 (+2)","vanDelWaalsRadius":"","ionizationEnergy":597,"electronAffinity":-50,"oxidationStates":"2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1818,"boilingPoint":2223,"density":9.321,"groupBlock":"lanthanoid","yearDiscovered":1879},
  {"atomicNumber":70,"symbol":"Yb","name":"Ytterbium","atomicMass":"173.054(5)","cpkHexColor":"00BF38","electronicConfiguration":"[Xe] 4f14 6s2","electronegativity":1.1,"atomicRadius":"","ionRadius":"102 (+2)","vanDelWaalsRadius":"","ionizationEnergy":603,"electronAffinity":-50,"oxidationStates":"2, 3","standardState":"solid","bondingType":"metallic","meltingPoint":1092,"boilingPoint":1469,"density":6.57,"groupBlock":"lanthanoid","yearDiscovered":1878},
  {"atomicNumber":71,"symbol":"Lu","name":"Lutetium","atomicMass":"174.9668(1)","cpkHexColor":"00AB24","electronicConfiguration":"[Xe] 4f14 5d1 6s2","electronegativity":1.27,"atomicRadius":160,"ionRadius":"86.1 (+3)","vanDelWaalsRadius":"","ionizationEnergy":524,"electronAffinity":-50,"oxidationStates":3,"standardState":"solid","bondingType":"metallic","meltingPoint":1936,"boilingPoint":3675,"density":9.841,"groupBlock":"lanthanoid","yearDiscovered":1907},
  {"atomicNumber":72,"symbol":"Hf","name":"Hafnium","atomicMass":"178.49(2)","cpkHexColor":"4DC2FF","electronicConfiguration":"[Xe] 4f14 5d2 6s2","electronegativity":1.3,"atomicRadius":150,"ionRadius":"71 (+4)","vanDelWaalsRadius":"","ionizationEnergy":659,"electronAffinity":0,"oxidationStates":"2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":2506,"boilingPoint":4876,"density":13.31,"groupBlock":"transition metal","yearDiscovered":1923},
  {"atomicNumber":73,"symbol":"Ta","name":"Tantalum","atomicMass":"180.94788(2)","cpkHexColor":"4DA6FF","electronicConfiguration":"[Xe] 4f14 5d3 6s2","electronegativity":1.5,"atomicRadius":138,"ionRadius":"72 (+3)","vanDelWaalsRadius":"","ionizationEnergy":761,"electronAffinity":-31,"oxidationStates":"-1, 2, 3, 4, 5","standardState":"solid","bondingType":"metallic","meltingPoint":3290,"boilingPoint":5731,"density":16.65,"groupBlock":"transition metal","yearDiscovered":1802},
  {"atomicNumber":74,"symbol":"W","name":"Tungsten","atomicMass":"183.84(1)","cpkHexColor":"2194D6","electronicConfiguration":"[Xe] 4f14 5d4 6s2","electronegativity":2.36,"atomicRadius":146,"ionRadius":"66 (+4)","vanDelWaalsRadius":"","ionizationEnergy":770,"electronAffinity":-79,"oxidationStates":"-2, -1, 1, 2, 3, 4, 5, 6","standardState":"solid","bondingType":"metallic","meltingPoint":3695,"boilingPoint":5828,"density":19.25,"groupBlock":"transition metal","yearDiscovered":1783},
  {"atomicNumber":75,"symbol":"Re","name":"Rhenium","atomicMass":"186.207(1)","cpkHexColor":"267DAB","electronicConfiguration":"[Xe] 4f14 5d5 6s2","electronegativity":1.9,"atomicRadius":159,"ionRadius":"63 (+4)","vanDelWaalsRadius":"","ionizationEnergy":760,"electronAffinity":-15,"oxidationStates":"-3, -1, 1, 2, 3, 4, 5, 6, 7","standardState":"solid","bondingType":"metallic","meltingPoint":3459,"boilingPoint":5869,"density":21.02,"groupBlock":"transition metal","yearDiscovered":1925},
  {"atomicNumber":76,"symbol":"Os","name":"Osmium","atomicMass":"190.23(3)","cpkHexColor":266696,"electronicConfiguration":"[Xe] 4f14 5d6 6s2","electronegativity":2.2,"atomicRadius":128,"ionRadius":"63 (+4)","vanDelWaalsRadius":"","ionizationEnergy":840,"electronAffinity":-106,"oxidationStates":"-2, -1, 1, 2, 3, 4, 5, 6, 7, 8","standardState":"solid","bondingType":"metallic","meltingPoint":3306,"boilingPoint":5285,"density":22.61,"groupBlock":"transition metal","yearDiscovered":1803},
  {"atomicNumber":77,"symbol":"Ir","name":"Iridium","atomicMass":"192.217(3)","cpkHexColor":175487,"electronicConfiguration":"[Xe] 4f14 5d7 6s2","electronegativity":2.2,"atomicRadius":137,"ionRadius":"68 (+3)","vanDelWaalsRadius":"","ionizationEnergy":880,"electronAffinity":-151,"oxidationStates":"-3, -1, 1, 2, 3, 4, 5, 6","standardState":"solid","bondingType":"metallic","meltingPoint":2739,"boilingPoint":4701,"density":22.65,"groupBlock":"transition metal","yearDiscovered":1803},
  {"atomicNumber":78,"symbol":"Pt","name":"Platinum","atomicMass":"195.084(9)","cpkHexColor":"D0D0E0","electronicConfiguration":"[Xe] 4f14 5d9 6s1","electronegativity":2.28,"atomicRadius":128,"ionRadius":"86 (+2)","vanDelWaalsRadius":175,"ionizationEnergy":870,"electronAffinity":-205,"oxidationStates":"2, 4, 5, 6","standardState":"solid","bondingType":"metallic","meltingPoint":2041,"boilingPoint":4098,"density":21.09,"groupBlock":"transition metal","yearDiscovered":"Ancient"},
  {"atomicNumber":79,"symbol":"Au","name":"Gold","atomicMass":"196.966569(4)","cpkHexColor":"FFD123","electronicConfiguration":"[Xe] 4f14 5d10 6s1","electronegativity":2.54,"atomicRadius":144,"ionRadius":"137 (+1)","vanDelWaalsRadius":166,"ionizationEnergy":890,"electronAffinity":-223,"oxidationStates":"-1, 1, 2, 3, 5","standardState":"solid","bondingType":"metallic","meltingPoint":1337,"boilingPoint":3129,"density":19.3,"groupBlock":"transition metal","yearDiscovered":"Ancient"},
  {"atomicNumber":80,"symbol":"Hg","name":"Mercury","atomicMass":"200.59(2)","cpkHexColor":"B8B8D0","electronicConfiguration":"[Xe] 4f14 5d10 6s2","electronegativity":2,"atomicRadius":149,"ionRadius":"119 (+1)","vanDelWaalsRadius":155,"ionizationEnergy":1007,"electronAffinity":0,"oxidationStates":"1, 2, 4","standardState":"liquid","bondingType":"metallic","meltingPoint":234,"boilingPoint":630,"density":13.534,"groupBlock":"transition metal","yearDiscovered":"Ancient"},
  {"atomicNumber":81,"symbol":"Tl","name":"Thallium","atomicMass":"204.3833(2)","cpkHexColor":"A6544D","electronicConfiguration":"[Xe] 4f14 5d10 6s2 6p1","electronegativity":2.04,"atomicRadius":148,"ionRadius":"150 (+1)","vanDelWaalsRadius":196,"ionizationEnergy":589,"electronAffinity":-19,"oxidationStates":"1, 3","standardState":"solid","bondingType":"metallic","meltingPoint":577,"boilingPoint":1746,"density":11.85,"groupBlock":"metal","yearDiscovered":1861},
  {"atomicNumber":82,"symbol":"Pb","name":"Lead","atomicMass":"207.2(1)","cpkHexColor":575961,"electronicConfiguration":"[Xe] 4f14 5d10 6s2 6p2","electronegativity":2.33,"atomicRadius":147,"ionRadius":"119 (+2)","vanDelWaalsRadius":202,"ionizationEnergy":716,"electronAffinity":-35,"oxidationStates":"-4, 2, 4","standardState":"solid","bondingType":"metallic","meltingPoint":601,"boilingPoint":2022,"density":11.34,"groupBlock":"metal","yearDiscovered":"Ancient"},
  {"atomicNumber":83,"symbol":"Bi","name":"Bismuth","atomicMass":"208.98040(1)","cpkHexColor":"9E4FB5","electronicConfiguration":"[Xe] 4f14 5d10 6s2 6p3","electronegativity":2.02,"atomicRadius":146,"ionRadius":"103 (+3)","vanDelWaalsRadius":"","ionizationEnergy":703,"electronAffinity":-91,"oxidationStates":"-3, 3, 5","standardState":"solid","bondingType":"metallic","meltingPoint":544,"boilingPoint":1837,"density":9.78,"groupBlock":"metal","yearDiscovered":"Ancient"},
  {"atomicNumber":84,"symbol":"Po","name":"Polonium","atomicMass":[209],"cpkHexColor":"AB5C00","electronicConfiguration":"[Xe] 4f14 5d10 6s2 6p4","electronegativity":2,"atomicRadius":"","ionRadius":"94 (+4)","vanDelWaalsRadius":"","ionizationEnergy":812,"electronAffinity":-183,"oxidationStates":"-2, 2, 4, 6","standardState":"solid","bondingType":"metallic","meltingPoint":527,"boilingPoint":1235,"density":9.196,"groupBlock":"metalloid","yearDiscovered":1898},
  {"atomicNumber":85,"symbol":"At","name":"Astatine","atomicMass":[210],"cpkHexColor":"754F45","electronicConfiguration":"[Xe] 4f14 5d10 6s2 6p5","electronegativity":2.2,"atomicRadius":"","ionRadius":"62 (+7)","vanDelWaalsRadius":"","ionizationEnergy":920,"electronAffinity":-270,"oxidationStates":"-1, 1, 3, 5","standardState":"solid","bondingType":"covalent network","meltingPoint":575,"boilingPoint":"","density":"","groupBlock":"halogen","yearDiscovered":1940},
  {"atomicNumber":86,"symbol":"Rn","name":"Radon","atomicMass":[222],"cpkHexColor":428296,"electronicConfiguration":"[Xe] 4f14 5d10 6s2 6p6","electronegativity":"","atomicRadius":145,"ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":1037,"electronAffinity":"","oxidationStates":2,"standardState":"gas","bondingType":"atomic","meltingPoint":202,"boilingPoint":211,"density":0.00973,"groupBlock":"noble gas","yearDiscovered":1900},
  {"atomicNumber":87,"symbol":"Fr","name":"Francium","atomicMass":[223],"cpkHexColor":420066,"electronicConfiguration":"[Rn] 7s1","electronegativity":0.7,"atomicRadius":"","ionRadius":"180 (+1)","vanDelWaalsRadius":"","ionizationEnergy":380,"electronAffinity":"","oxidationStates":1,"standardState":"solid","bondingType":"metallic","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"alkali metal","yearDiscovered":1939},
  {"atomicNumber":88,"symbol":"Ra","name":"Radium","atomicMass":[226],"cpkHexColor":"007D00","electronicConfiguration":"[Rn] 7s2","electronegativity":0.9,"atomicRadius":"","ionRadius":"148 (+2)","vanDelWaalsRadius":"","ionizationEnergy":509,"electronAffinity":"","oxidationStates":2,"standardState":"solid","bondingType":"metallic","meltingPoint":973,"boilingPoint":2010,"density":5,"groupBlock":"alkaline earth metal","yearDiscovered":1898},
  {"atomicNumber":89,"symbol":"Ac","name":"Actinium","atomicMass":[227],"cpkHexColor":"70ABFA","electronicConfiguration":"[Rn] 6d1 7s2","electronegativity":1.1,"atomicRadius":"","ionRadius":"112 (+3)","vanDelWaalsRadius":"","ionizationEnergy":499,"electronAffinity":"","oxidationStates":3,"standardState":"solid","bondingType":"metallic","meltingPoint":1323,"boilingPoint":3473,"density":10.07,"groupBlock":"actinoid","yearDiscovered":1899},
  {"atomicNumber":90,"symbol":"Th","name":"Thorium","atomicMass":"232.03806(2)","cpkHexColor":"00BAFF","electronicConfiguration":"[Rn] 6d2 7s2","electronegativity":1.3,"atomicRadius":"","ionRadius":"94 (+4)","vanDelWaalsRadius":"","ionizationEnergy":587,"electronAffinity":"","oxidationStates":"2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":2023,"boilingPoint":5093,"density":11.724,"groupBlock":"actinoid","yearDiscovered":1828},
  {"atomicNumber":91,"symbol":"Pa","name":"Protactinium","atomicMass":"231.03588(2)","cpkHexColor":"00A1FF","electronicConfiguration":"[Rn] 5f2 6d1 7s2","electronegativity":1.5,"atomicRadius":"","ionRadius":"104 (+3)","vanDelWaalsRadius":"","ionizationEnergy":568,"electronAffinity":"","oxidationStates":"3, 4, 5","standardState":"solid","bondingType":"metallic","meltingPoint":1845,"boilingPoint":4273,"density":15.37,"groupBlock":"actinoid","yearDiscovered":1913},
  {"atomicNumber":92,"symbol":"U","name":"Uranium","atomicMass":"238.02891(3)","cpkHexColor":"008FFF","electronicConfiguration":"[Rn] 5f3 6d1 7s2","electronegativity":1.38,"atomicRadius":"","ionRadius":"102.5 (+3)","vanDelWaalsRadius":186,"ionizationEnergy":598,"electronAffinity":"","oxidationStates":"3, 4, 5, 6","standardState":"solid","bondingType":"metallic","meltingPoint":1408,"boilingPoint":4200,"density":19.05,"groupBlock":"actinoid","yearDiscovered":1789},
  {"atomicNumber":93,"symbol":"Np","name":"Neptunium","atomicMass":[237],"cpkHexColor":"0080FF","electronicConfiguration":"[Rn] 5f4 6d1 7s2","electronegativity":1.36,"atomicRadius":"","ionRadius":"110 (+2)","vanDelWaalsRadius":"","ionizationEnergy":605,"electronAffinity":"","oxidationStates":"3, 4, 5, 6, 7","standardState":"solid","bondingType":"metallic","meltingPoint":917,"boilingPoint":4273,"density":20.45,"groupBlock":"actinoid","yearDiscovered":1940},
  {"atomicNumber":94,"symbol":"Pu","name":"Plutonium","atomicMass":[244],"cpkHexColor":"006BFF","electronicConfiguration":"[Rn] 5f6 7s2","electronegativity":1.28,"atomicRadius":"","ionRadius":"100 (+3)","vanDelWaalsRadius":"","ionizationEnergy":585,"electronAffinity":"","oxidationStates":"3, 4, 5, 6, 7","standardState":"solid","bondingType":"metallic","meltingPoint":913,"boilingPoint":3503,"density":19.816,"groupBlock":"actinoid","yearDiscovered":1940},
  {"atomicNumber":95,"symbol":"Am","name":"Americium","atomicMass":[243],"cpkHexColor":"545CF2","electronicConfiguration":"[Rn] 5f7 7s2","electronegativity":1.3,"atomicRadius":"","ionRadius":"126 (+2)","vanDelWaalsRadius":"","ionizationEnergy":578,"electronAffinity":"","oxidationStates":"2, 3, 4, 5, 6","standardState":"solid","bondingType":"metallic","meltingPoint":1449,"boilingPoint":2284,"density":"","groupBlock":"actinoid","yearDiscovered":1944},
  {"atomicNumber":96,"symbol":"Cm","name":"Curium","atomicMass":[247],"cpkHexColor":"785CE3","electronicConfiguration":"[Rn] 5f7 6d1 7s2","electronegativity":1.3,"atomicRadius":"","ionRadius":"97 (+3)","vanDelWaalsRadius":"","ionizationEnergy":581,"electronAffinity":"","oxidationStates":"3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1618,"boilingPoint":3383,"density":13.51,"groupBlock":"actinoid","yearDiscovered":1944},
  {"atomicNumber":97,"symbol":"Bk","name":"Berkelium","atomicMass":[247],"cpkHexColor":"8A4FE3","electronicConfiguration":"[Rn] 5f9 7s2","electronegativity":1.3,"atomicRadius":"","ionRadius":"96 (+3)","vanDelWaalsRadius":"","ionizationEnergy":601,"electronAffinity":"","oxidationStates":"3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1323,"boilingPoint":"","density":14.78,"groupBlock":"actinoid","yearDiscovered":1949},
  {"atomicNumber":98,"symbol":"Cf","name":"Californium","atomicMass":[251],"cpkHexColor":"A136D4","electronicConfiguration":"[Rn] 5f10 7s2","electronegativity":1.3,"atomicRadius":"","ionRadius":"95 (+3)","vanDelWaalsRadius":"","ionizationEnergy":608,"electronAffinity":"","oxidationStates":"2, 3, 4","standardState":"solid","bondingType":"metallic","meltingPoint":1173,"boilingPoint":"","density":15.1,"groupBlock":"actinoid","yearDiscovered":1950},
  {"atomicNumber":99,"symbol":"Es","name":"Einsteinium","atomicMass":[252],"cpkHexColor":"B31FD4","electronicConfiguration":"[Rn] 5f11 7s2","electronegativity":1.3,"atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":619,"electronAffinity":"","oxidationStates":"2, 3","standardState":"solid","bondingType":"","meltingPoint":1133,"boilingPoint":"","density":"","groupBlock":"actinoid","yearDiscovered":1952},
  {"atomicNumber":100,"symbol":"Fm","name":"Fermium","atomicMass":[257],"cpkHexColor":"B31FBA","electronicConfiguration":"[Rn] 5f12 7s2","electronegativity":1.3,"atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":627,"electronAffinity":"","oxidationStates":"2, 3","standardState":"","bondingType":"","meltingPoint":1800,"boilingPoint":"","density":"","groupBlock":"actinoid","yearDiscovered":1952},
  {"atomicNumber":101,"symbol":"Md","name":"Mendelevium","atomicMass":[258],"cpkHexColor":"B30DA6","electronicConfiguration":"[Rn] 5f13 7s2","electronegativity":1.3,"atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":635,"electronAffinity":"","oxidationStates":"2, 3","standardState":"","bondingType":"","meltingPoint":1100,"boilingPoint":"","density":"","groupBlock":"actinoid","yearDiscovered":1955},
  {"atomicNumber":102,"symbol":"No","name":"Nobelium","atomicMass":[259],"cpkHexColor":"BD0D87","electronicConfiguration":"[Rn] 5f14 7s2","electronegativity":1.3,"atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":642,"electronAffinity":"","oxidationStates":"2, 3","standardState":"","bondingType":"","meltingPoint":1100,"boilingPoint":"","density":"","groupBlock":"actinoid","yearDiscovered":1957},
  {"atomicNumber":103,"symbol":"Lr","name":"Lawrencium","atomicMass":[262],"cpkHexColor":"C70066","electronicConfiguration":"[Rn] 5f14 7s2 7p1","electronegativity":1.3,"atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":3,"standardState":"","bondingType":"","meltingPoint":1900,"boilingPoint":"","density":"","groupBlock":"transition metal","yearDiscovered":1961},
  {"atomicNumber":104,"symbol":"Rf","name":"Rutherfordium","atomicMass":[267],"cpkHexColor":"CC0059","electronicConfiguration":"[Rn] 5f14 6d2 7s2","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":4,"standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"transition metal","yearDiscovered":1969},
  {"atomicNumber":105,"symbol":"Db","name":"Dubnium","atomicMass":[268],"cpkHexColor":"D1004F","electronicConfiguration":"[Rn] 5f14 6d3 7s2","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"transition metal","yearDiscovered":1967},
  {"atomicNumber":106,"symbol":"Sg","name":"Seaborgium","atomicMass":[271],"cpkHexColor":"D90045","electronicConfiguration":"[Rn] 5f14 6d4 7s2","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"transition metal","yearDiscovered":1974},
  {"atomicNumber":107,"symbol":"Bh","name":"Bohrium","atomicMass":[272],"cpkHexColor":"E00038","electronicConfiguration":"[Rn] 5f14 6d5 7s2","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"transition metal","yearDiscovered":1976},
  {"atomicNumber":108,"symbol":"Hs","name":"Hassium","atomicMass":[270],"cpkHexColor":"E6002E","electronicConfiguration":"[Rn] 5f14 6d6 7s2","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"transition metal","yearDiscovered":1984},
  {"atomicNumber":109,"symbol":"Mt","name":"Meitnerium","atomicMass":[276],"cpkHexColor":"EB0026","electronicConfiguration":"[Rn] 5f14 6d7 7s2","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"transition metal","yearDiscovered":1982},
  {"atomicNumber":110,"symbol":"Ds","name":"Darmstadtium","atomicMass":[281],"cpkHexColor":"","electronicConfiguration":"[Rn] 5f14 6d9 7s1","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"transition metal","yearDiscovered":1994},
  {"atomicNumber":111,"symbol":"Rg","name":"Roentgenium","atomicMass":[280],"cpkHexColor":"","electronicConfiguration":"[Rn] 5f14 6d10 7s1","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"transition metal","yearDiscovered":1994},
  {"atomicNumber":112,"symbol":"Cn","name":"Copernicium","atomicMass":[285],"cpkHexColor":"","electronicConfiguration":"[Rn] 5f14 6d10 7s2","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"transition metal","yearDiscovered":1996},
  {"atomicNumber":113,"symbol":"Nh","name":"Nihonium","atomicMass":[284],"cpkHexColor":"","electronicConfiguration":"[Rn] 5f14 6d10 7s2 7p1","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"post-transition metal","yearDiscovered":2003},
  {"atomicNumber":114,"symbol":"Fl","name":"Flerovium","atomicMass":[289],"cpkHexColor":"","electronicConfiguration":"[Rn] 5f14 6d10 7s2 7p2","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"post-transition metal","yearDiscovered":1998},
  {"atomicNumber":115,"symbol":"Mc","name":"Moscovium","atomicMass":[288],"cpkHexColor":"","electronicConfiguration":"[Rn] 5f14 6d10 7s2 7p3","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"post-transition metal","yearDiscovered":2003},
  {"atomicNumber":116,"symbol":"Lv","name":"Livermorium","atomicMass":[293],"cpkHexColor":"","electronicConfiguration":"[Rn] 5f14 6d10 7s2 7p4","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"post-transition metal","yearDiscovered":2000},
  {"atomicNumber":117,"symbol":"Ts","name":"Tennessine","atomicMass":[294],"cpkHexColor":"","electronicConfiguration":"[Rn] 5f14 6d10 7s2 7p5","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"post-transition metal","yearDiscovered":2010},
  {"atomicNumber":118,"symbol":"Og","name":"Oganesson","atomicMass":[294],"cpkHexColor":"","electronicConfiguration":"[Rn] 5f14 6d10 7s2 7p6","electronegativity":"","atomicRadius":"","ionRadius":"","vanDelWaalsRadius":"","ionizationEnergy":"","electronAffinity":"","oxidationStates":"","standardState":"","bondingType":"","meltingPoint":"","boilingPoint":"","density":"","groupBlock":"noble gas","yearDiscovered":2002}
  ]
export const colors =  [
  'amaranth',
  'amber',
  'amethyst',
  'apricot',
  'aqua',
  'aquamarine',
  'azure',
  'beige',
  'black',
  'blue',
  'blush',
  'bronze',
  'brown',
  'chocolate',
  'coffee',
  'copper',
  'coral',
  'crimson',
  'cyan',
  'emerald',
  'fuchsia',
  'gold',
  'gray',
  'green',
  'harlequin',
  'indigo',
  'ivory',
  'jade',
  'lavender',
  'lime',
  'magenta',
  'maroon',
  'moccasin',
  'olive',
  'orange',
  'peach',
  'pink',
  'plum',
  'purple',
  'red',
  'rose',
  'salmon',
  'sapphire',
  'scarlet',
  'silver',
  'tan',
  'teal',
  'tomato',
  'turquoise',
  'violet',
  'white',
  'yellow',
];
export const names = [
  'Aaren',
  'Aarika',
  'Abagael',
  'Abagail',
  'Abbe',
  'Abbey',
  'Abbi',
  'Abbie',
  'Abby',
  'Abbye',
  'Abigael',
  'Abigail',
  'Abigale',
  'Abra',
  'Ada',
  'Adah',
  'Adaline',
  'Adan',
  'Adara',
  'Adda',
  'Addi',
  'Addia',
  'Addie',
  'Addy',
  'Adel',
  'Adela',
  'Adelaida',
  'Adelaide',
  'Adele',
  'Adelheid',
  'Adelice',
  'Adelina',
  'Adelind',
  'Adeline',
  'Adella',
  'Adelle',
  'Adena',
  'Adey',
  'Adi',
  'Adiana',
  'Adina',
  'Adora',
  'Adore',
  'Adoree',
  'Adorne',
  'Adrea',
  'Adria',
  'Adriaens',
  'Adrian',
  'Adriana',
  'Adriane',
  'Adrianna',
  'Adrianne',
  'Adriena',
  'Adrienne',
  'Aeriel',
  'Aeriela',
  'Aeriell',
  'Afton',
  'Ag',
  'Agace',
  'Agata',
  'Agatha',
  'Agathe',
  'Aggi',
  'Aggie',
  'Aggy',
  'Agna',
  'Agnella',
  'Agnes',
  'Agnese',
  'Agnesse',
  'Agneta',
  'Agnola',
  'Agretha',
  'Aida',
  'Aidan',
  'Aigneis',
  'Aila',
  'Aile',
  'Ailee',
  'Aileen',
  'Ailene',
  'Ailey',
  'Aili',
  'Ailina',
  'Ailis',
  'Ailsun',
  'Ailyn',
  'Aime',
  'Aimee',
  'Aimil',
  'Aindrea',
  'Ainslee',
  'Ainsley',
  'Ainslie',
  'Ajay',
  'Alaine',
  'Alameda',
  'Alana',
  'Alanah',
  'Alane',
  'Alanna',
  'Alayne',
  'Alberta',
  'Albertina',
  'Albertine',
  'Albina',
  'Alecia',
  'Aleda',
  'Aleece',
  'Aleen',
  'Alejandra',
  'Alejandrina',
  'Alena',
  'Alene',
  'Alessandra',
  'Aleta',
  'Alethea',
  'Alex',
  'Alexa',
  'Alexandra',
  'Alexandrina',
  'Alexi',
  'Alexia',
  'Alexina',
  'Alexine',
  'Alexis',
  'Alfi',
  'Alfie',
  'Alfreda',
  'Alfy',
  'Ali',
  'Alia',
  'Alica',
  'Alice',
  'Alicea',
  'Alicia',
  'Alida',
  'Alidia',
  'Alie',
  'Alika',
  'Alikee',
  'Alina',
  'Aline',
  'Alis',
  'Alisa',
  'Alisha',
  'Alison',
  'Alissa',
  'Alisun',
  'Alix',
  'Aliza',
  'Alla',
  'Alleen',
  'Allegra',
  'Allene',
  'Alli',
  'Allianora',
  'Allie',
  'Allina',
  'Allis',
  'Allison',
  'Allissa',
  'Allix',
  'Allsun',
  'Allx',
  'Ally',
  'Allyce',
  'Allyn',
  'Allys',
  'Allyson',
  'Alma',
  'Almeda',
  'Almeria',
  'Almeta',
  'Almira',
  'Almire',
  'Aloise',
  'Aloisia',
  'Aloysia',
  'Alta',
  'Althea',
  'Alvera',
  'Alverta',
  'Alvina',
  'Alvinia',
  'Alvira',
  'Alyce',
  'Alyda',
  'Alys',
  'Alysa',
  'Alyse',
  'Alysia',
  'Alyson',
  'Alyss',
  'Alyssa',
  'Amabel',
  'Amabelle',
  'Amalea',
  'Amalee',
  'Amaleta',
  'Amalia',
  'Amalie',
  'Amalita',
  'Amalle',
  'Amanda',
  'Amandi',
  'Amandie',
  'Amandy',
  'Amara',
  'Amargo',
  'Amata',
  'Amber',
  'Amberly',
  'Ambur',
  'Ame',
  'Amelia',
  'Amelie',
  'Amelina',
  'Ameline',
  'Amelita',
  'Ami',
  'Amie',
  'Amii',
  'Amil',
  'Amitie',
  'Amity',
  'Ammamaria',
  'Amy',
  'Amye',
  'Ana',
  'Anabal',
  'Anabel',
  'Anabella',
  'Anabelle',
  'Analiese',
  'Analise',
  'Anallese',
  'Anallise',
  'Anastasia',
  'Anastasie',
  'Anastassia',
  'Anatola',
  'Andee',
  'Andeee',
  'Anderea',
  'Andi',
  'Andie',
  'Andra',
  'Andrea',
  'Andreana',
  'Andree',
  'Andrei',
  'Andria',
  'Andriana',
  'Andriette',
  'Andromache',
  'Andy',
  'Anestassia',
  'Anet',
  'Anett',
  'Anetta',
  'Anette',
  'Ange',
  'Angel',
  'Angela',
  'Angele',
  'Angelia',
  'Angelica',
  'Angelika',
  'Angelina',
  'Angeline',
  'Angelique',
  'Angelita',
  'Angelle',
  'Angie',
  'Angil',
  'Angy',
  'Ania',
  'Anica',
  'Anissa',
  'Anita',
  'Anitra',
  'Anjanette',
  'Anjela',
  'Ann',
  'Ann-marie',
  'Anna',
  'Anna-diana',
  'Anna-diane',
  'Anna-maria',
  'Annabal',
  'Annabel',
  'Annabela',
  'Annabell',
  'Annabella',
  'Annabelle',
  'Annadiana',
  'Annadiane',
  'Annalee',
  'Annaliese',
  'Annalise',
  'Annamaria',
  'Annamarie',
  'Anne',
  'Anne-corinne',
  'Anne-marie',
  'Annecorinne',
  'Anneliese',
  'Annelise',
  'Annemarie',
  'Annetta',
  'Annette',
  'Anni',
  'Annice',
  'Annie',
  'Annis',
  'Annissa',
  'Annmaria',
  'Annmarie',
  'Annnora',
  'Annora',
  'Anny',
  'Anselma',
  'Ansley',
  'Anstice',
  'Anthe',
  'Anthea',
  'Anthia',
  'Anthiathia',
  'Antoinette',
  'Antonella',
  'Antonetta',
  'Antonia',
  'Antonie',
  'Antonietta',
  'Antonina',
  'Anya',
  'Appolonia',
  'April',
  'Aprilette',
  'Ara',
  'Arabel',
  'Arabela',
  'Arabele',
  'Arabella',
  'Arabelle',
  'Arda',
  'Ardath',
  'Ardeen',
  'Ardelia',
  'Ardelis',
  'Ardella',
  'Ardelle',
  'Arden',
  'Ardene',
  'Ardenia',
  'Ardine',
  'Ardis',
  'Ardisj',
  'Ardith',
  'Ardra',
  'Ardyce',
  'Ardys',
  'Ardyth',
  'Aretha',
  'Ariadne',
  'Ariana',
  'Aridatha',
  'Ariel',
  'Ariela',
  'Ariella',
  'Arielle',
  'Arlana',
  'Arlee',
  'Arleen',
  'Arlen',
  'Arlena',
  'Arlene',
  'Arleta',
  'Arlette',
  'Arleyne',
  'Arlie',
  'Arliene',
  'Arlina',
  'Arlinda',
  'Arline',
  'Arluene',
  'Arly',
  'Arlyn',
  'Arlyne',
  'Aryn',
  'Ashely',
  'Ashia',
  'Ashien',
  'Ashil',
  'Ashla',
  'Ashlan',
  'Ashlee',
  'Ashleigh',
  'Ashlen',
  'Ashley',
  'Ashli',
  'Ashlie',
  'Ashly',
  'Asia',
  'Astra',
  'Astrid',
  'Astrix',
  'Atalanta',
  'Athena',
  'Athene',
  'Atlanta',
  'Atlante',
  'Auberta',
  'Aubine',
  'Aubree',
  'Aubrette',
  'Aubrey',
  'Aubrie',
  'Aubry',
  'Audi',
  'Audie',
  'Audra',
  'Audre',
  'Audrey',
  'Audrie',
  'Audry',
  'Audrye',
  'Audy',
  'Augusta',
  'Auguste',
  'Augustina',
  'Augustine',
  'Aundrea',
  'Aura',
  'Aurea',
  'Aurel',
  'Aurelea',
  'Aurelia',
  'Aurelie',
  'Auria',
  'Aurie',
  'Aurilia',
  'Aurlie',
  'Auroora',
  'Aurora',
  'Aurore',
  'Austin',
  'Austina',
  'Austine',
  'Ava',
  'Aveline',
  'Averil',
  'Averyl',
  'Avie',
  'Avis',
  'Aviva',
  'Avivah',
  'Avril',
  'Avrit',
  'Ayn',
  'Bab',
  'Babara',
  'Babb',
  'Babbette',
  'Babbie',
  'Babette',
  'Babita',
  'Babs',
  'Bambi',
  'Bambie',
  'Bamby',
  'Barb',
  'Barbabra',
  'Barbara',
  'Barbara-anne',
  'Barbaraanne',
  'Barbe',
  'Barbee',
  'Barbette',
  'Barbey',
  'Barbi',
  'Barbie',
  'Barbra',
  'Barby',
  'Bari',
  'Barrie',
  'Barry',
  'Basia',
  'Bathsheba',
  'Batsheva',
  'Bea',
  'Beatrice',
  'Beatrisa',
  'Beatrix',
  'Beatriz',
  'Bebe',
  'Becca',
  'Becka',
  'Becki',
  'Beckie',
  'Becky',
  'Bee',
  'Beilul',
  'Beitris',
  'Bekki',
  'Bel',
  'Belia',
  'Belicia',
  'Belinda',
  'Belita',
  'Bell',
  'Bella',
  'Bellanca',
  'Belle',
  'Bellina',
  'Belva',
  'Belvia',
  'Bendite',
  'Benedetta',
  'Benedicta',
  'Benedikta',
  'Benetta',
  'Benita',
  'Benni',
  'Bennie',
  'Benny',
  'Benoite',
  'Berenice',
  'Beret',
  'Berget',
  'Berna',
  'Bernadene',
  'Bernadette',
  'Bernadina',
  'Bernadine',
  'Bernardina',
  'Bernardine',
  'Bernelle',
  'Bernete',
  'Bernetta',
  'Bernette',
  'Berni',
  'Bernice',
  'Bernie',
  'Bernita',
  'Berny',
  'Berri',
  'Berrie',
  'Berry',
  'Bert',
  'Berta',
  'Berte',
  'Bertha',
  'Berthe',
  'Berti',
  'Bertie',
  'Bertina',
  'Bertine',
  'Berty',
  'Beryl',
  'Beryle',
  'Bess',
  'Bessie',
  'Bessy',
  'Beth',
  'Bethanne',
  'Bethany',
  'Bethena',
  'Bethina',
  'Betsey',
  'Betsy',
  'Betta',
  'Bette',
  'Bette-ann',
  'Betteann',
  'Betteanne',
  'Betti',
  'Bettina',
  'Bettine',
  'Betty',
  'Bettye',
  'Beulah',
  'Bev',
  'Beverie',
  'Beverlee',
  'Beverley',
  'Beverlie',
  'Beverly',
  'Bevvy',
  'Bianca',
  'Bianka',
  'Bibbie',
  'Bibby',
  'Bibbye',
  'Bibi',
  'Biddie',
  'Biddy',
  'Bidget',
  'Bili',
  'Bill',
  'Billi',
  'Billie',
  'Billy',
  'Billye',
  'Binni',
  'Binnie',
  'Binny',
  'Bird',
  'Birdie',
  'Birgit',
  'Birgitta',
  'Blair',
  'Blaire',
  'Blake',
  'Blakelee',
  'Blakeley',
  'Blanca',
  'Blanch',
  'Blancha',
  'Blanche',
  'Blinni',
  'Blinnie',
  'Blinny',
  'Bliss',
  'Blisse',
  'Blithe',
  'Blondell',
  'Blondelle',
  'Blondie',
  'Blondy',
  'Blythe',
  'Bobbe',
  'Bobbee',
  'Bobbette',
  'Bobbi',
  'Bobbie',
  'Bobby',
  'Bobbye',
  'Bobette',
  'Bobina',
  'Bobine',
  'Bobinette',
  'Bonita',
  'Bonnee',
  'Bonni',
  'Bonnibelle',
  'Bonnie',
  'Bonny',
  'Brana',
  'Brandais',
  'Brande',
  'Brandea',
  'Brandi',
  'Brandice',
  'Brandie',
  'Brandise',
  'Brandy',
  'Breanne',
  'Brear',
  'Bree',
  'Breena',
  'Bren',
  'Brena',
  'Brenda',
  'Brenn',
  'Brenna',
  'Brett',
  'Bria',
  'Briana',
  'Brianna',
  'Brianne',
  'Bride',
  'Bridget',
  'Bridgette',
  'Bridie',
  'Brier',
  'Brietta',
  'Brigid',
  'Brigida',
  'Brigit',
  'Brigitta',
  'Brigitte',
  'Brina',
  'Briney',
  'Brinn',
  'Brinna',
  'Briny',
  'Brit',
  'Brita',
  'Britney',
  'Britni',
  'Britt',
  'Britta',
  'Brittan',
  'Brittaney',
  'Brittani',
  'Brittany',
  'Britte',
  'Britteny',
  'Brittne',
  'Brittney',
  'Brittni',
  'Brook',
  'Brooke',
  'Brooks',
  'Brunhilda',
  'Brunhilde',
  'Bryana',
  'Bryn',
  'Bryna',
  'Brynn',
  'Brynna',
  'Brynne',
  'Buffy',
  'Bunni',
  'Bunnie',
  'Bunny',
  'Cacilia',
  'Cacilie',
  'Cahra',
  'Cairistiona',
  'Caitlin',
  'Caitrin',
  'Cal',
  'Calida',
  'Calla',
  'Calley',
  'Calli',
  'Callida',
  'Callie',
  'Cally',
  'Calypso',
  'Cam',
  'Camala',
  'Camel',
  'Camella',
  'Camellia',
  'Cami',
  'Camila',
  'Camile',
  'Camilla',
  'Camille',
  'Cammi',
  'Cammie',
  'Cammy',
  'Candace',
  'Candi',
  'Candice',
  'Candida',
  'Candide',
  'Candie',
  'Candis',
  'Candra',
  'Candy',
  'Caprice',
  'Cara',
  'Caralie',
  'Caren',
  'Carena',
  'Caresa',
  'Caressa',
  'Caresse',
  'Carey',
  'Cari',
  'Caria',
  'Carie',
  'Caril',
  'Carilyn',
  'Carin',
  'Carina',
  'Carine',
  'Cariotta',
  'Carissa',
  'Carita',
  'Caritta',
  'Carla',
  'Carlee',
  'Carleen',
  'Carlen',
  'Carlene',
  'Carley',
  'Carlie',
  'Carlin',
  'Carlina',
  'Carline',
  'Carlita',
  'Carlota',
  'Carlotta',
  'Carly',
  'Carlye',
  'Carlyn',
  'Carlynn',
  'Carlynne',
  'Carma',
  'Carmel',
  'Carmela',
  'Carmelia',
  'Carmelina',
  'Carmelita',
  'Carmella',
  'Carmelle',
  'Carmen',
  'Carmencita',
  'Carmina',
  'Carmine',
  'Carmita',
  'Carmon',
  'Caro',
  'Carol',
  'Carol-jean',
  'Carola',
  'Carolan',
  'Carolann',
  'Carole',
  'Carolee',
  'Carolin',
  'Carolina',
  'Caroline',
  'Caroljean',
  'Carolyn',
  'Carolyne',
  'Carolynn',
  'Caron',
  'Carree',
  'Carri',
  'Carrie',
  'Carrissa',
  'Carroll',
  'Carry',
  'Cary',
  'Caryl',
  'Caryn',
  'Casandra',
  'Casey',
  'Casi',
  'Casie',
  'Cass',
  'Cassandra',
  'Cassandre',
  'Cassandry',
  'Cassaundra',
  'Cassey',
  'Cassi',
  'Cassie',
  'Cassondra',
  'Cassy',
  'Catarina',
  'Cate',
  'Caterina',
  'Catha',
  'Catharina',
  'Catharine',
  'Cathe',
  'Cathee',
  'Catherin',
  'Catherina',
  'Catherine',
  'Cathi',
  'Cathie',
  'Cathleen',
  'Cathlene',
  'Cathrin',
  'Cathrine',
  'Cathryn',
  'Cathy',
  'Cathyleen',
  'Cati',
  'Catie',
  'Catina',
  'Catlaina',
  'Catlee',
  'Catlin',
  'Catrina',
  'Catriona',
  'Caty',
  'Caye',
  'Cayla',
  'Cecelia',
  'Cecil',
  'Cecile',
  'Ceciley',
  'Cecilia',
  'Cecilla',
  'Cecily',
  'Ceil',
  'Cele',
  'Celene',
  'Celesta',
  'Celeste',
  'Celestia',
  'Celestina',
  'Celestine',
  'Celestyn',
  'Celestyna',
  'Celia',
  'Celie',
  'Celina',
  'Celinda',
  'Celine',
  'Celinka',
  'Celisse',
  'Celka',
  'Celle',
  'Cesya',
  'Chad',
  'Chanda',
  'Chandal',
  'Chandra',
  'Channa',
  'Chantal',
  'Chantalle',
  'Charil',
  'Charin',
  'Charis',
  'Charissa',
  'Charisse',
  'Charita',
  'Charity',
  'Charla',
  'Charlean',
  'Charleen',
  'Charlena',
  'Charlene',
  'Charline',
  'Charlot',
  'Charlotta',
  'Charlotte',
  'Charmain',
  'Charmaine',
  'Charmane',
  'Charmian',
  'Charmine',
  'Charmion',
  'Charo',
  'Charyl',
  'Chastity',
  'Chelsae',
  'Chelsea',
  'Chelsey',
  'Chelsie',
  'Chelsy',
  'Cher',
  'Chere',
  'Cherey',
  'Cheri',
  'Cherianne',
  'Cherice',
  'Cherida',
  'Cherie',
  'Cherilyn',
  'Cherilynn',
  'Cherin',
  'Cherise',
  'Cherish',
  'Cherlyn',
  'Cherri',
  'Cherrita',
  'Cherry',
  'Chery',
  'Cherye',
  'Cheryl',
  'Cheslie',
  'Chiarra',
  'Chickie',
  'Chicky',
  'Chiquia',
  'Chiquita',
  'Chlo',
  'Chloe',
  'Chloette',
  'Chloris',
  'Chris',
  'Chrissie',
  'Chrissy',
  'Christa',
  'Christabel',
  'Christabella',
  'Christal',
  'Christalle',
  'Christan',
  'Christean',
  'Christel',
  'Christen',
  'Christi',
  'Christian',
  'Christiana',
  'Christiane',
  'Christie',
  'Christin',
  'Christina',
  'Christine',
  'Christy',
  'Christye',
  'Christyna',
  'Chrysa',
  'Chrysler',
  'Chrystal',
  'Chryste',
  'Chrystel',
  'Cicely',
  'Cicily',
  'Ciel',
  'Cilka',
  'Cinda',
  'Cindee',
  'Cindelyn',
  'Cinderella',
  'Cindi',
  'Cindie',
  'Cindra',
  'Cindy',
  'Cinnamon',
  'Cissiee',
  'Cissy',
  'Clair',
  'Claire',
  'Clara',
  'Clarabelle',
  'Clare',
  'Claresta',
  'Clareta',
  'Claretta',
  'Clarette',
  'Clarey',
  'Clari',
  'Claribel',
  'Clarice',
  'Clarie',
  'Clarinda',
  'Clarine',
  'Clarissa',
  'Clarisse',
  'Clarita',
  'Clary',
  'Claude',
  'Claudelle',
  'Claudetta',
  'Claudette',
  'Claudia',
  'Claudie',
  'Claudina',
  'Claudine',
  'Clea',
  'Clem',
  'Clemence',
  'Clementia',
  'Clementina',
  'Clementine',
  'Clemmie',
  'Clemmy',
  'Cleo',
  'Cleopatra',
  'Clerissa',
  'Clio',
  'Clo',
  'Cloe',
  'Cloris',
  'Clotilda',
  'Clovis',
  'Codee',
  'Codi',
  'Codie',
  'Cody',
  'Coleen',
  'Colene',
  'Coletta',
  'Colette',
  'Colleen',
  'Collen',
  'Collete',
  'Collette',
  'Collie',
  'Colline',
  'Colly',
  'Con',
  'Concettina',
  'Conchita',
  'Concordia',
  'Conni',
  'Connie',
  'Conny',
  'Consolata',
  'Constance',
  'Constancia',
  'Constancy',
  'Constanta',
  'Constantia',
  'Constantina',
  'Constantine',
  'Consuela',
  'Consuelo',
  'Cookie',
  'Cora',
  'Corabel',
  'Corabella',
  'Corabelle',
  'Coral',
  'Coralie',
  'Coraline',
  'Coralyn',
  'Cordelia',
  'Cordelie',
  'Cordey',
  'Cordi',
  'Cordie',
  'Cordula',
  'Cordy',
  'Coreen',
  'Corella',
  'Corenda',
  'Corene',
  'Coretta',
  'Corette',
  'Corey',
  'Cori',
  'Corie',
  'Corilla',
  'Corina',
  'Corine',
  'Corinna',
  'Corinne',
  'Coriss',
  'Corissa',
  'Corliss',
  'Corly',
  'Cornela',
  'Cornelia',
  'Cornelle',
  'Cornie',
  'Corny',
  'Correna',
  'Correy',
  'Corri',
  'Corrianne',
  'Corrie',
  'Corrina',
  'Corrine',
  'Corrinne',
  'Corry',
  'Cortney',
  'Cory',
  'Cosetta',
  'Cosette',
  'Costanza',
  'Courtenay',
  'Courtnay',
  'Courtney',
  'Crin',
  'Cris',
  'Crissie',
  'Crissy',
  'Crista',
  'Cristabel',
  'Cristal',
  'Cristen',
  'Cristi',
  'Cristie',
  'Cristin',
  'Cristina',
  'Cristine',
  'Cristionna',
  'Cristy',
  'Crysta',
  'Crystal',
  'Crystie',
  'Cthrine',
  'Cyb',
  'Cybil',
  'Cybill',
  'Cymbre',
  'Cynde',
  'Cyndi',
  'Cyndia',
  'Cyndie',
  'Cyndy',
  'Cynthea',
  'Cynthia',
  'Cynthie',
  'Cynthy',
  'Dacey',
  'Dacia',
  'Dacie',
  'Dacy',
  'Dael',
  'Daffi',
  'Daffie',
  'Daffy',
  'Dagmar',
  'Dahlia',
  'Daile',
  'Daisey',
  'Daisi',
  'Daisie',
  'Daisy',
  'Dale',
  'Dalenna',
  'Dalia',
  'Dalila',
  'Dallas',
  'Daloris',
  'Damara',
  'Damaris',
  'Damita',
  'Dana',
  'Danell',
  'Danella',
  'Danette',
  'Dani',
  'Dania',
  'Danica',
  'Danice',
  'Daniela',
  'Daniele',
  'Daniella',
  'Danielle',
  'Danika',
  'Danila',
  'Danit',
  'Danita',
  'Danna',
  'Danni',
  'Dannie',
  'Danny',
  'Dannye',
  'Danya',
  'Danyelle',
  'Danyette',
  'Daphene',
  'Daphna',
  'Daphne',
  'Dara',
  'Darb',
  'Darbie',
  'Darby',
  'Darcee',
  'Darcey',
  'Darci',
  'Darcie',
  'Darcy',
  'Darda',
  'Dareen',
  'Darell',
  'Darelle',
  'Dari',
  'Daria',
  'Darice',
  'Darla',
  'Darleen',
  'Darlene',
  'Darline',
  'Darlleen',
  'Daron',
  'Darrelle',
  'Darryl',
  'Darsey',
  'Darsie',
  'Darya',
  'Daryl',
  'Daryn',
  'Dasha',
  'Dasi',
  'Dasie',
  'Dasya',
  'Datha',
  'Daune',
  'Daveen',
  'Daveta',
  'Davida',
  'Davina',
  'Davine',
  'Davita',
  'Dawn',
  'Dawna',
  'Dayle',
  'Dayna',
  'Ddene',
  'De',
  'Deana',
  'Deane',
  'Deanna',
  'Deanne',
  'Deb',
  'Debbi',
  'Debbie',
  'Debby',
  'Debee',
  'Debera',
  'Debi',
  'Debor',
  'Debora',
  'Deborah',
  'Debra',
  'Dede',
  'Dedie',
  'Dedra',
  'Dee',
  'Deeann',
  'Deeanne',
  'Deedee',
  'Deena',
  'Deerdre',
  'Deeyn',
  'Dehlia',
  'Deidre',
  'Deina',
  'Deirdre',
  'Del',
  'Dela',
  'Delcina',
  'Delcine',
  'Delia',
  'Delila',
  'Delilah',
  'Delinda',
  'Dell',
  'Della',
  'Delly',
  'Delora',
  'Delores',
  'Deloria',
  'Deloris',
  'Delphine',
  'Delphinia',
  'Demeter',
  'Demetra',
  'Demetria',
  'Demetris',
  'Dena',
  'Deni',
  'Denice',
  'Denise',
  'Denna',
  'Denni',
  'Dennie',
  'Denny',
  'Deny',
  'Denys',
  'Denyse',
  'Deonne',
  'Desdemona',
  'Desirae',
  'Desiree',
  'Desiri',
  'Deva',
  'Devan',
  'Devi',
  'Devin',
  'Devina',
  'Devinne',
  'Devon',
  'Devondra',
  'Devonna',
  'Devonne',
  'Devora',
  'Di',
  'Diahann',
  'Dian',
  'Diana',
  'Diandra',
  'Diane',
  'Diane-marie',
  'Dianemarie',
  'Diann',
  'Dianna',
  'Dianne',
  'Diannne',
  'Didi',
  'Dido',
  'Diena',
  'Dierdre',
  'Dina',
  'Dinah',
  'Dinnie',
  'Dinny',
  'Dion',
  'Dione',
  'Dionis',
  'Dionne',
  'Dita',
  'Dix',
  'Dixie',
  'Dniren',
  'Dode',
  'Dodi',
  'Dodie',
  'Dody',
  'Doe',
  'Doll',
  'Dolley',
  'Dolli',
  'Dollie',
  'Dolly',
  'Dolores',
  'Dolorita',
  'Doloritas',
  'Domeniga',
  'Dominga',
  'Domini',
  'Dominica',
  'Dominique',
  'Dona',
  'Donella',
  'Donelle',
  'Donetta',
  'Donia',
  'Donica',
  'Donielle',
  'Donna',
  'Donnamarie',
  'Donni',
  'Donnie',
  'Donny',
  'Dora',
  'Doralia',
  'Doralin',
  'Doralyn',
  'Doralynn',
  'Doralynne',
  'Dore',
  'Doreen',
  'Dorelia',
  'Dorella',
  'Dorelle',
  'Dorena',
  'Dorene',
  'Doretta',
  'Dorette',
  'Dorey',
  'Dori',
  'Doria',
  'Dorian',
  'Dorice',
  'Dorie',
  'Dorine',
  'Doris',
  'Dorisa',
  'Dorise',
  'Dorita',
  'Doro',
  'Dorolice',
  'Dorolisa',
  'Dorotea',
  'Doroteya',
  'Dorothea',
  'Dorothee',
  'Dorothy',
  'Dorree',
  'Dorri',
  'Dorrie',
  'Dorris',
  'Dorry',
  'Dorthea',
  'Dorthy',
  'Dory',
  'Dosi',
  'Dot',
  'Doti',
  'Dotti',
  'Dottie',
  'Dotty',
  'Dre',
  'Dreddy',
  'Dredi',
  'Drona',
  'Dru',
  'Druci',
  'Drucie',
  'Drucill',
  'Drucy',
  'Drusi',
  'Drusie',
  'Drusilla',
  'Drusy',
  'Dulce',
  'Dulcea',
  'Dulci',
  'Dulcia',
  'Dulciana',
  'Dulcie',
  'Dulcine',
  'Dulcinea',
  'Dulcy',
  'Dulsea',
  'Dusty',
  'Dyan',
  'Dyana',
  'Dyane',
  'Dyann',
  'Dyanna',
  'Dyanne',
  'Dyna',
  'Dynah',
  'Eachelle',
  'Eada',
  'Eadie',
  'Eadith',
  'Ealasaid',
  'Eartha',
  'Easter',
  'Eba',
  'Ebba',
  'Ebonee',
  'Ebony',
  'Eda',
  'Eddi',
  'Eddie',
  'Eddy',
  'Ede',
  'Edee',
  'Edeline',
  'Eden',
  'Edi',
  'Edie',
  'Edin',
  'Edita',
  'Edith',
  'Editha',
  'Edithe',
  'Ediva',
  'Edna',
  'Edwina',
  'Edy',
  'Edyth',
  'Edythe',
  'Effie',
  'Eileen',
  'Eilis',
  'Eimile',
  'Eirena',
  'Ekaterina',
  'Elaina',
  'Elaine',
  'Elana',
  'Elane',
  'Elayne',
  'Elberta',
  'Elbertina',
  'Elbertine',
  'Eleanor',
  'Eleanora',
  'Eleanore',
  'Electra',
  'Eleen',
  'Elena',
  'Elene',
  'Eleni',
  'Elenore',
  'Eleonora',
  'Eleonore',
  'Elfie',
  'Elfreda',
  'Elfrida',
  'Elfrieda',
  'Elga',
  'Elianora',
  'Elianore',
  'Elicia',
  'Elie',
  'Elinor',
  'Elinore',
  'Elisa',
  'Elisabet',
  'Elisabeth',
  'Elisabetta',
  'Elise',
  'Elisha',
  'Elissa',
  'Elita',
  'Eliza',
  'Elizabet',
  'Elizabeth',
  'Elka',
  'Elke',
  'Ella',
  'Elladine',
  'Elle',
  'Ellen',
  'Ellene',
  'Ellette',
  'Elli',
  'Ellie',
  'Ellissa',
  'Elly',
  'Ellyn',
  'Ellynn',
  'Elmira',
  'Elna',
  'Elnora',
  'Elnore',
  'Eloisa',
  'Eloise',
  'Elonore',
  'Elora',
  'Elsa',
  'Elsbeth',
  'Else',
  'Elset',
  'Elsey',
  'Elsi',
  'Elsie',
  'Elsinore',
  'Elspeth',
  'Elsy',
  'Elva',
  'Elvera',
  'Elvina',
  'Elvira',
  'Elwira',
  'Elyn',
  'Elyse',
  'Elysee',
  'Elysha',
  'Elysia',
  'Elyssa',
  'Em',
  'Ema',
  'Emalee',
  'Emalia',
  'Emelda',
  'Emelia',
  'Emelina',
  'Emeline',
  'Emelita',
  'Emelyne',
  'Emera',
  'Emilee',
  'Emili',
  'Emilia',
  'Emilie',
  'Emiline',
  'Emily',
  'Emlyn',
  'Emlynn',
  'Emlynne',
  'Emma',
  'Emmalee',
  'Emmaline',
  'Emmalyn',
  'Emmalynn',
  'Emmalynne',
  'Emmeline',
  'Emmey',
  'Emmi',
  'Emmie',
  'Emmy',
  'Emmye',
  'Emogene',
  'Emyle',
  'Emylee',
  'Engracia',
  'Enid',
  'Enrica',
  'Enrichetta',
  'Enrika',
  'Enriqueta',
  'Eolanda',
  'Eolande',
  'Eran',
  'Erda',
  'Erena',
  'Erica',
  'Ericha',
  'Ericka',
  'Erika',
  'Erin',
  'Erina',
  'Erinn',
  'Erinna',
  'Erma',
  'Ermengarde',
  'Ermentrude',
  'Ermina',
  'Erminia',
  'Erminie',
  'Erna',
  'Ernaline',
  'Ernesta',
  'Ernestine',
  'Ertha',
  'Eryn',
  'Esma',
  'Esmaria',
  'Esme',
  'Esmeralda',
  'Essa',
  'Essie',
  'Essy',
  'Esta',
  'Estel',
  'Estele',
  'Estell',
  'Estella',
  'Estelle',
  'Ester',
  'Esther',
  'Estrella',
  'Estrellita',
  'Ethel',
  'Ethelda',
  'Ethelin',
  'Ethelind',
  'Etheline',
  'Ethelyn',
  'Ethyl',
  'Etta',
  'Etti',
  'Ettie',
  'Etty',
  'Eudora',
  'Eugenia',
  'Eugenie',
  'Eugine',
  'Eula',
  'Eulalie',
  'Eunice',
  'Euphemia',
  'Eustacia',
  'Eva',
  'Evaleen',
  'Evangelia',
  'Evangelin',
  'Evangelina',
  'Evangeline',
  'Evania',
  'Evanne',
  'Eve',
  'Eveleen',
  'Evelina',
  'Eveline',
  'Evelyn',
  'Evey',
  'Evie',
  'Evita',
  'Evonne',
  'Evvie',
  'Evvy',
  'Evy',
  'Eyde',
  'Eydie',
  'Ezmeralda',
  'Fae',
  'Faina',
  'Faith',
  'Fallon',
  'Fan',
  'Fanchette',
  'Fanchon',
  'Fancie',
  'Fancy',
  'Fanechka',
  'Fania',
  'Fanni',
  'Fannie',
  'Fanny',
  'Fanya',
  'Fara',
  'Farah',
  'Farand',
  'Farica',
  'Farra',
  'Farrah',
  'Farrand',
  'Faun',
  'Faunie',
  'Faustina',
  'Faustine',
  'Fawn',
  'Fawne',
  'Fawnia',
  'Fay',
  'Faydra',
  'Faye',
  'Fayette',
  'Fayina',
  'Fayre',
  'Fayth',
  'Faythe',
  'Federica',
  'Fedora',
  'Felecia',
  'Felicdad',
  'Felice',
  'Felicia',
  'Felicity',
  'Felicle',
  'Felipa',
  'Felisha',
  'Felita',
  'Feliza',
  'Fenelia',
  'Feodora',
  'Ferdinanda',
  'Ferdinande',
  'Fern',
  'Fernanda',
  'Fernande',
  'Fernandina',
  'Ferne',
  'Fey',
  'Fiann',
  'Fianna',
  'Fidela',
  'Fidelia',
  'Fidelity',
  'Fifi',
  'Fifine',
  'Filia',
  'Filide',
  'Filippa',
  'Fina',
  'Fiona',
  'Fionna',
  'Fionnula',
  'Fiorenze',
  'Fleur',
  'Fleurette',
  'Flo',
  'Flor',
  'Flora',
  'Florance',
  'Flore',
  'Florella',
  'Florence',
  'Florencia',
  'Florentia',
  'Florenza',
  'Florette',
  'Flori',
  'Floria',
  'Florida',
  'Florie',
  'Florina',
  'Florinda',
  'Floris',
  'Florri',
  'Florrie',
  'Florry',
  'Flory',
  'Flossi',
  'Flossie',
  'Flossy',
  'Flss',
  'Fran',
  'Francene',
  'Frances',
  'Francesca',
  'Francine',
  'Francisca',
  'Franciska',
  'Francoise',
  'Francyne',
  'Frank',
  'Frankie',
  'Franky',
  'Franni',
  'Frannie',
  'Franny',
  'Frayda',
  'Fred',
  'Freda',
  'Freddi',
  'Freddie',
  'Freddy',
  'Fredelia',
  'Frederica',
  'Fredericka',
  'Frederique',
  'Fredi',
  'Fredia',
  'Fredra',
  'Fredrika',
  'Freida',
  'Frieda',
  'Friederike',
  'Fulvia',
  'Gabbey',
  'Gabbi',
  'Gabbie',
  'Gabey',
  'Gabi',
  'Gabie',
  'Gabriel',
  'Gabriela',
  'Gabriell',
  'Gabriella',
  'Gabrielle',
  'Gabriellia',
  'Gabrila',
  'Gaby',
  'Gae',
  'Gael',
  'Gail',
  'Gale',
  'Galina',
  'Garland',
  'Garnet',
  'Garnette',
  'Gates',
  'Gavra',
  'Gavrielle',
  'Gay',
  'Gaye',
  'Gayel',
  'Gayla',
  'Gayle',
  'Gayleen',
  'Gaylene',
  'Gaynor',
  'Gelya',
  'Gena',
  'Gene',
  'Geneva',
  'Genevieve',
  'Genevra',
  'Genia',
  'Genna',
  'Genni',
  'Gennie',
  'Gennifer',
  'Genny',
  'Genovera',
  'Genvieve',
  'George',
  'Georgeanna',
  'Georgeanne',
  'Georgena',
  'Georgeta',
  'Georgetta',
  'Georgette',
  'Georgia',
  'Georgiana',
  'Georgianna',
  'Georgianne',
  'Georgie',
  'Georgina',
  'Georgine',
  'Geralda',
  'Geraldine',
  'Gerda',
  'Gerhardine',
  'Geri',
  'Gerianna',
  'Gerianne',
  'Gerladina',
  'Germain',
  'Germaine',
  'Germana',
  'Gerri',
  'Gerrie',
  'Gerrilee',
  'Gerry',
  'Gert',
  'Gerta',
  'Gerti',
  'Gertie',
  'Gertrud',
  'Gertruda',
  'Gertrude',
  'Gertrudis',
  'Gerty',
  'Giacinta',
  'Giana',
  'Gianina',
  'Gianna',
  'Gigi',
  'Gilberta',
  'Gilberte',
  'Gilbertina',
  'Gilbertine',
  'Gilda',
  'Gilemette',
  'Gill',
  'Gillan',
  'Gilli',
  'Gillian',
  'Gillie',
  'Gilligan',
  'Gilly',
  'Gina',
  'Ginelle',
  'Ginevra',
  'Ginger',
  'Ginni',
  'Ginnie',
  'Ginnifer',
  'Ginny',
  'Giorgia',
  'Giovanna',
  'Gipsy',
  'Giralda',
  'Gisela',
  'Gisele',
  'Gisella',
  'Giselle',
  'Giuditta',
  'Giulia',
  'Giulietta',
  'Giustina',
  'Gizela',
  'Glad',
  'Gladi',
  'Gladys',
  'Gleda',
  'Glen',
  'Glenda',
  'Glenine',
  'Glenn',
  'Glenna',
  'Glennie',
  'Glennis',
  'Glori',
  'Gloria',
  'Gloriana',
  'Gloriane',
  'Glory',
  'Glyn',
  'Glynda',
  'Glynis',
  'Glynnis',
  'Gnni',
  'Godiva',
  'Golda',
  'Goldarina',
  'Goldi',
  'Goldia',
  'Goldie',
  'Goldina',
  'Goldy',
  'Grace',
  'Gracia',
  'Gracie',
  'Grata',
  'Gratia',
  'Gratiana',
  'Gray',
  'Grayce',
  'Grazia',
  'Greer',
  'Greta',
  'Gretal',
  'Gretchen',
  'Grete',
  'Gretel',
  'Grethel',
  'Gretna',
  'Gretta',
  'Grier',
  'Griselda',
  'Grissel',
  'Guendolen',
  'Guenevere',
  'Guenna',
  'Guglielma',
  'Gui',
  'Guillema',
  'Guillemette',
  'Guinevere',
  'Guinna',
  'Gunilla',
  'Gus',
  'Gusella',
  'Gussi',
  'Gussie',
  'Gussy',
  'Gusta',
  'Gusti',
  'Gustie',
  'Gusty',
  'Gwen',
  'Gwendolen',
  'Gwendolin',
  'Gwendolyn',
  'Gweneth',
  'Gwenette',
  'Gwenneth',
  'Gwenni',
  'Gwennie',
  'Gwenny',
  'Gwenora',
  'Gwenore',
  'Gwyn',
  'Gwyneth',
  'Gwynne',
  'Gypsy',
  'Hadria',
  'Hailee',
  'Haily',
  'Haleigh',
  'Halette',
  'Haley',
  'Hali',
  'Halie',
  'Halimeda',
  'Halley',
  'Halli',
  'Hallie',
  'Hally',
  'Hana',
  'Hanna',
  'Hannah',
  'Hanni',
  'Hannie',
  'Hannis',
  'Hanny',
  'Happy',
  'Harlene',
  'Harley',
  'Harli',
  'Harlie',
  'Harmonia',
  'Harmonie',
  'Harmony',
  'Harri',
  'Harrie',
  'Harriet',
  'Harriett',
  'Harrietta',
  'Harriette',
  'Harriot',
  'Harriott',
  'Hatti',
  'Hattie',
  'Hatty',
  'Hayley',
  'Hazel',
  'Heath',
  'Heather',
  'Heda',
  'Hedda',
  'Heddi',
  'Heddie',
  'Hedi',
  'Hedvig',
  'Hedvige',
  'Hedwig',
  'Hedwiga',
  'Hedy',
  'Heida',
  'Heidi',
  'Heidie',
  'Helaina',
  'Helaine',
  'Helen',
  'Helen-elizabeth',
  'Helena',
  'Helene',
  'Helenka',
  'Helga',
  'Helge',
  'Helli',
  'Heloise',
  'Helsa',
  'Helyn',
  'Hendrika',
  'Henka',
  'Henrie',
  'Henrieta',
  'Henrietta',
  'Henriette',
  'Henryetta',
  'Hephzibah',
  'Hermia',
  'Hermina',
  'Hermine',
  'Herminia',
  'Hermione',
  'Herta',
  'Hertha',
  'Hester',
  'Hesther',
  'Hestia',
  'Hetti',
  'Hettie',
  'Hetty',
  'Hilary',
  'Hilda',
  'Hildagard',
  'Hildagarde',
  'Hilde',
  'Hildegaard',
  'Hildegarde',
  'Hildy',
  'Hillary',
  'Hilliary',
  'Hinda',
  'Holli',
  'Hollie',
  'Holly',
  'Holly-anne',
  'Hollyanne',
  'Honey',
  'Honor',
  'Honoria',
  'Hope',
  'Horatia',
  'Hortense',
  'Hortensia',
  'Hulda',
  'Hyacinth',
  'Hyacintha',
  'Hyacinthe',
  'Hyacinthia',
  'Hyacinthie',
  'Hynda',
  'Ianthe',
  'Ibbie',
  'Ibby',
  'Ida',
  'Idalia',
  'Idalina',
  'Idaline',
  'Idell',
  'Idelle',
  'Idette',
  'Ileana',
  'Ileane',
  'Ilene',
  'Ilise',
  'Ilka',
  'Illa',
  'Ilsa',
  'Ilse',
  'Ilysa',
  'Ilyse',
  'Ilyssa',
  'Imelda',
  'Imogen',
  'Imogene',
  'Imojean',
  'Ina',
  'Indira',
  'Ines',
  'Inesita',
  'Inessa',
  'Inez',
  'Inga',
  'Ingaberg',
  'Ingaborg',
  'Inge',
  'Ingeberg',
  'Ingeborg',
  'Inger',
  'Ingrid',
  'Ingunna',
  'Inna',
  'Iolande',
  'Iolanthe',
  'Iona',
  'Iormina',
  'Ira',
  'Irena',
  'Irene',
  'Irina',
  'Iris',
  'Irita',
  'Irma',
  'Isa',
  'Isabel',
  'Isabelita',
  'Isabella',
  'Isabelle',
  'Isadora',
  'Isahella',
  'Iseabal',
  'Isidora',
  'Isis',
  'Isobel',
  'Issi',
  'Issie',
  'Issy',
  'Ivett',
  'Ivette',
  'Ivie',
  'Ivonne',
  'Ivory',
  'Ivy',
  'Izabel',
  'Jacenta',
  'Jacinda',
  'Jacinta',
  'Jacintha',
  'Jacinthe',
  'Jackelyn',
  'Jacki',
  'Jackie',
  'Jacklin',
  'Jacklyn',
  'Jackquelin',
  'Jackqueline',
  'Jacky',
  'Jaclin',
  'Jaclyn',
  'Jacquelin',
  'Jacqueline',
  'Jacquelyn',
  'Jacquelynn',
  'Jacquenetta',
  'Jacquenette',
  'Jacquetta',
  'Jacquette',
  'Jacqui',
  'Jacquie',
  'Jacynth',
  'Jada',
  'Jade',
  'Jaime',
  'Jaimie',
  'Jaine',
  'Jami',
  'Jamie',
  'Jamima',
  'Jammie',
  'Jan',
  'Jana',
  'Janaya',
  'Janaye',
  'Jandy',
  'Jane',
  'Janean',
  'Janeczka',
  'Janeen',
  'Janel',
  'Janela',
  'Janella',
  'Janelle',
  'Janene',
  'Janenna',
  'Janessa',
  'Janet',
  'Janeta',
  'Janetta',
  'Janette',
  'Janeva',
  'Janey',
  'Jania',
  'Janice',
  'Janie',
  'Janifer',
  'Janina',
  'Janine',
  'Janis',
  'Janith',
  'Janka',
  'Janna',
  'Jannel',
  'Jannelle',
  'Janot',
  'Jany',
  'Jaquelin',
  'Jaquelyn',
  'Jaquenetta',
  'Jaquenette',
  'Jaquith',
  'Jasmin',
  'Jasmina',
  'Jasmine',
  'Jayme',
  'Jaymee',
  'Jayne',
  'Jaynell',
  'Jazmin',
  'Jean',
  'Jeana',
  'Jeane',
  'Jeanelle',
  'Jeanette',
  'Jeanie',
  'Jeanine',
  'Jeanna',
  'Jeanne',
  'Jeannette',
  'Jeannie',
  'Jeannine',
  'Jehanna',
  'Jelene',
  'Jemie',
  'Jemima',
  'Jemimah',
  'Jemmie',
  'Jemmy',
  'Jen',
  'Jena',
  'Jenda',
  'Jenelle',
  'Jeni',
  'Jenica',
  'Jeniece',
  'Jenifer',
  'Jeniffer',
  'Jenilee',
  'Jenine',
  'Jenn',
  'Jenna',
  'Jennee',
  'Jennette',
  'Jenni',
  'Jennica',
  'Jennie',
  'Jennifer',
  'Jennilee',
  'Jennine',
  'Jenny',
  'Jeralee',
  'Jere',
  'Jeri',
  'Jermaine',
  'Jerrie',
  'Jerrilee',
  'Jerrilyn',
  'Jerrine',
  'Jerry',
  'Jerrylee',
  'Jess',
  'Jessa',
  'Jessalin',
  'Jessalyn',
  'Jessamine',
  'Jessamyn',
  'Jesse',
  'Jesselyn',
  'Jessi',
  'Jessica',
  'Jessie',
  'Jessika',
  'Jessy',
  'Jewel',
  'Jewell',
  'Jewelle',
  'Jill',
  'Jillana',
  'Jillane',
  'Jillayne',
  'Jilleen',
  'Jillene',
  'Jilli',
  'Jillian',
  'Jillie',
  'Jilly',
  'Jinny',
  'Jo',
  'Jo-ann',
  'Jo-anne',
  'Joan',
  'Joana',
  'Joane',
  'Joanie',
  'Joann',
  'Joanna',
  'Joanne',
  'Joannes',
  'Jobey',
  'Jobi',
  'Jobie',
  'Jobina',
  'Joby',
  'Jobye',
  'Jobyna',
  'Jocelin',
  'Joceline',
  'Jocelyn',
  'Jocelyne',
  'Jodee',
  'Jodi',
  'Jodie',
  'Jody',
  'Joeann',
  'Joela',
  'Joelie',
  'Joell',
  'Joella',
  'Joelle',
  'Joellen',
  'Joelly',
  'Joellyn',
  'Joelynn',
  'Joete',
  'Joey',
  'Johanna',
  'Johannah',
  'Johna',
  'Johnath',
  'Johnette',
  'Johnna',
  'Joice',
  'Jojo',
  'Jolee',
  'Joleen',
  'Jolene',
  'Joletta',
  'Joli',
  'Jolie',
  'Joline',
  'Joly',
  'Jolyn',
  'Jolynn',
  'Jonell',
  'Joni',
  'Jonie',
  'Jonis',
  'Jordain',
  'Jordan',
  'Jordana',
  'Jordanna',
  'Jorey',
  'Jori',
  'Jorie',
  'Jorrie',
  'Jorry',
  'Joscelin',
  'Josee',
  'Josefa',
  'Josefina',
  'Josepha',
  'Josephina',
  'Josephine',
  'Josey',
  'Josi',
  'Josie',
  'Josselyn',
  'Josy',
  'Jourdan',
  'Joy',
  'Joya',
  'Joyan',
  'Joyann',
  'Joyce',
  'Joycelin',
  'Joye',
  'Jsandye',
  'Juana',
  'Juanita',
  'Judi',
  'Judie',
  'Judith',
  'Juditha',
  'Judy',
  'Judye',
  'Juieta',
  'Julee',
  'Juli',
  'Julia',
  'Juliana',
  'Juliane',
  'Juliann',
  'Julianna',
  'Julianne',
  'Julie',
  'Julienne',
  'Juliet',
  'Julieta',
  'Julietta',
  'Juliette',
  'Julina',
  'Juline',
  'Julissa',
  'Julita',
  'June',
  'Junette',
  'Junia',
  'Junie',
  'Junina',
  'Justina',
  'Justine',
  'Justinn',
  'Jyoti',
  'Kacey',
  'Kacie',
  'Kacy',
  'Kaela',
  'Kai',
  'Kaia',
  'Kaila',
  'Kaile',
  'Kailey',
  'Kaitlin',
  'Kaitlyn',
  'Kaitlynn',
  'Kaja',
  'Kakalina',
  'Kala',
  'Kaleena',
  'Kali',
  'Kalie',
  'Kalila',
  'Kalina',
  'Kalinda',
  'Kalindi',
  'Kalli',
  'Kally',
  'Kameko',
  'Kamila',
  'Kamilah',
  'Kamillah',
  'Kandace',
  'Kandy',
  'Kania',
  'Kanya',
  'Kara',
  'Kara-lynn',
  'Karalee',
  'Karalynn',
  'Kare',
  'Karee',
  'Karel',
  'Karen',
  'Karena',
  'Kari',
  'Karia',
  'Karie',
  'Karil',
  'Karilynn',
  'Karin',
  'Karina',
  'Karine',
  'Kariotta',
  'Karisa',
  'Karissa',
  'Karita',
  'Karla',
  'Karlee',
  'Karleen',
  'Karlen',
  'Karlene',
  'Karlie',
  'Karlotta',
  'Karlotte',
  'Karly',
  'Karlyn',
  'Karmen',
  'Karna',
  'Karol',
  'Karola',
  'Karole',
  'Karolina',
  'Karoline',
  'Karoly',
  'Karon',
  'Karrah',
  'Karrie',
  'Karry',
  'Kary',
  'Karyl',
  'Karylin',
  'Karyn',
  'Kasey',
  'Kass',
  'Kassandra',
  'Kassey',
  'Kassi',
  'Kassia',
  'Kassie',
  'Kat',
  'Kata',
  'Katalin',
  'Kate',
  'Katee',
  'Katerina',
  'Katerine',
  'Katey',
  'Kath',
  'Katha',
  'Katharina',
  'Katharine',
  'Katharyn',
  'Kathe',
  'Katherina',
  'Katherine',
  'Katheryn',
  'Kathi',
  'Kathie',
  'Kathleen',
  'Kathlin',
  'Kathrine',
  'Kathryn',
  'Kathryne',
  'Kathy',
  'Kathye',
  'Kati',
  'Katie',
  'Katina',
  'Katine',
  'Katinka',
  'Katleen',
  'Katlin',
  'Katrina',
  'Katrine',
  'Katrinka',
  'Katti',
  'Kattie',
  'Katuscha',
  'Katusha',
  'Katy',
  'Katya',
  'Kay',
  'Kaycee',
  'Kaye',
  'Kayla',
  'Kayle',
  'Kaylee',
  'Kayley',
  'Kaylil',
  'Kaylyn',
  'Keeley',
  'Keelia',
  'Keely',
  'Kelcey',
  'Kelci',
  'Kelcie',
  'Kelcy',
  'Kelila',
  'Kellen',
  'Kelley',
  'Kelli',
  'Kellia',
  'Kellie',
  'Kellina',
  'Kellsie',
  'Kelly',
  'Kellyann',
  'Kelsey',
  'Kelsi',
  'Kelsy',
  'Kendra',
  'Kendre',
  'Kenna',
  'Keri',
  'Keriann',
  'Kerianne',
  'Kerri',
  'Kerrie',
  'Kerrill',
  'Kerrin',
  'Kerry',
  'Kerstin',
  'Kesley',
  'Keslie',
  'Kessia',
  'Kessiah',
  'Ketti',
  'Kettie',
  'Ketty',
  'Kevina',
  'Kevyn',
  'Ki',
  'Kiah',
  'Kial',
  'Kiele',
  'Kiersten',
  'Kikelia',
  'Kiley',
  'Kim',
  'Kimberlee',
  'Kimberley',
  'Kimberli',
  'Kimberly',
  'Kimberlyn',
  'Kimbra',
  'Kimmi',
  'Kimmie',
  'Kimmy',
  'Kinna',
  'Kip',
  'Kipp',
  'Kippie',
  'Kippy',
  'Kira',
  'Kirbee',
  'Kirbie',
  'Kirby',
  'Kiri',
  'Kirsten',
  'Kirsteni',
  'Kirsti',
  'Kirstin',
  'Kirstyn',
  'Kissee',
  'Kissiah',
  'Kissie',
  'Kit',
  'Kitti',
  'Kittie',
  'Kitty',
  'Kizzee',
  'Kizzie',
  'Klara',
  'Klarika',
  'Klarrisa',
  'Konstance',
  'Konstanze',
  'Koo',
  'Kora',
  'Koral',
  'Koralle',
  'Kordula',
  'Kore',
  'Korella',
  'Koren',
  'Koressa',
  'Kori',
  'Korie',
  'Korney',
  'Korrie',
  'Korry',
  'Kris',
  'Krissie',
  'Krissy',
  'Krista',
  'Kristal',
  'Kristan',
  'Kriste',
  'Kristel',
  'Kristen',
  'Kristi',
  'Kristien',
  'Kristin',
  'Kristina',
  'Kristine',
  'Kristy',
  'Kristyn',
  'Krysta',
  'Krystal',
  'Krystalle',
  'Krystle',
  'Krystyna',
  'Kyla',
  'Kyle',
  'Kylen',
  'Kylie',
  'Kylila',
  'Kylynn',
  'Kym',
  'Kynthia',
  'Kyrstin',
  'Lacee',
  'Lacey',
  'Lacie',
  'Lacy',
  'Ladonna',
  'Laetitia',
  'Laina',
  'Lainey',
  'Lana',
  'Lanae',
  'Lane',
  'Lanette',
  'Laney',
  'Lani',
  'Lanie',
  'Lanita',
  'Lanna',
  'Lanni',
  'Lanny',
  'Lara',
  'Laraine',
  'Lari',
  'Larina',
  'Larine',
  'Larisa',
  'Larissa',
  'Lark',
  'Laryssa',
  'Latashia',
  'Latia',
  'Latisha',
  'Latrena',
  'Latrina',
  'Laura',
  'Lauraine',
  'Laural',
  'Lauralee',
  'Laure',
  'Lauree',
  'Laureen',
  'Laurel',
  'Laurella',
  'Lauren',
  'Laurena',
  'Laurene',
  'Lauretta',
  'Laurette',
  'Lauri',
  'Laurianne',
  'Laurice',
  'Laurie',
  'Lauryn',
  'Lavena',
  'Laverna',
  'Laverne',
  'Lavina',
  'Lavinia',
  'Lavinie',
  'Layla',
  'Layne',
  'Layney',
  'Lea',
  'Leah',
  'Leandra',
  'Leann',
  'Leanna',
  'Leanor',
  'Leanora',
  'Lebbie',
  'Leda',
  'Lee',
  'Leeann',
  'Leeanne',
  'Leela',
  'Leelah',
  'Leena',
  'Leesa',
  'Leese',
  'Legra',
  'Leia',
  'Leigh',
  'Leigha',
  'Leila',
  'Leilah',
  'Leisha',
  'Lela',
  'Lelah',
  'Leland',
  'Lelia',
  'Lena',
  'Lenee',
  'Lenette',
  'Lenka',
  'Lenna',
  'Lenora',
  'Lenore',
  'Leodora',
  'Leoine',
  'Leola',
  'Leoline',
  'Leona',
  'Leonanie',
  'Leone',
  'Leonelle',
  'Leonie',
  'Leonora',
  'Leonore',
  'Leontine',
  'Leontyne',
  'Leora',
  'Leshia',
  'Lesley',
  'Lesli',
  'Leslie',
  'Lesly',
  'Lesya',
  'Leta',
  'Lethia',
  'Leticia',
  'Letisha',
  'Letitia',
  'Letizia',
  'Letta',
  'Letti',
  'Lettie',
  'Letty',
  'Lexi',
  'Lexie',
  'Lexine',
  'Lexis',
  'Lexy',
  'Leyla',
  'Lezlie',
  'Lia',
  'Lian',
  'Liana',
  'Liane',
  'Lianna',
  'Lianne',
  'Lib',
  'Libbey',
  'Libbi',
  'Libbie',
  'Libby',
  'Licha',
  'Lida',
  'Lidia',
  'Liesa',
  'Lil',
  'Lila',
  'Lilah',
  'Lilas',
  'Lilia',
  'Lilian',
  'Liliane',
  'Lilias',
  'Lilith',
  'Lilla',
  'Lilli',
  'Lillian',
  'Lillis',
  'Lilllie',
  'Lilly',
  'Lily',
  'Lilyan',
  'Lin',
  'Lina',
  'Lind',
  'Linda',
  'Lindi',
  'Lindie',
  'Lindsay',
  'Lindsey',
  'Lindsy',
  'Lindy',
  'Linea',
  'Linell',
  'Linet',
  'Linette',
  'Linn',
  'Linnea',
  'Linnell',
  'Linnet',
  'Linnie',
  'Linzy',
  'Lira',
  'Lisa',
  'Lisabeth',
  'Lisbeth',
  'Lise',
  'Lisetta',
  'Lisette',
  'Lisha',
  'Lishe',
  'Lissa',
  'Lissi',
  'Lissie',
  'Lissy',
  'Lita',
  'Liuka',
  'Liv',
  'Liva',
  'Livia',
  'Livvie',
  'Livvy',
  'Livvyy',
  'Livy',
  'Liz',
  'Liza',
  'Lizabeth',
  'Lizbeth',
  'Lizette',
  'Lizzie',
  'Lizzy',
  'Loella',
  'Lois',
  'Loise',
  'Lola',
  'Loleta',
  'Lolita',
  'Lolly',
  'Lona',
  'Lonee',
  'Loni',
  'Lonna',
  'Lonni',
  'Lonnie',
  'Lora',
  'Lorain',
  'Loraine',
  'Loralee',
  'Loralie',
  'Loralyn',
  'Loree',
  'Loreen',
  'Lorelei',
  'Lorelle',
  'Loren',
  'Lorena',
  'Lorene',
  'Lorenza',
  'Loretta',
  'Lorette',
  'Lori',
  'Loria',
  'Lorianna',
  'Lorianne',
  'Lorie',
  'Lorilee',
  'Lorilyn',
  'Lorinda',
  'Lorine',
  'Lorita',
  'Lorna',
  'Lorne',
  'Lorraine',
  'Lorrayne',
  'Lorri',
  'Lorrie',
  'Lorrin',
  'Lorry',
  'Lory',
  'Lotta',
  'Lotte',
  'Lotti',
  'Lottie',
  'Lotty',
  'Lou',
  'Louella',
  'Louisa',
  'Louise',
  'Louisette',
  'Loutitia',
  'Lu',
  'Luce',
  'Luci',
  'Lucia',
  'Luciana',
  'Lucie',
  'Lucienne',
  'Lucila',
  'Lucilia',
  'Lucille',
  'Lucina',
  'Lucinda',
  'Lucine',
  'Lucita',
  'Lucky',
  'Lucretia',
  'Lucy',
  'Ludovika',
  'Luella',
  'Luelle',
  'Luisa',
  'Luise',
  'Lula',
  'Lulita',
  'Lulu',
  'Lura',
  'Lurette',
  'Lurleen',
  'Lurlene',
  'Lurline',
  'Lusa',
  'Luz',
  'Lyda',
  'Lydia',
  'Lydie',
  'Lyn',
  'Lynda',
  'Lynde',
  'Lyndel',
  'Lyndell',
  'Lyndsay',
  'Lyndsey',
  'Lyndsie',
  'Lyndy',
  'Lynea',
  'Lynelle',
  'Lynett',
  'Lynette',
  'Lynn',
  'Lynna',
  'Lynne',
  'Lynnea',
  'Lynnell',
  'Lynnelle',
  'Lynnet',
  'Lynnett',
  'Lynnette',
  'Lynsey',
  'Lyssa',
  'Mab',
  'Mabel',
  'Mabelle',
  'Mable',
  'Mada',
  'Madalena',
  'Madalyn',
  'Maddalena',
  'Maddi',
  'Maddie',
  'Maddy',
  'Madel',
  'Madelaine',
  'Madeleine',
  'Madelena',
  'Madelene',
  'Madelin',
  'Madelina',
  'Madeline',
  'Madella',
  'Madelle',
  'Madelon',
  'Madelyn',
  'Madge',
  'Madlen',
  'Madlin',
  'Madonna',
  'Mady',
  'Mae',
  'Maegan',
  'Mag',
  'Magda',
  'Magdaia',
  'Magdalen',
  'Magdalena',
  'Magdalene',
  'Maggee',
  'Maggi',
  'Maggie',
  'Maggy',
  'Mahala',
  'Mahalia',
  'Maia',
  'Maible',
  'Maiga',
  'Maighdiln',
  'Mair',
  'Maire',
  'Maisey',
  'Maisie',
  'Maitilde',
  'Mala',
  'Malanie',
  'Malena',
  'Malia',
  'Malina',
  'Malinda',
  'Malinde',
  'Malissa',
  'Malissia',
  'Mallissa',
  'Mallorie',
  'Mallory',
  'Malorie',
  'Malory',
  'Malva',
  'Malvina',
  'Malynda',
  'Mame',
  'Mamie',
  'Manda',
  'Mandi',
  'Mandie',
  'Mandy',
  'Manon',
  'Manya',
  'Mara',
  'Marabel',
  'Marcela',
  'Marcelia',
  'Marcella',
  'Marcelle',
  'Marcellina',
  'Marcelline',
  'Marchelle',
  'Marci',
  'Marcia',
  'Marcie',
  'Marcile',
  'Marcille',
  'Marcy',
  'Mareah',
  'Maren',
  'Marena',
  'Maressa',
  'Marga',
  'Margalit',
  'Margalo',
  'Margaret',
  'Margareta',
  'Margarete',
  'Margaretha',
  'Margarethe',
  'Margaretta',
  'Margarette',
  'Margarita',
  'Margaux',
  'Marge',
  'Margeaux',
  'Margery',
  'Marget',
  'Margette',
  'Margi',
  'Margie',
  'Margit',
  'Margo',
  'Margot',
  'Margret',
  'Marguerite',
  'Margy',
  'Mari',
  'Maria',
  'Mariam',
  'Marian',
  'Mariana',
  'Mariann',
  'Marianna',
  'Marianne',
  'Maribel',
  'Maribelle',
  'Maribeth',
  'Marice',
  'Maridel',
  'Marie',
  'Marie-ann',
  'Marie-jeanne',
  'Marieann',
  'Mariejeanne',
  'Mariel',
  'Mariele',
  'Marielle',
  'Mariellen',
  'Marietta',
  'Mariette',
  'Marigold',
  'Marijo',
  'Marika',
  'Marilee',
  'Marilin',
  'Marillin',
  'Marilyn',
  'Marin',
  'Marina',
  'Marinna',
  'Marion',
  'Mariquilla',
  'Maris',
  'Marisa',
  'Mariska',
  'Marissa',
  'Marita',
  'Maritsa',
  'Mariya',
  'Marj',
  'Marja',
  'Marje',
  'Marji',
  'Marjie',
  'Marjorie',
  'Marjory',
  'Marjy',
  'Marketa',
  'Marla',
  'Marlane',
  'Marleah',
  'Marlee',
  'Marleen',
  'Marlena',
  'Marlene',
  'Marley',
  'Marlie',
  'Marline',
  'Marlo',
  'Marlyn',
  'Marna',
  'Marne',
  'Marney',
  'Marni',
  'Marnia',
  'Marnie',
  'Marquita',
  'Marrilee',
  'Marris',
  'Marrissa',
  'Marsha',
  'Marsiella',
  'Marta',
  'Martelle',
  'Martguerita',
  'Martha',
  'Marthe',
  'Marthena',
  'Marti',
  'Martica',
  'Martie',
  'Martina',
  'Martita',
  'Marty',
  'Martynne',
  'Mary',
  'Marya',
  'Maryann',
  'Maryanna',
  'Maryanne',
  'Marybelle',
  'Marybeth',
  'Maryellen',
  'Maryjane',
  'Maryjo',
  'Maryl',
  'Marylee',
  'Marylin',
  'Marylinda',
  'Marylou',
  'Marylynne',
  'Maryrose',
  'Marys',
  'Marysa',
  'Masha',
  'Matelda',
  'Mathilda',
  'Mathilde',
  'Matilda',
  'Matilde',
  'Matti',
  'Mattie',
  'Matty',
  'Maud',
  'Maude',
  'Maudie',
  'Maura',
  'Maure',
  'Maureen',
  'Maureene',
  'Maurene',
  'Maurine',
  'Maurise',
  'Maurita',
  'Maurizia',
  'Mavis',
  'Mavra',
  'Max',
  'Maxi',
  'Maxie',
  'Maxine',
  'Maxy',
  'May',
  'Maybelle',
  'Maye',
  'Mead',
  'Meade',
  'Meagan',
  'Meaghan',
  'Meara',
  'Mechelle',
  'Meg',
  'Megan',
  'Megen',
  'Meggi',
  'Meggie',
  'Meggy',
  'Meghan',
  'Meghann',
  'Mehetabel',
  'Mei',
  'Mel',
  'Mela',
  'Melamie',
  'Melania',
  'Melanie',
  'Melantha',
  'Melany',
  'Melba',
  'Melesa',
  'Melessa',
  'Melicent',
  'Melina',
  'Melinda',
  'Melinde',
  'Melisa',
  'Melisande',
  'Melisandra',
  'Melisenda',
  'Melisent',
  'Melissa',
  'Melisse',
  'Melita',
  'Melitta',
  'Mella',
  'Melli',
  'Mellicent',
  'Mellie',
  'Mellisa',
  'Mellisent',
  'Melloney',
  'Melly',
  'Melodee',
  'Melodie',
  'Melody',
  'Melonie',
  'Melony',
  'Melosa',
  'Melva',
  'Mercedes',
  'Merci',
  'Mercie',
  'Mercy',
  'Meredith',
  'Meredithe',
  'Meridel',
  'Meridith',
  'Meriel',
  'Merilee',
  'Merilyn',
  'Meris',
  'Merissa',
  'Merl',
  'Merla',
  'Merle',
  'Merlina',
  'Merline',
  'Merna',
  'Merola',
  'Merralee',
  'Merridie',
  'Merrie',
  'Merrielle',
  'Merrile',
  'Merrilee',
  'Merrili',
  'Merrill',
  'Merrily',
  'Merry',
  'Mersey',
  'Meryl',
  'Meta',
  'Mia',
  'Micaela',
  'Michaela',
  'Michaelina',
  'Michaeline',
  'Michaella',
  'Michal',
  'Michel',
  'Michele',
  'Michelina',
  'Micheline',
  'Michell',
  'Michelle',
  'Micki',
  'Mickie',
  'Micky',
  'Midge',
  'Mignon',
  'Mignonne',
  'Miguela',
  'Miguelita',
  'Mikaela',
  'Mil',
  'Mildred',
  'Mildrid',
  'Milena',
  'Milicent',
  'Milissent',
  'Milka',
  'Milli',
  'Millicent',
  'Millie',
  'Millisent',
  'Milly',
  'Milzie',
  'Mimi',
  'Min',
  'Mina',
  'Minda',
  'Mindy',
  'Minerva',
  'Minetta',
  'Minette',
  'Minna',
  'Minnaminnie',
  'Minne',
  'Minni',
  'Minnie',
  'Minnnie',
  'Minny',
  'Minta',
  'Miquela',
  'Mira',
  'Mirabel',
  'Mirabella',
  'Mirabelle',
  'Miran',
  'Miranda',
  'Mireielle',
  'Mireille',
  'Mirella',
  'Mirelle',
  'Miriam',
  'Mirilla',
  'Mirna',
  'Misha',
  'Missie',
  'Missy',
  'Misti',
  'Misty',
  'Mitzi',
  'Modesta',
  'Modestia',
  'Modestine',
  'Modesty',
  'Moina',
  'Moira',
  'Moll',
  'Mollee',
  'Molli',
  'Mollie',
  'Molly',
  'Mommy',
  'Mona',
  'Monah',
  'Monica',
  'Monika',
  'Monique',
  'Mora',
  'Moreen',
  'Morena',
  'Morgan',
  'Morgana',
  'Morganica',
  'Morganne',
  'Morgen',
  'Moria',
  'Morissa',
  'Morna',
  'Moselle',
  'Moyna',
  'Moyra',
  'Mozelle',
  'Muffin',
  'Mufi',
  'Mufinella',
  'Muire',
  'Mureil',
  'Murial',
  'Muriel',
  'Murielle',
  'Myra',
  'Myrah',
  'Myranda',
  'Myriam',
  'Myrilla',
  'Myrle',
  'Myrlene',
  'Myrna',
  'Myrta',
  'Myrtia',
  'Myrtice',
  'Myrtie',
  'Myrtle',
  'Nada',
  'Nadean',
  'Nadeen',
  'Nadia',
  'Nadine',
  'Nadiya',
  'Nady',
  'Nadya',
  'Nalani',
  'Nan',
  'Nana',
  'Nananne',
  'Nance',
  'Nancee',
  'Nancey',
  'Nanci',
  'Nancie',
  'Nancy',
  'Nanete',
  'Nanette',
  'Nani',
  'Nanice',
  'Nanine',
  'Nannette',
  'Nanni',
  'Nannie',
  'Nanny',
  'Nanon',
  'Naoma',
  'Naomi',
  'Nara',
  'Nari',
  'Nariko',
  'Nat',
  'Nata',
  'Natala',
  'Natalee',
  'Natalie',
  'Natalina',
  'Nataline',
  'Natalya',
  'Natasha',
  'Natassia',
  'Nathalia',
  'Nathalie',
  'Natividad',
  'Natka',
  'Natty',
  'Neala',
  'Neda',
  'Nedda',
  'Nedi',
  'Neely',
  'Neila',
  'Neile',
  'Neilla',
  'Neille',
  'Nelia',
  'Nelie',
  'Nell',
  'Nelle',
  'Nelli',
  'Nellie',
  'Nelly',
  'Nerissa',
  'Nerita',
  'Nert',
  'Nerta',
  'Nerte',
  'Nerti',
  'Nertie',
  'Nerty',
  'Nessa',
  'Nessi',
  'Nessie',
  'Nessy',
  'Nesta',
  'Netta',
  'Netti',
  'Nettie',
  'Nettle',
  'Netty',
  'Nevsa',
  'Neysa',
  'Nichol',
  'Nichole',
  'Nicholle',
  'Nicki',
  'Nickie',
  'Nicky',
  'Nicol',
  'Nicola',
  'Nicole',
  'Nicolea',
  'Nicolette',
  'Nicoli',
  'Nicolina',
  'Nicoline',
  'Nicolle',
  'Nikaniki',
  'Nike',
  'Niki',
  'Nikki',
  'Nikkie',
  'Nikoletta',
  'Nikolia',
  'Nina',
  'Ninetta',
  'Ninette',
  'Ninnetta',
  'Ninnette',
  'Ninon',
  'Nissa',
  'Nisse',
  'Nissie',
  'Nissy',
  'Nita',
  'Nixie',
  'Noami',
  'Noel',
  'Noelani',
  'Noell',
  'Noella',
  'Noelle',
  'Noellyn',
  'Noelyn',
  'Noemi',
  'Nola',
  'Nolana',
  'Nolie',
  'Nollie',
  'Nomi',
  'Nona',
  'Nonah',
  'Noni',
  'Nonie',
  'Nonna',
  'Nonnah',
  'Nora',
  'Norah',
  'Norean',
  'Noreen',
  'Norene',
  'Norina',
  'Norine',
  'Norma',
  'Norri',
  'Norrie',
  'Norry',
  'Novelia',
  'Nydia',
  'Nyssa',
  'Octavia',
  'Odele',
  'Odelia',
  'Odelinda',
  'Odella',
  'Odelle',
  'Odessa',
  'Odetta',
  'Odette',
  'Odilia',
  'Odille',
  'Ofelia',
  'Ofella',
  'Ofilia',
  'Ola',
  'Olenka',
  'Olga',
  'Olia',
  'Olimpia',
  'Olive',
  'Olivette',
  'Olivia',
  'Olivie',
  'Oliy',
  'Ollie',
  'Olly',
  'Olva',
  'Olwen',
  'Olympe',
  'Olympia',
  'Olympie',
  'Ondrea',
  'Oneida',
  'Onida',
  'Oona',
  'Opal',
  'Opalina',
  'Opaline',
  'Ophelia',
  'Ophelie',
  'Ora',
  'Oralee',
  'Oralia',
  'Oralie',
  'Oralla',
  'Oralle',
  'Orel',
  'Orelee',
  'Orelia',
  'Orelie',
  'Orella',
  'Orelle',
  'Oriana',
  'Orly',
  'Orsa',
  'Orsola',
  'Ortensia',
  'Otha',
  'Othelia',
  'Othella',
  'Othilia',
  'Othilie',
  'Ottilie',
  'Page',
  'Paige',
  'Paloma',
  'Pam',
  'Pamela',
  'Pamelina',
  'Pamella',
  'Pammi',
  'Pammie',
  'Pammy',
  'Pandora',
  'Pansie',
  'Pansy',
  'Paola',
  'Paolina',
  'Papagena',
  'Pat',
  'Patience',
  'Patrica',
  'Patrice',
  'Patricia',
  'Patrizia',
  'Patsy',
  'Patti',
  'Pattie',
  'Patty',
  'Paula',
  'Paule',
  'Pauletta',
  'Paulette',
  'Pauli',
  'Paulie',
  'Paulina',
  'Pauline',
  'Paulita',
  'Pauly',
  'Pavia',
  'Pavla',
  'Pearl',
  'Pearla',
  'Pearle',
  'Pearline',
  'Peg',
  'Pegeen',
  'Peggi',
  'Peggie',
  'Peggy',
  'Pen',
  'Penelopa',
  'Penelope',
  'Penni',
  'Pennie',
  'Penny',
  'Pepi',
  'Pepita',
  'Peri',
  'Peria',
  'Perl',
  'Perla',
  'Perle',
  'Perri',
  'Perrine',
  'Perry',
  'Persis',
  'Pet',
  'Peta',
  'Petra',
  'Petrina',
  'Petronella',
  'Petronia',
  'Petronilla',
  'Petronille',
  'Petunia',
  'Phaedra',
  'Phaidra',
  'Phebe',
  'Phedra',
  'Phelia',
  'Phil',
  'Philipa',
  'Philippa',
  'Philippe',
  'Philippine',
  'Philis',
  'Phillida',
  'Phillie',
  'Phillis',
  'Philly',
  'Philomena',
  'Phoebe',
  'Phylis',
  'Phyllida',
  'Phyllis',
  'Phyllys',
  'Phylys',
  'Pia',
  'Pier',
  'Pierette',
  'Pierrette',
  'Pietra',
  'Piper',
  'Pippa',
  'Pippy',
  'Polly',
  'Pollyanna',
  'Pooh',
  'Poppy',
  'Portia',
  'Pris',
  'Prisca',
  'Priscella',
  'Priscilla',
  'Prissie',
  'Pru',
  'Prudence',
  'Prudi',
  'Prudy',
  'Prue',
  'Queenie',
  'Quentin',
  'Querida',
  'Quinn',
  'Quinta',
  'Quintana',
  'Quintilla',
  'Quintina',
  'Rachael',
  'Rachel',
  'Rachele',
  'Rachelle',
  'Rae',
  'Raeann',
  'Raf',
  'Rafa',
  'Rafaela',
  'Rafaelia',
  'Rafaelita',
  'Rahal',
  'Rahel',
  'Raina',
  'Raine',
  'Rakel',
  'Ralina',
  'Ramona',
  'Ramonda',
  'Rana',
  'Randa',
  'Randee',
  'Randene',
  'Randi',
  'Randie',
  'Randy',
  'Ranee',
  'Rani',
  'Rania',
  'Ranice',
  'Ranique',
  'Ranna',
  'Raphaela',
  'Raquel',
  'Raquela',
  'Rasia',
  'Rasla',
  'Raven',
  'Ray',
  'Raychel',
  'Raye',
  'Rayna',
  'Raynell',
  'Rayshell',
  'Rea',
  'Reba',
  'Rebbecca',
  'Rebe',
  'Rebeca',
  'Rebecca',
  'Rebecka',
  'Rebeka',
  'Rebekah',
  'Rebekkah',
  'Ree',
  'Reeba',
  'Reena',
  'Reeta',
  'Reeva',
  'Regan',
  'Reggi',
  'Reggie',
  'Regina',
  'Regine',
  'Reiko',
  'Reina',
  'Reine',
  'Remy',
  'Rena',
  'Renae',
  'Renata',
  'Renate',
  'Rene',
  'Renee',
  'Renell',
  'Renelle',
  'Renie',
  'Rennie',
  'Reta',
  'Retha',
  'Revkah',
  'Rey',
  'Reyna',
  'Rhea',
  'Rheba',
  'Rheta',
  'Rhetta',
  'Rhiamon',
  'Rhianna',
  'Rhianon',
  'Rhoda',
  'Rhodia',
  'Rhodie',
  'Rhody',
  'Rhona',
  'Rhonda',
  'Riane',
  'Riannon',
  'Rianon',
  'Rica',
  'Ricca',
  'Rici',
  'Ricki',
  'Rickie',
  'Ricky',
  'Riki',
  'Rikki',
  'Rina',
  'Risa',
  'Rita',
  'Riva',
  'Rivalee',
  'Rivi',
  'Rivkah',
  'Rivy',
  'Roana',
  'Roanna',
  'Roanne',
  'Robbi',
  'Robbie',
  'Robbin',
  'Robby',
  'Robbyn',
  'Robena',
  'Robenia',
  'Roberta',
  'Robin',
  'Robina',
  'Robinet',
  'Robinett',
  'Robinetta',
  'Robinette',
  'Robinia',
  'Roby',
  'Robyn',
  'Roch',
  'Rochell',
  'Rochella',
  'Rochelle',
  'Rochette',
  'Roda',
  'Rodi',
  'Rodie',
  'Rodina',
  'Rois',
  'Romola',
  'Romona',
  'Romonda',
  'Romy',
  'Rona',
  'Ronalda',
  'Ronda',
  'Ronica',
  'Ronna',
  'Ronni',
  'Ronnica',
  'Ronnie',
  'Ronny',
  'Roobbie',
  'Rora',
  'Rori',
  'Rorie',
  'Rory',
  'Ros',
  'Rosa',
  'Rosabel',
  'Rosabella',
  'Rosabelle',
  'Rosaleen',
  'Rosalia',
  'Rosalie',
  'Rosalind',
  'Rosalinda',
  'Rosalinde',
  'Rosaline',
  'Rosalyn',
  'Rosalynd',
  'Rosamond',
  'Rosamund',
  'Rosana',
  'Rosanna',
  'Rosanne',
  'Rose',
  'Roseann',
  'Roseanna',
  'Roseanne',
  'Roselia',
  'Roselin',
  'Roseline',
  'Rosella',
  'Roselle',
  'Rosemaria',
  'Rosemarie',
  'Rosemary',
  'Rosemonde',
  'Rosene',
  'Rosetta',
  'Rosette',
  'Roshelle',
  'Rosie',
  'Rosina',
  'Rosita',
  'Roslyn',
  'Rosmunda',
  'Rosy',
  'Row',
  'Rowe',
  'Rowena',
  'Roxana',
  'Roxane',
  'Roxanna',
  'Roxanne',
  'Roxi',
  'Roxie',
  'Roxine',
  'Roxy',
  'Roz',
  'Rozalie',
  'Rozalin',
  'Rozamond',
  'Rozanna',
  'Rozanne',
  'Roze',
  'Rozele',
  'Rozella',
  'Rozelle',
  'Rozina',
  'Rubetta',
  'Rubi',
  'Rubia',
  'Rubie',
  'Rubina',
  'Ruby',
  'Ruperta',
  'Ruth',
  'Ruthann',
  'Ruthanne',
  'Ruthe',
  'Ruthi',
  'Ruthie',
  'Ruthy',
  'Ryann',
  'Rycca',
  'Saba',
  'Sabina',
  'Sabine',
  'Sabra',
  'Sabrina',
  'Sacha',
  'Sada',
  'Sadella',
  'Sadie',
  'Sadye',
  'Saidee',
  'Sal',
  'Salaidh',
  'Sallee',
  'Salli',
  'Sallie',
  'Sally',
  'Sallyann',
  'Sallyanne',
  'Saloma',
  'Salome',
  'Salomi',
  'Sam',
  'Samantha',
  'Samara',
  'Samaria',
  'Sammy',
  'Sande',
  'Sandi',
  'Sandie',
  'Sandra',
  'Sandy',
  'Sandye',
  'Sapphira',
  'Sapphire',
  'Sara',
  'Sara-ann',
  'Saraann',
  'Sarah',
  'Sarajane',
  'Saree',
  'Sarena',
  'Sarene',
  'Sarette',
  'Sari',
  'Sarina',
  'Sarine',
  'Sarita',
  'Sascha',
  'Sasha',
  'Sashenka',
  'Saudra',
  'Saundra',
  'Savina',
  'Sayre',
  'Scarlet',
  'Scarlett',
  'Sean',
  'Seana',
  'Seka',
  'Sela',
  'Selena',
  'Selene',
  'Selestina',
  'Selia',
  'Selie',
  'Selina',
  'Selinda',
  'Seline',
  'Sella',
  'Selle',
  'Selma',
  'Sena',
  'Sephira',
  'Serena',
  'Serene',
  'Shae',
  'Shaina',
  'Shaine',
  'Shalna',
  'Shalne',
  'Shana',
  'Shanda',
  'Shandee',
  'Shandeigh',
  'Shandie',
  'Shandra',
  'Shandy',
  'Shane',
  'Shani',
  'Shanie',
  'Shanna',
  'Shannah',
  'Shannen',
  'Shannon',
  'Shanon',
  'Shanta',
  'Shantee',
  'Shara',
  'Sharai',
  'Shari',
  'Sharia',
  'Sharity',
  'Sharl',
  'Sharla',
  'Sharleen',
  'Sharlene',
  'Sharline',
  'Sharon',
  'Sharona',
  'Sharron',
  'Sharyl',
  'Shaun',
  'Shauna',
  'Shawn',
  'Shawna',
  'Shawnee',
  'Shay',
  'Shayla',
  'Shaylah',
  'Shaylyn',
  'Shaylynn',
  'Shayna',
  'Shayne',
  'Shea',
  'Sheba',
  'Sheela',
  'Sheelagh',
  'Sheelah',
  'Sheena',
  'Sheeree',
  'Sheila',
  'Sheila-kathryn',
  'Sheilah',
  'Shel',
  'Shela',
  'Shelagh',
  'Shelba',
  'Shelbi',
  'Shelby',
  'Shelia',
  'Shell',
  'Shelley',
  'Shelli',
  'Shellie',
  'Shelly',
  'Shena',
  'Sher',
  'Sheree',
  'Sheri',
  'Sherie',
  'Sherill',
  'Sherilyn',
  'Sherline',
  'Sherri',
  'Sherrie',
  'Sherry',
  'Sherye',
  'Sheryl',
  'Shina',
  'Shir',
  'Shirl',
  'Shirlee',
  'Shirleen',
  'Shirlene',
  'Shirley',
  'Shirline',
  'Shoshana',
  'Shoshanna',
  'Siana',
  'Sianna',
  'Sib',
  'Sibbie',
  'Sibby',
  'Sibeal',
  'Sibel',
  'Sibella',
  'Sibelle',
  'Sibilla',
  'Sibley',
  'Sibyl',
  'Sibylla',
  'Sibylle',
  'Sidoney',
  'Sidonia',
  'Sidonnie',
  'Sigrid',
  'Sile',
  'Sileas',
  'Silva',
  'Silvana',
  'Silvia',
  'Silvie',
  'Simona',
  'Simone',
  'Simonette',
  'Simonne',
  'Sindee',
  'Siobhan',
  'Sioux',
  'Siouxie',
  'Sisely',
  'Sisile',
  'Sissie',
  'Sissy',
  'Siusan',
  'Sofia',
  'Sofie',
  'Sondra',
  'Sonia',
  'Sonja',
  'Sonni',
  'Sonnie',
  'Sonnnie',
  'Sonny',
  'Sonya',
  'Sophey',
  'Sophi',
  'Sophia',
  'Sophie',
  'Sophronia',
  'Sorcha',
  'Sosanna',
  'Stace',
  'Stacee',
  'Stacey',
  'Staci',
  'Stacia',
  'Stacie',
  'Stacy',
  'Stafani',
  'Star',
  'Starla',
  'Starlene',
  'Starlin',
  'Starr',
  'Stefa',
  'Stefania',
  'Stefanie',
  'Steffane',
  'Steffi',
  'Steffie',
  'Stella',
  'Stepha',
  'Stephana',
  'Stephani',
  'Stephanie',
  'Stephannie',
  'Stephenie',
  'Stephi',
  'Stephie',
  'Stephine',
  'Stesha',
  'Stevana',
  'Stevena',
  'Stoddard',
  'Storm',
  'Stormi',
  'Stormie',
  'Stormy',
  'Sue',
  'Suellen',
  'Sukey',
  'Suki',
  'Sula',
  'Sunny',
  'Sunshine',
  'Susan',
  'Susana',
  'Susanetta',
  'Susann',
  'Susanna',
  'Susannah',
  'Susanne',
  'Susette',
  'Susi',
  'Susie',
  'Susy',
  'Suzann',
  'Suzanna',
  'Suzanne',
  'Suzette',
  'Suzi',
  'Suzie',
  'Suzy',
  'Sybil',
  'Sybila',
  'Sybilla',
  'Sybille',
  'Sybyl',
  'Sydel',
  'Sydelle',
  'Sydney',
  'Sylvia',
  'Tabatha',
  'Tabbatha',
  'Tabbi',
  'Tabbie',
  'Tabbitha',
  'Tabby',
  'Tabina',
  'Tabitha',
  'Taffy',
  'Talia',
  'Tallia',
  'Tallie',
  'Tallou',
  'Tallulah',
  'Tally',
  'Talya',
  'Talyah',
  'Tamar',
  'Tamara',
  'Tamarah',
  'Tamarra',
  'Tamera',
  'Tami',
  'Tamiko',
  'Tamma',
  'Tammara',
  'Tammi',
  'Tammie',
  'Tammy',
  'Tamqrah',
  'Tamra',
  'Tana',
  'Tandi',
  'Tandie',
  'Tandy',
  'Tanhya',
  'Tani',
  'Tania',
  'Tanitansy',
  'Tansy',
  'Tanya',
  'Tara',
  'Tarah',
  'Tarra',
  'Tarrah',
  'Taryn',
  'Tasha',
  'Tasia',
  'Tate',
  'Tatiana',
  'Tatiania',
  'Tatum',
  'Tawnya',
  'Tawsha',
  'Ted',
  'Tedda',
  'Teddi',
  'Teddie',
  'Teddy',
  'Tedi',
  'Tedra',
  'Teena',
  'Teirtza',
  'Teodora',
  'Tera',
  'Teresa',
  'Terese',
  'Teresina',
  'Teresita',
  'Teressa',
  'Teri',
  'Teriann',
  'Terra',
  'Terri',
  'Terrie',
  'Terrijo',
  'Terry',
  'Terrye',
  'Tersina',
  'Terza',
  'Tess',
  'Tessa',
  'Tessi',
  'Tessie',
  'Tessy',
  'Thalia',
  'Thea',
  'Theadora',
  'Theda',
  'Thekla',
  'Thelma',
  'Theo',
  'Theodora',
  'Theodosia',
  'Theresa',
  'Therese',
  'Theresina',
  'Theresita',
  'Theressa',
  'Therine',
  'Thia',
  'Thomasa',
  'Thomasin',
  'Thomasina',
  'Thomasine',
  'Tiena',
  'Tierney',
  'Tiertza',
  'Tiff',
  'Tiffani',
  'Tiffanie',
  'Tiffany',
  'Tiffi',
  'Tiffie',
  'Tiffy',
  'Tilda',
  'Tildi',
  'Tildie',
  'Tildy',
  'Tillie',
  'Tilly',
  'Tim',
  'Timi',
  'Timmi',
  'Timmie',
  'Timmy',
  'Timothea',
  'Tina',
  'Tine',
  'Tiphani',
  'Tiphanie',
  'Tiphany',
  'Tish',
  'Tisha',
  'Tobe',
  'Tobey',
  'Tobi',
  'Toby',
  'Tobye',
  'Toinette',
  'Toma',
  'Tomasina',
  'Tomasine',
  'Tomi',
  'Tommi',
  'Tommie',
  'Tommy',
  'Toni',
  'Tonia',
  'Tonie',
  'Tony',
  'Tonya',
  'Tonye',
  'Tootsie',
  'Torey',
  'Tori',
  'Torie',
  'Torrie',
  'Tory',
  'Tova',
  'Tove',
  'Tracee',
  'Tracey',
  'Traci',
  'Tracie',
  'Tracy',
  'Trenna',
  'Tresa',
  'Trescha',
  'Tressa',
  'Tricia',
  'Trina',
  'Trish',
  'Trisha',
  'Trista',
  'Trix',
  'Trixi',
  'Trixie',
  'Trixy',
  'Truda',
  'Trude',
  'Trudey',
  'Trudi',
  'Trudie',
  'Trudy',
  'Trula',
  'Tuesday',
  'Twila',
  'Twyla',
  'Tybi',
  'Tybie',
  'Tyne',
  'Ula',
  'Ulla',
  'Ulrica',
  'Ulrika',
  'Ulrikaumeko',
  'Ulrike',
  'Umeko',
  'Una',
  'Ursa',
  'Ursala',
  'Ursola',
  'Ursula',
  'Ursulina',
  'Ursuline',
  'Uta',
  'Val',
  'Valaree',
  'Valaria',
  'Vale',
  'Valeda',
  'Valencia',
  'Valene',
  'Valenka',
  'Valentia',
  'Valentina',
  'Valentine',
  'Valera',
  'Valeria',
  'Valerie',
  'Valery',
  'Valerye',
  'Valida',
  'Valina',
  'Valli',
  'Vallie',
  'Vally',
  'Valma',
  'Valry',
  'Van',
  'Vanda',
  'Vanessa',
  'Vania',
  'Vanna',
  'Vanni',
  'Vannie',
  'Vanny',
  'Vanya',
  'Veda',
  'Velma',
  'Velvet',
  'Venita',
  'Venus',
  'Vera',
  'Veradis',
  'Vere',
  'Verena',
  'Verene',
  'Veriee',
  'Verile',
  'Verina',
  'Verine',
  'Verla',
  'Verna',
  'Vernice',
  'Veronica',
  'Veronika',
  'Veronike',
  'Veronique',
  'Vevay',
  'Vi',
  'Vicki',
  'Vickie',
  'Vicky',
  'Victoria',
  'Vida',
  'Viki',
  'Vikki',
  'Vikky',
  'Vilhelmina',
  'Vilma',
  'Vin',
  'Vina',
  'Vinita',
  'Vinni',
  'Vinnie',
  'Vinny',
  'Viola',
  'Violante',
  'Viole',
  'Violet',
  'Violetta',
  'Violette',
  'Virgie',
  'Virgina',
  'Virginia',
  'Virginie',
  'Vita',
  'Vitia',
  'Vitoria',
  'Vittoria',
  'Viv',
  'Viva',
  'Vivi',
  'Vivia',
  'Vivian',
  'Viviana',
  'Vivianna',
  'Vivianne',
  'Vivie',
  'Vivien',
  'Viviene',
  'Vivienne',
  'Viviyan',
  'Vivyan',
  'Vivyanne',
  'Vonni',
  'Vonnie',
  'Vonny',
  'Vyky',
  'Wallie',
  'Wallis',
  'Walliw',
  'Wally',
  'Waly',
  'Wanda',
  'Wandie',
  'Wandis',
  'Waneta',
  'Wanids',
  'Wenda',
  'Wendeline',
  'Wendi',
  'Wendie',
  'Wendy',
  'Wendye',
  'Wenona',
  'Wenonah',
  'Whitney',
  'Wileen',
  'Wilhelmina',
  'Wilhelmine',
  'Wilie',
  'Willa',
  'Willabella',
  'Willamina',
  'Willetta',
  'Willette',
  'Willi',
  'Willie',
  'Willow',
  'Willy',
  'Willyt',
  'Wilma',
  'Wilmette',
  'Wilona',
  'Wilone',
  'Wilow',
  'Windy',
  'Wini',
  'Winifred',
  'Winna',
  'Winnah',
  'Winne',
  'Winni',
  'Winnie',
  'Winnifred',
  'Winny',
  'Winona',
  'Winonah',
  'Wren',
  'Wrennie',
  'Wylma',
  'Wynn',
  'Wynne',
  'Wynnie',
  'Wynny',
  'Xaviera',
  'Xena',
  'Xenia',
  'Xylia',
  'Xylina',
  'Yalonda',
  'Yasmeen',
  'Yasmin',
  'Yelena',
  'Yetta',
  'Yettie',
  'Yetty',
  'Yevette',
  'Ynes',
  'Ynez',
  'Yoko',
  'Yolanda',
  'Yolande',
  'Yolane',
  'Yolanthe',
  'Yoshi',
  'Yoshiko',
  'Yovonnda',
  'Ysabel',
  'Yvette',
  'Yvonne',
  'Zabrina',
  'Zahara',
  'Zandra',
  'Zaneta',
  'Zara',
  'Zarah',
  'Zaria',
  'Zarla',
  'Zea',
  'Zelda',
  'Zelma',
  'Zena',
  'Zenia',
  'Zia',
  'Zilvia',
  'Zita',
  'Zitella',
  'Zoe',
  'Zola',
  'Zonda',
  'Zondra',
  'Zonnya',
  'Zora',
  'Zorah',
  'Zorana',
  'Zorina',
  'Zorine',
  'Zsazsa',
  'Zulema',
  'Zuzana',
];

export const adjectives = [
  'able',
  'above',
  'absent',
  'absolute',
  'abstract',
  'abundant',
  'academic',
  'acceptable',
  'accepted',
  'accessible',
  'accurate',
  'accused',
  'active',
  'actual',
  'acute',
  'added',
  'additional',
  'adequate',
  'adjacent',
  'administrative',
  'adorable',
  'advanced',
  'adverse',
  'advisory',
  'aesthetic',
  'afraid',
  'aggregate',
  'aggressive',
  'agreeable',
  'agreed',
  'agricultural',
  'alert',
  'alive',
  'alleged',
  'allied',
  'alone',
  'alright',
  'alternative',
  'amateur',
  'amazing',
  'ambitious',
  'amused',
  'ancient',
  'angry',
  'annoyed',
  'annual',
  'anonymous',
  'anxious',
  'appalling',
  'apparent',
  'applicable',
  'appropriate',
  'arbitrary',
  'architectural',
  'armed',
  'arrogant',
  'artificial',
  'artistic',
  'ashamed',
  'asleep',
  'assistant',
  'associated',
  'atomic',
  'attractive',
  'automatic',
  'autonomous',
  'available',
  'average',
  'awake',
  'aware',
  'awful',
  'awkward',
  'back',
  'bad',
  'balanced',
  'bare',
  'basic',
  'beautiful',
  'beneficial',
  'better',
  'bewildered',
  'big',
  'binding',
  'biological',
  'bitter',
  'bizarre',
  'blank',
  'blind',
  'blonde',
  'bloody',
  'blushing',
  'boiling',
  'bold',
  'bored',
  'boring',
  'bottom',
  'brainy',
  'brave',
  'breakable',
  'breezy',
  'brief',
  'bright',
  'brilliant',
  'broad',
  'broken',
  'bumpy',
  'burning',
  'busy',
  'calm',
  'capable',
  'capitalist',
  'careful',
  'casual',
  'causal',
  'cautious',
  'central',
  'certain',
  'changing',
  'characteristic',
  'charming',
  'cheap',
  'cheerful',
  'chemical',
  'chief',
  'chilly',
  'chosen',
  'christian',
  'chronic',
  'chubby',
  'circular',
  'civic',
  'civil',
  'civilian',
  'classic',
  'classical',
  'clean',
  'clear',
  'clever',
  'clinical',
  'close',
  'closed',
  'cloudy',
  'clumsy',
  'coastal',
  'cognitive',
  'coherent',
  'cold',
  'collective',
  'colonial',
  'colorful',
  'colossal',
  'coloured',
  'colourful',
  'combative',
  'combined',
  'comfortable',
  'coming',
  'commercial',
  'common',
  'communist',
  'compact',
  'comparable',
  'comparative',
  'compatible',
  'competent',
  'competitive',
  'complete',
  'complex',
  'complicated',
  'comprehensive',
  'compulsory',
  'conceptual',
  'concerned',
  'concrete',
  'condemned',
  'confident',
  'confidential',
  'confused',
  'conscious',
  'conservation',
  'conservative',
  'considerable',
  'consistent',
  'constant',
  'constitutional',
  'contemporary',
  'content',
  'continental',
  'continued',
  'continuing',
  'continuous',
  'controlled',
  'controversial',
  'convenient',
  'conventional',
  'convinced',
  'convincing',
  'cooing',
  'cool',
  'cooperative',
  'corporate',
  'correct',
  'corresponding',
  'costly',
  'courageous',
  'crazy',
  'creative',
  'creepy',
  'criminal',
  'critical',
  'crooked',
  'crowded',
  'crucial',
  'crude',
  'cruel',
  'cuddly',
  'cultural',
  'curious',
  'curly',
  'current',
  'curved',
  'cute',
  'daily',
  'damaged',
  'damp',
  'dangerous',
  'dark',
  'dead',
  'deaf',
  'deafening',
  'dear',
  'decent',
  'decisive',
  'deep',
  'defeated',
  'defensive',
  'defiant',
  'definite',
  'deliberate',
  'delicate',
  'delicious',
  'delighted',
  'delightful',
  'democratic',
  'dependent',
  'depressed',
  'desirable',
  'desperate',
  'detailed',
  'determined',
  'developed',
  'developing',
  'devoted',
  'different',
  'difficult',
  'digital',
  'diplomatic',
  'direct',
  'dirty',
  'disabled',
  'disappointed',
  'disastrous',
  'disciplinary',
  'disgusted',
  'distant',
  'distinct',
  'distinctive',
  'distinguished',
  'disturbed',
  'disturbing',
  'diverse',
  'divine',
  'dizzy',
  'domestic',
  'dominant',
  'double',
  'doubtful',
  'drab',
  'dramatic',
  'dreadful',
  'driving',
  'drunk',
  'dry',
  'dual',
  'due',
  'dull',
  'dusty',
  'dutch',
  'dying',
  'dynamic',
  'eager',
  'early',
  'eastern',
  'easy',
  'economic',
  'educational',
  'eerie',
  'effective',
  'efficient',
  'elaborate',
  'elated',
  'elderly',
  'eldest',
  'electoral',
  'electric',
  'electrical',
  'electronic',
  'elegant',
  'eligible',
  'embarrassed',
  'embarrassing',
  'emotional',
  'empirical',
  'empty',
  'enchanting',
  'encouraging',
  'endless',
  'energetic',
  'enormous',
  'enthusiastic',
  'entire',
  'entitled',
  'envious',
  'environmental',
  'equal',
  'equivalent',
  'essential',
  'established',
  'estimated',
  'ethical',
  'ethnic',
  'eventual',
  'everyday',
  'evident',
  'evil',
  'evolutionary',
  'exact',
  'excellent',
  'exceptional',
  'excess',
  'excessive',
  'excited',
  'exciting',
  'exclusive',
  'existing',
  'exotic',
  'expected',
  'expensive',
  'experienced',
  'experimental',
  'explicit',
  'extended',
  'extensive',
  'external',
  'extra',
  'extraordinary',
  'extreme',
  'exuberant',
  'faint',
  'fair',
  'faithful',
  'familiar',
  'famous',
  'fancy',
  'fantastic',
  'far',
  'fascinating',
  'fashionable',
  'fast',
  'fat',
  'fatal',
  'favourable',
  'favourite',
  'federal',
  'fellow',
  'female',
  'feminist',
  'few',
  'fierce',
  'filthy',
  'final',
  'financial',
  'fine',
  'firm',
  'fiscal',
  'fit',
  'fixed',
  'flaky',
  'flat',
  'flexible',
  'fluffy',
  'fluttering',
  'flying',
  'following',
  'fond',
  'foolish',
  'foreign',
  'formal',
  'formidable',
  'forthcoming',
  'fortunate',
  'forward',
  'fragile',
  'frail',
  'frantic',
  'free',
  'frequent',
  'fresh',
  'friendly',
  'frightened',
  'front',
  'frozen',
  'full',
  'fun',
  'functional',
  'fundamental',
  'funny',
  'furious',
  'future',
  'fuzzy',
  'gastric',
  'gay',
  'general',
  'generous',
  'genetic',
  'gentle',
  'genuine',
  'geographical',
  'giant',
  'gigantic',
  'given',
  'glad',
  'glamorous',
  'gleaming',
  'global',
  'glorious',
  'golden',
  'good',
  'gorgeous',
  'gothic',
  'governing',
  'graceful',
  'gradual',
  'grand',
  'grateful',
  'greasy',
  'great',
  'grieving',
  'grim',
  'gross',
  'grotesque',
  'growing',
  'grubby',
  'grumpy',
  'guilty',
  'handicapped',
  'handsome',
  'happy',
  'hard',
  'harsh',
  'head',
  'healthy',
  'heavy',
  'helpful',
  'helpless',
  'hidden',
  'high',
  'hilarious',
  'hissing',
  'historic',
  'historical',
  'hollow',
  'holy',
  'homeless',
  'homely',
  'hon',
  'honest',
  'horizontal',
  'horrible',
  'hostile',
  'hot',
  'huge',
  'human',
  'hungry',
  'hurt',
  'hushed',
  'husky',
  'icy',
  'ideal',
  'identical',
  'ideological',
  'ill',
  'illegal',
  'imaginative',
  'immediate',
  'immense',
  'imperial',
  'implicit',
  'important',
  'impossible',
  'impressed',
  'impressive',
  'improved',
  'inadequate',
  'inappropriate',
  'inc',
  'inclined',
  'increased',
  'increasing',
  'incredible',
  'independent',
  'indirect',
  'individual',
  'industrial',
  'inevitable',
  'influential',
  'informal',
  'inherent',
  'initial',
  'injured',
  'inland',
  'inner',
  'innocent',
  'innovative',
  'inquisitive',
  'instant',
  'institutional',
  'insufficient',
  'intact',
  'integral',
  'integrated',
  'intellectual',
  'intelligent',
  'intense',
  'intensive',
  'interested',
  'interesting',
  'interim',
  'interior',
  'intermediate',
  'internal',
  'international',
  'intimate',
  'invisible',
  'involved',
  'irrelevant',
  'isolated',
  'itchy',
  'jealous',
  'jittery',
  'joint',
  'jolly',
  'joyous',
  'judicial',
  'juicy',
  'junior',
  'just',
  'keen',
  'key',
  'kind',
  'known',
  'labour',
  'large',
  'late',
  'latin',
  'lazy',
  'leading',
  'left',
  'legal',
  'legislative',
  'legitimate',
  'lengthy',
  'lesser',
  'level',
  'lexical',
  'liable',
  'liberal',
  'light',
  'like',
  'likely',
  'limited',
  'linear',
  'linguistic',
  'liquid',
  'literary',
  'little',
  'live',
  'lively',
  'living',
  'local',
  'logical',
  'lonely',
  'long',
  'loose',
  'lost',
  'loud',
  'lovely',
  'low',
  'loyal',
  'ltd',
  'lucky',
  'mad',
  'magic',
  'magnetic',
  'magnificent',
  'main',
  'major',
  'male',
  'mammoth',
  'managerial',
  'managing',
  'manual',
  'many',
  'marginal',
  'marine',
  'marked',
  'married',
  'marvellous',
  'marxist',
  'mass',
  'massive',
  'mathematical',
  'mature',
  'maximum',
  'mean',
  'meaningful',
  'mechanical',
  'medical',
  'medieval',
  'melodic',
  'melted',
  'mental',
  'mere',
  'metropolitan',
  'mid',
  'middle',
  'mighty',
  'mild',
  'military',
  'miniature',
  'minimal',
  'minimum',
  'ministerial',
  'minor',
  'miserable',
  'misleading',
  'missing',
  'misty',
  'mixed',
  'moaning',
  'mobile',
  'moderate',
  'modern',
  'modest',
  'molecular',
  'monetary',
  'monthly',
  'moral',
  'motionless',
  'muddy',
  'multiple',
  'mushy',
  'musical',
  'mute',
  'mutual',
  'mysterious',
  'naked',
  'narrow',
  'nasty',
  'national',
  'native',
  'natural',
  'naughty',
  'naval',
  'near',
  'nearby',
  'neat',
  'necessary',
  'negative',
  'neighbouring',
  'nervous',
  'net',
  'neutral',
  'new',
  'nice',
  'noble',
  'noisy',
  'normal',
  'northern',
  'nosy',
  'notable',
  'novel',
  'nuclear',
  'numerous',
  'nursing',
  'nutritious',
  'nutty',
  'obedient',
  'objective',
  'obliged',
  'obnoxious',
  'obvious',
  'occasional',
  'occupational',
  'odd',
  'official',
  'ok',
  'okay',
  'old',
  'olympic',
  'only',
  'open',
  'operational',
  'opposite',
  'optimistic',
  'oral',
  'ordinary',
  'organic',
  'organisational',
  'original',
  'orthodox',
  'other',
  'outdoor',
  'outer',
  'outrageous',
  'outside',
  'outstanding',
  'overall',
  'overseas',
  'overwhelming',
  'painful',
  'pale',
  'panicky',
  'parallel',
  'parental',
  'parliamentary',
  'partial',
  'particular',
  'passing',
  'passive',
  'past',
  'patient',
  'payable',
  'peaceful',
  'peculiar',
  'perfect',
  'permanent',
  'persistent',
  'personal',
  'petite',
  'philosophical',
  'physical',
  'plain',
  'planned',
  'plastic',
  'pleasant',
  'pleased',
  'poised',
  'polite',
  'political',
  'poor',
  'popular',
  'positive',
  'possible',
  'potential',
  'powerful',
  'practical',
  'precious',
  'precise',
  'preferred',
  'pregnant',
  'preliminary',
  'premier',
  'prepared',
  'present',
  'presidential',
  'pretty',
  'previous',
  'prickly',
  'primary',
  'prime',
  'primitive',
  'principal',
  'printed',
  'prior',
  'private',
  'probable',
  'productive',
  'professional',
  'profitable',
  'profound',
  'progressive',
  'prominent',
  'promising',
  'proper',
  'proposed',
  'prospective',
  'protective',
  'protestant',
  'proud',
  'provincial',
  'psychiatric',
  'psychological',
  'public',
  'puny',
  'pure',
  'purring',
  'puzzled',
  'quaint',
  'qualified',
  'quarrelsome',
  'querulous',
  'quick',
  'quickest',
  'quiet',
  'quintessential',
  'quixotic',
  'racial',
  'radical',
  'rainy',
  'random',
  'rapid',
  'rare',
  'raspy',
  'rational',
  'ratty',
  'raw',
  'ready',
  'real',
  'realistic',
  'rear',
  'reasonable',
  'recent',
  'reduced',
  'redundant',
  'regional',
  'registered',
  'regular',
  'regulatory',
  'related',
  'relative',
  'relaxed',
  'relevant',
  'reliable',
  'relieved',
  'religious',
  'reluctant',
  'remaining',
  'remarkable',
  'remote',
  'renewed',
  'representative',
  'repulsive',
  'required',
  'resident',
  'residential',
  'resonant',
  'respectable',
  'respective',
  'responsible',
  'resulting',
  'retail',
  'retired',
  'revolutionary',
  'rich',
  'ridiculous',
  'right',
  'rigid',
  'ripe',
  'rising',
  'rival',
  'roasted',
  'robust',
  'rolling',
  'romantic',
  'rotten',
  'rough',
  'round',
  'royal',
  'rubber',
  'rude',
  'ruling',
  'running',
  'rural',
  'sacred',
  'sad',
  'safe',
  'salty',
  'satisfactory',
  'satisfied',
  'scared',
  'scary',
  'scattered',
  'scientific',
  'scornful',
  'scrawny',
  'screeching',
  'secondary',
  'secret',
  'secure',
  'select',
  'selected',
  'selective',
  'selfish',
  'semantic',
  'senior',
  'sensible',
  'sensitive',
  'separate',
  'serious',
  'severe',
  'sexual',
  'shaggy',
  'shaky',
  'shallow',
  'shared',
  'sharp',
  'sheer',
  'shiny',
  'shivering',
  'shocked',
  'short',
  'shrill',
  'shy',
  'sick',
  'significant',
  'silent',
  'silky',
  'silly',
  'similar',
  'simple',
  'single',
  'skilled',
  'skinny',
  'sleepy',
  'slight',
  'slim',
  'slimy',
  'slippery',
  'slow',
  'small',
  'smart',
  'smiling',
  'smoggy',
  'smooth',
  'social',
  'socialist',
  'soft',
  'solar',
  'sole',
  'solid',
  'sophisticated',
  'sore',
  'sorry',
  'sound',
  'sour',
  'southern',
  'soviet',
  'spare',
  'sparkling',
  'spatial',
  'special',
  'specific',
  'specified',
  'spectacular',
  'spicy',
  'spiritual',
  'splendid',
  'spontaneous',
  'sporting',
  'spotless',
  'spotty',
  'square',
  'squealing',
  'stable',
  'stale',
  'standard',
  'static',
  'statistical',
  'statutory',
  'steady',
  'steep',
  'sticky',
  'stiff',
  'still',
  'stingy',
  'stormy',
  'straight',
  'straightforward',
  'strange',
  'strategic',
  'strict',
  'striking',
  'striped',
  'strong',
  'structural',
  'stuck',
  'stupid',
  'subjective',
  'subsequent',
  'substantial',
  'subtle',
  'successful',
  'successive',
  'sudden',
  'sufficient',
  'suitable',
  'sunny',
  'super',
  'superb',
  'superior',
  'supporting',
  'supposed',
  'supreme',
  'sure',
  'surprised',
  'surprising',
  'surrounding',
  'surviving',
  'suspicious',
  'sweet',
  'swift',
  'symbolic',
  'sympathetic',
  'systematic',
  'tall',
  'tame',
  'tart',
  'tasteless',
  'tasty',
  'technical',
  'technological',
  'teenage',
  'temporary',
  'tender',
  'tense',
  'terrible',
  'territorial',
  'testy',
  'then',
  'theoretical',
  'thick',
  'thin',
  'thirsty',
  'thorough',
  'thoughtful',
  'thoughtless',
  'thundering',
  'tight',
  'tiny',
  'tired',
  'top',
  'tory',
  'total',
  'tough',
  'toxic',
  'traditional',
  'tragic',
  'tremendous',
  'tricky',
  'tropical',
  'troubled',
  'typical',
  'ugliest',
  'ugly',
  'ultimate',
  'unable',
  'unacceptable',
  'unaware',
  'uncertain',
  'unchanged',
  'uncomfortable',
  'unconscious',
  'underground',
  'underlying',
  'unemployed',
  'uneven',
  'unexpected',
  'unfair',
  'unfortunate',
  'unhappy',
  'uniform',
  'uninterested',
  'unique',
  'united',
  'universal',
  'unknown',
  'unlikely',
  'unnecessary',
  'unpleasant',
  'unsightly',
  'unusual',
  'unwilling',
  'upper',
  'upset',
  'uptight',
  'urban',
  'urgent',
  'used',
  'useful',
  'useless',
  'usual',
  'vague',
  'valid',
  'valuable',
  'variable',
  'varied',
  'various',
  'varying',
  'vast',
  'verbal',
  'vertical',
  'very',
  'vicarious',
  'vicious',
  'victorious',
  'violent',
  'visible',
  'visiting',
  'visual',
  'vital',
  'vitreous',
  'vivacious',
  'vivid',
  'vocal',
  'vocational',
  'voiceless',
  'voluminous',
  'voluntary',
  'vulnerable',
  'wandering',
  'warm',
  'wasteful',
  'watery',
  'weak',
  'wealthy',
  'weary',
  'wee',
  'weekly',
  'weird',
  'welcome',
  'well',
  'western',
  'wet',
  'whispering',
  'whole',
  'wicked',
  'wide',
  'widespread',
  'wild',
  'wilful',
  'willing',
  'willowy',
  'wily',
  'wise',
  'wispy',
  'wittering',
  'witty',
  'wonderful',
  'wooden',
  'working',
  'worldwide',
  'worried',
  'worrying',
  'worthwhile',
  'worthy',
  'written',
  'wrong',
  'xenacious',
  'xenial',
  'xenogeneic',
  'xenophobic',
  'xeric',
  'xerothermic',
  'yabbering',
  'yammering',
  'yappiest',
  'yappy',
  'yawning',
  'yearling',
  'yearning',
  'yeasty',
  'yelling',
  'yelping',
  'yielding',
  'yodelling',
  'young',
  'youngest',
  'youthful',
  'ytterbic',
  'yucky',
  'yummy',
  'zany',
  'zealous',
  'zeroth',
  'zestful',
  'zesty',
  'zippy',
  'zonal',
  'zoophagous',
  'zygomorphic',
  'zygotic',
];
