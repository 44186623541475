import { adjectives,names,colors , periodictable } from './dictionaries';


export const getHostname = ()=>{
    const diction = {}
    diction[0] = parseInt( Math.random()* adjectives.length-1);
    diction[1] = parseInt( Math.random()* names.length-1);
    diction[2] = parseInt(Math.random() * colors.length-1)
    diction[3] = parseInt(Math.random() * periodictable.length - 1);
    return periodictable[diction[3]]['name'].toLowerCase() +"."+ colors[diction[2]] ;
}