import {Layout, Menu} from 'antd';
import React, {useContext} from 'react';
// Custom imports
import {Context} from '../../context';
import {PAGE} from '../../reducer/types';
import {loadPage} from '../../SharedFunctions';

const SideNavigation = () => {
  const {Header, Footer, Sider, Content} = Layout;
  const {SubMenu} = Menu;

  const {state, dispatch} = useContext(Context);

  const loadPage = (key) => {
    dispatch({
      type: PAGE,
      payload: {
        page: key,
      },
    });
  };

  return (
    <Sider theme="light" width="100%">
      <Menu
        style={{ width: '100%' }}
        defaultSelectedKeys={['dashboard']}
        defaultOpenKeys={['dedicatedServers']}
        mode="inline"
        selectedKeys={state.page}
      >
        <Menu.Item
          key="dashboard"
          onClick={() => loadPage('dashboard', dispatch)}
        >
          Dashboard
        </Menu.Item>
        <SubMenu key="dedicatedServers" title="Dedicated Servers">
          <Menu.ItemGroup key="g1">
            <Menu.Item
              key="dsManage"
              onClick={() => loadPage('dsManage', dispatch)}
            >
              Manage
            </Menu.Item>
            <Menu.Item
              key="dsOrder"
              onClick={() => loadPage('dsOrder', dispatch)}
            >
              Order
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <SubMenu key="cloudServers" title="Cloud Servers">
          <Menu.ItemGroup key="g2">
            <Menu.Item
              key="ccCreateManage"
              onClick={() => loadPage('ccCreateManage', dispatch)}
            >
              Manage
            </Menu.Item>
            <Menu.Item
              key="ccImages"
              onClick={() => loadPage('ccImages', dispatch)}
            >
              Images
            </Menu.Item>
            <Menu.Item
              key="ccVolumes"
              onClick={() => loadPage('ccVolumes', dispatch)}
            >
              Volumes
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <Menu.Item
          key="cloudStorage"
          onClick={() => loadPage('cloudStorage', dispatch)}
        >
          Cloud Storage
        </Menu.Item>
        <Menu.Item key="dns" onClick={() => loadPage('dns', dispatch)}>
          DNS
        </Menu.Item>
        <Menu.Item
          key="loadBalancers"
          onClick={() => loadPage('loadBalancers')}
        >
          Load Balancers
        </Menu.Item>
        <Menu.Item
          key="firewalls"
          onClick={() => loadPage('firewalls', dispatch)}
        >
          Firewalls
        </Menu.Item>

        <SubMenu key="networks" title="Networks">
          <Menu.ItemGroup key="g3">
            <Menu.Item
              key="networksDirectConnect"
              onClick={() => loadPage('networksDirectConnect', dispatch)}
            >
              Direct Connect
            </Menu.Item>
            <Menu.Item
              key="networkL2Segments"
              onClick={() => loadPage('networkL2Segments', dispatch)}
            >
              L2 Segments
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <Menu.Item
          key="privateRacks"
          onClick={() => loadPage('privateRacks', dispatch)}
        >
          Private Racks
        </Menu.Item>

        <SubMenu key="monitoring" title="Monitoring">
          <Menu.ItemGroup key="g4" onClick={() => loadPage('g4', dispatch)}>
            <Menu.Item
              key="monitoringHealthchecks"
              onClick={() => loadPage('monitoringHealthchecks', dispatch)}
            >
              Healthchecks
            </Menu.Item>
            <Menu.Item
              key="monitoringNotificationSettings"
              onClick={() =>
                loadPage('monitoringNotificationSettings', dispatch)
              }
            >
              Notification Settings
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu key="reports" title="Reports">
          <Menu.ItemGroup key="g5">
            <Menu.Item
              key="reportsCloudStorage"
              onClick={() => loadPage('reportsCloudStorage', dispatch)}
            >
              Cloud Storage
            </Menu.Item>
            <Menu.Item
              key="reportsCloudServers"
              onClick={() => loadPage('reportsCloudServers', dispatch)}
            >
              Cloud Servers
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <Menu.Item
          key="requests"
          onClick={() => loadPage('requests', dispatch)}
        >
          Requests
        </Menu.Item>
        <Menu.Item
          key="sslCertificates"
          onClick={() => loadPage('sslCertificates', dispatch)}
        >
          SSL certificates
        </Menu.Item>

        <SubMenu key="profile" title="Profile">
          <Menu.ItemGroup key="g6">
            <Menu.Item
              key="profileAccount"
              onClick={() => loadPage('profileAccount', dispatch)}
            >
              Account
            </Menu.Item>
            <Menu.Item
              key="profileContacts"
              onClick={() => loadPage('profileContacts', dispatch)}
            >
              Contacts
            </Menu.Item>
            <Menu.Item
              key="profileGroups"
              onClick={() => loadPage('profileGroups', dispatch)}
            >
              Groups
            </Menu.Item>
            <Menu.Item
              key="profileSshKeys"
              onClick={() => loadPage('profileSSHKeys', dispatch)}
            >
              SSH-Keys
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <SubMenu key="billing" title="Billing">
          <Menu.ItemGroup key="g7">
            <Menu.Item
              key="billingOrders"
              onClick={() => loadPage('billingOrders', dispatch)}
            >
              Orders
            </Menu.Item>
            <Menu.Item
              key="billingInvoices"
              onClick={() => loadPage('billingInvoices', dispatch)}
            >
              Invoices
            </Menu.Item>
            <Menu.Item
              key="billingHroupInvoices"
              onClick={() => loadPage('billingHroupInvoices', dispatch)}
            >
              Group Invoices
            </Menu.Item>
            <Menu.Item
              key="billingAccountStatement"
              onClick={() => loadPage('billingAccountStatement', dispatch)}
            >
              Account Statement
            </Menu.Item>
            <Menu.Item
              key="billingPaymentDetails"
              onClick={() => loadPage('billingPaymentDetails', dispatch)}
            >
              Payment Details
            </Menu.Item>
            <Menu.Item
              key="billingLoadAccount"
              onClick={() => loadPage('billingLoadAccount', dispatch)}
            >
              Load Account
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default SideNavigation;
