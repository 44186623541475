import { Layout } from "antd";
// Custom imports
import "./footer.scss";

const Footer = () => {
  const { Footer } = Layout;

  return (
    <Footer
      className='full-width footer py-1'
      style={{ backgroundColor: "#fff" }}>
      <div className='full-width footer-copyrights text-center d-flex justify-content-center'>
        &copy; Copyrights | All Right Reserved
      </div>
    </Footer>
  );
};

export default Footer;
