import React, { useState, useEffect, useContext } from 'react';

import {
  Row,
  Divider,
  notification,
  Col,
  Button,
  Breadcrumb,
  Modal,
  Table,
  Tooltip,
  message,
  Layout,
  Alert,
} from 'antd';
import { ImGift } from 'react-icons/im';
import { BiRupee, BiEditAlt, BiTrashAlt, BiCheckCircle } from 'react-icons/bi';
import { Formik, Field, Form } from 'formik';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './stripeckForm.jsx';

// Custom imports
import './cart.scss';
import { Context } from '../../context';
import { BREADCRUMB } from '../../reducer/types';
import { loadPage } from '../../SharedFunctions';
import { DELETE, POST, apiURL, GET, PUT } from '../../common/crud';
import { useSessionStorage } from '../../common/customhooks';
import { CartOrderDetails, CartPrice } from '../../components/Cart/index';

// Table Dummy Content

const stripePromise = loadStripe('pk_test_JSmI8CYxBq8b1rkIPE5I1cRH00fNmam7qb');

const CheckOut = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const [cust, setCust] = useSessionStorage('userData', {});

  const [paymode, setPaymode] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const { state, dispatch } = useContext(Context);
  const [payInfo, setPayInfo] = useState(null);
  const [detail, setDetail] = useState({});
  const [data, setData] = useState([]);
  const [priceSymbol, setPriceSymbol] = useState(null);
  const [priceCode, setPriceCode] = useState(null);
  const [price, setPrice] = useState({
    addon_price: 0,
    product_price: 0,
    cart_price: 0,
    discountValue: 0,
    total_tds: 0,
    addon_price_pre_tds: 0,
    product_price_pre_tds: 0,
    cart_price_pre_tds: 0,
  });
  const [paymentSuccessAlert, setPaymentSuccessAlert] = useState(false);

  const columns = [
    {
      title: 'Order Details',
      dataIndex: 'order_details',
      className: 'order-details-col w-40',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      className: 'w-20 text-end',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      className: 'cart-quantity text-center w-10',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      className: 'text-end w-10',
    },
  ];

  const getOrderDetails = (orderid) => {
    GET(`orders/${orderid}`, state.token)
      .then((res) => {
        console.log('Order details res - ', res);
        setDetail(res.data);
        const dat = [];
        if (res.data.stripe_id) {
          setClientSecret(res.data.stripe_client_secret);
          setPayInfo({
            order_id: orderid,
            stripe_id: res.data.stripe_id,
            invoice_id: res.data.invoice_id,
          });
        }
        let [cptds, cp, pp, pptds, ap, aptds, ttds, atds, ptds] = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ];
        res.data.cart_items.map((obj, index) => {
          setPriceSymbol(obj.symbol);
          setPriceCode(obj.currency);
          ap = ap + obj.addon_price;
          pp = pp + obj.product_price;
          cp = cp + obj.cart_price;
          aptds = aptds + obj.addon_price_pre_tds;
          pptds = pptds + obj.product_price_pre_tds;
          cptds = cptds + obj.cart_price_pre_tds;
          ttds = ttds + obj.total_tds;
          atds = atds + obj.addon_tds;
          ptds = ptds + obj.product_tds;
          if (true) {
            dat.push({
              key: index,
              order_details: (
                <CartOrderDetails
                  mainTitle={obj.product_info.title}
                  subTitle={`Location: ${obj.product_info.location}`}
                  //  subSubTitle={obj.products[0].category}
                  subSubTitle={
                    obj.product_info.host_names !== undefined
                      ? obj.product_info.host_names
                      : null
                  }
                />
              ),
              price: (
                <CartPrice
                  priceConv="USD => INR"
                  price={Number(obj.cart_price_pre_tds).toFixed(2)}
                  duration="Month"
                  symbol={obj.symbol}
                />
              ),
              quantity: obj.quantity,
              amount: (
                <span
                  className="cart-price-number"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {obj.symbol}
                  {Number(Number(obj.cart_price_pre_tds)).toFixed(2)}{' '}
                </span>
              ),
            });
          }
        });

        setData(dat);
        setPrice({
          addon_price: ap,
          product_price: pp,
          cart_price: cp,
          total_tds: ttds,
          discountValue: 0,
          addon_price_pre_tds: aptds,
          product_price_pre_tds: pptds,
          cart_price_pre_tds: cptds,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const det = sessionStorage.getItem('checkout');
  useEffect(() => {
    if (det !== null) {
      getOrderDetails(det);
    }

    return () => {
      setDetail({});
      sessionStorage.removeItem('checkout');
    };
  }, [det]);

  const postCaptuePayment = (inv_id, str_id, razorBody) => {
    POST(`invoices/${inv_id}/${str_id}/charge`, state.token, razorBody).then(
      (res) => {
        if (res.success) {
          notification.success({
            message: 'Your Payment was Successful!!!',
          });
          console.log('payment success ', res);
          //  loadPage('billingOrders', dispatch);
          setPaymentSuccessAlert(true);
        } else {
          //  message.open('unknown error');
          notification.error({
            message: 'Something went wrong, payment was Unsuccessful!!!',
          });
        }
      }
    );
  };

  const triggerRazorPayment = (inv_id, rpay_id) => {
    const rzrpy = new window.Razorpay({
      key: 'rzp_test_aOgG0SwmN2IsYl',
      name: 'Ibee Portal',
      order_id: rpay_id,
      handler: function (response) {
        const razorBody = {
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature,
          gateway: 'razorpay',
        };
        console.log(response);
        postCaptuePayment(inv_id, rpay_id, razorBody);
        /* alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature); */
      },
      prefill: {
        name: state.userId,
        email: cust !== null && cust !== undefined ? cust.email : '',
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#011d11',
      },
    });
    rzrpy.open();
  };

  const appearance = {
    theme: 'stripe',
  };
  const Stripeoptions = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title">
              Checkout <span></span>
            </Col>
            <Col span="10" flex="row" align="end">
              {/* <Button type="default" onClick={showModal}>
                <ImGift style={{ marginRight: "10px" }} />
                Apply Promocode
              </Button>*/}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row align="stretch">
        <Col span="24" className="p-2">
          {paymentSuccessAlert ? (
            <Row>
              <Col span="24" style={{
                backgroundColor: '#f6ffed',
                border: '1px solid #b7eb8f',
                padding: '20px',
              }}>
                <Row gutter={25}>
                  <Col className='text-end'>
                    <BiCheckCircle color='#52c41a'  size="32" />
                  </Col>
                  <Col span='20'>
                    <h4 className="full-width text-success">Your payment was Successfull!</h4>
                    <h6 className="full-width text-gray">Your payment was successfull! We are prepring your order to get up and running!.</h6>
                  </Col>
              <Col span='24' className='mt-3 text-center'>
                <Button type="default" size='large' onClick={() => loadPage('billingOrders', dispatch)}>Go to My Orders</Button>
              </Col>
                </Row>

              </Col>

            </Row>
          ) : (
            <>
              <Row>
                <Col span="24">
                  <Table
                    columns={columns}
                    dataSource={data}
                    className="cart-table"
                    pagination={false}
                    summary={() =>
                      'cart_items' in detail && detail.cart_items.length > 0 ? (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={3}
                              className="text-end py-1"
                            >
                              Sub Total
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="cart-price-number py-1">
                              {priceSymbol}
                              {Number(price.cart_price_pre_tds).toFixed(2)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="py-1">
                              &nbsp;
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={3}
                              className="text-end py-1"
                            >
                              Tax
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="cart-price-number py-1">
                              {priceSymbol}
                              {Number(price.total_tds).toFixed(2)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="py-1">
                              &nbsp;
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={3}
                              className="text-end py-1"
                            >
                              Discount
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="cart-price-number py-1">
                              - {priceSymbol}
                              {detail.discount_value}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="py-1">
                              &nbsp;
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      ) : null
                    }
                  />
                </Col>
              </Row>
              {priceCode === 'INR' ? (
                <Row>
                  <Col span="12">
                    {/* <Button type="default">Cancel</Button> */}
                  </Col>
                  <Col span={12} className="text-end">
                    <Button
                      className="pay-now-btn"
                      onClick={() =>
                        triggerRazorPayment(
                          detail.invoice_id,
                          detail.razorpay_id
                        )
                      }
                    >
                      Pay Now {priceSymbol}
                      {'amount_paid' in detail && detail.amount_paid === 0
                        ? Number(detail.cart_price)
                        : Number(detail.amount_due)}
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row className="my-2">
                  <Col span="16">
                    {clientSecret && payInfo && (
                      <Elements options={Stripeoptions} stripe={stripePromise}>
                        <CheckoutForm
                          postCaptuePayment={postCaptuePayment}
                          inv_id={payInfo.invoice_id}
                          order_id={payInfo.order_id}
                          stripe_id={payInfo.stripe_id}
                        />
                      </Elements>
                    )}
                  </Col>
                </Row>
              )}
              <Row>
                <Col span="24">
                  <div className="full-width font-weight-bold fs-7">
                    Each server includes:
                  </div>
                  <div className="full-width pt-1 fs-7">
                    <ul className="">
                      <li>Instant setup, including OS install</li>
                      <li>
                        Self-service panel (Control Power, KVM, OS reinstall,
                        Billing and more)
                      </li>
                      <li>24/7 customer support</li>
                      <li>Instant Hardware Replacement</li>
                      <li>Power management (on/off/reboot)</li>
                      <li>Physical console access (KVM)</li>
                      <li>10 days money back guarantee — no questions asked</li>
                    </ul>
                  </div>
                  <div className="full-width mt-2 fs-7">
                    Note, that after the order is submitted your configuration
                    can't be modified. Please make sure to pay for the order
                    within 30 days. Please also note that orders not being paid
                    within 30 days will be deleted.
                  </div>
                </Col>
              </Row>
            </>
          )}
          {/* <Row className="mt-2 border-top pt-2">
            <Col span="12">
               <Button type="default">
                  Clear Cart
               </Button>
            </Col>
            <Col span="12" className="text-right" style={{textAlign: "right"}}>
               <Button type="default" className="">Continue Shopping</Button>
               <Button type="default" className="btn-primary ms-15">Buy Now</Button>
            </Col>
                    </Row>*/}
        </Col>
      </Row>
    </>
  );
};

export default CheckOut;
