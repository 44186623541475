import React, {useState, useContext, useEffect} from 'react';
import {
  Row,
  Col,
  Input,
  Table,
  Button,
  Breadcrumb,
  Card,
  Radio,
  message,
  Select,
  Form,
  Space,
} from 'antd';
import {AiOutlineDelete} from 'react-icons/ai';

// Custom imports
import '../ProfileAccount/contacts_scss.scss';
import {Context} from '../../context';
import {BREADCRUMB} from '../../reducer/types';
import { GET, PUT } from "../../common/crud";
import {loadPage} from '../../SharedFunctions';

// Dummy Private Key

const pKey = `-----BEGIN RSA PRIVATE KEY-----
MIIJKQIBAAKCAgEA01FWBYC4WbwR1IG06erC20w/ZaLlHvwpgLS2Ag1GhNGR/R8Q
nmplriWv1QG7Pw/iuYWLR7c9aJTxXvt1L+WyZXDNDOebqQKiVqbvWEP+OWJTb2+5
KSWsqzzcf0ZLayAmyIeHjYHOp7qQvnTi7aZBOHPvKUD3RcnJ1QuH6CiYbeETjs48
LWzTCiQL2iGznTJt8T3LuUNU0WeY/n2pPFO4SNbNUlyIZ2bCORhBu4lZW1CqHrN6
e76dbNwDFkS8cf0hbpOnxT4KSmZLaMVFMD4abeTdpLqi0/BV5HEACCqsiI1f4erN
4zbcnCBEQSE6p3AaLzFyfjaGWIuoH+PpI+aTc25z5z8Rf51IjuKVd6uuC3OeAlJ/
z28YoMg80jClj7X+cfT6OMqQ4s8y8cwzeyfhqbko+NvZ8zh4HmqPVy3/R0xsLLYj
1XWsQxhNyUd/3WY6pOPy+XbStojFdu6H+gJtGiNE3HfA8yVX/qksDsz8S3V6etXA
9keB3L6VujM5gWk5LL3HqtpkoWuZDWTJnnKrGU6N8PPMUF9MzcZJuHLaRW80wF+R
yJ6XuINQRthsdGSYT49BH+pFhafTRTOVqohVMNvTwHqzI9RJzT7CeQvDR7Y3FsSO
Cb5zX9r0DM1dJEcufh6SIE+1HopYwHbGWTRzd+ulCDtQNX6i9us2CbOlOfECAwEA
AQKCAgBAC9vNiSjWkai5xqIlb3CldKOWU1usG3McviiX0Jw57yaXGA46ZTbyJBv5
rC+rOk5WOkJvdvsWsmXBiucGoFCEbD7JlXzZKaI4LxpFnz3y+HkfQjZVYUTHFYgi
zxRWoIUArAsKeXg/38y03MpWitgjmwe2cik/rpwMhRUo6Pzh+KCr3JfikKQZ47jv
4N0GXN1zi24udVjEquh04XMkhJ9K/Ed4mfEBneRp1488nrFFetpEudL6pFgkQT5y
RlzzE2xJjlj/8ADAceaTF48Tww7RldcTcTmekK4HsbP3xa9LJ8YSu86w+dS9QQ5O
yxpy2wgkm5D+7dmkBpfrKnFj3yN7AMgSIaUveKD6mEatdvkw5WtjgHpp/e67T93w
LUEcq6OuLJplA5Mx7WVSWKo5mYxtSV6HM75IFKuL5lMiTXWrrarlZLmkni74VIs6
3q6R0v5I2rcLpXVvLWs/ltuKJOUO0UGf/3i2wulMd6k+IsqoMU8rcm0OoheJnH2d
P4ApO2UABT053i9+ZneWZ0tgefO6xRRKi2tzjRod68J5drHknKwx3wkFwIBHX7qh
67FwupTo+Bc0XhM/kQ6HD7nQpMngMGl8olRRKKvL/21Pri3mAgbj6qc/fH/IuDM3
etsxABFgpf6IFd3mPQPB8gxNB9S1fIQIyEyJI1AqKpY+c+1WEQKCAQEA72FQ/L7C
zX7W2EgUegYV/6VsBj2GgScELbwLMFmMZte8+nGKOVEYU5BY7lTkyXBa8SsgWZFK
TnHF8W3bRxdhznhgjK/DFIApIUrhAySSXlUPm+u+wjjcwqmbF3K9gmTd1SY8PZ46
zJb5lkE6culJh6QAWdRogRQN2lqq9s7/IOTKkVDdzzGgswPNZ2/q/MxkDepcF/He
aNO92oI160Hyu06p7IOfiwfHChGFr9Hziit4SzkAOxpYjAOphSQFXEEKQo1Z22O9
joO0RqwScVMQTYrqvO8Lgog/2AQxyW/wmbIKszcs6FwW4UEP2ixQJv2QUOagq3fv
ZXI5tDYHB2MzbQKCAQEA4f0+vQdJvAzZ4bATj88328UFp4Wev4LIP5lHBJxcBb92
F9JX6C2PeQdL78orPpHNHWRJj7ujczz/WjLPhsJ0RuYpMcddRpAyt3cDNGUvTQc1
68MmE+Va3WR4oz6e3u0O0X9CS8iM+DUCbqw4iDQ/Is3SF0krEx/6yeWjqzy1GAjD
OCnX97u21T8oayK4+O2i4FL1DDxozhKJHHQK32X27kxPgL55lTE3ShLtDUnhtVmn
8KEgqQAH5W8f+fU+Iq0sdY2H3RJKM7fIbWSHN5qAICN6xSq9LWCCO7k56Sy65fHh
MmznNMzUbkHwLeNeYlvq9yVkFzTU9D+oVspHVubKFQKCAQBJMrDZGFvpCogXyrK4
PXQsoCDQJ7AhfhTBB2dEeZ19NSsMSfvCWxHdO552Am+TSVAFvvI9cTgPlKq6kKUk
r/mwcQ7U9kno0ufAzmzP0TcQsWH8g7HpIgofXE2YdfWNEdrjerWhAelo9iGL2JZO
3Rcm6vdQgKsUDVXPFzqADfvlyxBGLzwkR0CkfN0qbAanFrjhqHBQwh0IYPcFAhwK
vDfFHuDG+dinYtFyOrlucfzOm0xFdECl0gCRj+0eUU1yUFEa2K5Jb85e7dcKZUOe
c4/gpkHxVAdo+tDK9T1kI16nINI0ywJoawB0ELkQNcysn4ypsFwhEqOZmbwbZwnv
MF5hAoIBAQC4ebvPHrXoFi199/ff+u3ELu7Qs1jFSIMit4Krlt/fqU1DYOd1lkt6
JqSsV8I5T1e62HTcKfXhSjfAb53MicvVXGjgQ7g2s9tSvIoXKUoVfP9dN25L63vj
IfMAf0u8+pKQHWwG5O1+Z/dWVKvJM60eQhlv/MB22jIkwZp7XBEQT4bcv/uWNNuo
8+eQnUWVnpbIu69hj6aWIbdfTLLbbibGm/CCTfp9+TEC/LQZlufy2/8lhMJecrH/
blkR+EoCkI6sXx47LoT39txbwCZJE4vnsE61yI+pDRECo89MMsFWl76XJSMPjO0U
HCttqh8P96y0lwc/klcMEzLdtj3KCJeJAoIBAQDNvdcR4KwCffEeIX8lruRboOZr
ASuLNN8F1VthPiiL7v0AF4b7Q8Mv2qa8n2HUED2faMEQkV5YXmGUfB+e1aWUrceo
MiBYBwYaUln43U5kmDPsjx4c0l16aYAhRx9a0Z1VQdvMa2Huz9iatIcxJlrHfjP+
ZBc7PzVmI7XF01dOq1Bo2COj75/7OezTPz4VhmPuwlspQGmFdcoC2wnpRS/C1avt
BogOdR4Zp1yMJxzwesAs3bqbFJsvu94bi3WRFm3MY39+BeZhgLBkbd9+ZrhAYJEU
dP6vGXqliv7LoKhamvjOeHuDOZbLqbwDmyKkHPrjHFyaPoUjVcVOXrAMtuxC
-----END RSA PRIVATE KEY-----`;

// Dummy Date
const dataSource = [
  {
    key: '1',
    name: 'Shanker',
    fingerprint: '70:6d:d8:ac:d6:3d:e4:95:e2:f6:5f:8b:a6:2c:45:15',
  },
  {
    key: '2',
    name: 'Yugandhar',
    fingerprint: '70:d6:3d:e4:95:e2:f6:5f:8b:a6:2c:45:15:6d:d8:ac',
  },
];


const ProfileSSHKeys = () => {
  const [showView, setShowView] = useState('list');
  const [publicGenerate, setPublicGenerate] = useState('public');
  const [customer, setCustomer] = useState(null);
  const {Search, TextArea} = Input;
  const {Tr, Td} = Table;
  const { state, dispatch } = useContext(Context);
  const { Option } = Select;


  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => loadPage('/', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => loadPage('profileAccount', dispatch)}
        >
          Profile
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>SSH Keys</Breadcrumb.Item>
    </Breadcrumb>
  );

  const getCustomerData = () => {
    GET(`customers/${state.userId}`, state.token)
      .then((res) => {
        if (res.success === true) {
          setCustomer(res.data);
          sessionStorage.setItem('userData', JSON.stringify(res.data));
          console.log('Get res - ', res);
          dispatch({
            type: 'USER_DATA',
            payload: {
              userData: res.data,
            },
          });
        } else if (res.success === false) {
          message.error('Something went wrong! Please try again!!!');
        }
      })
      .catch((err) => {
        console.log('Get err - ', err);
      });
  };

  const editCustomer = (obj,type) => {
    // console.log(values);
    const cust = { ...customer, ...obj };
    // setCustomer(cust);
    PUT(`customers`, 'me', state.token, cust).then((res) => {
      console.log(res);
      getCustomerData();
      setShowView('list')
      if(type === 'add')
      message.success("Successfully added Ssh pair");
      else
      message.success("Successfully removed ssh pair");
    });
    console.log(cust);
  };

  useEffect(()=>{
      getCustomerData();
  },[])
  // Create SSH-Keys Form On Submit SUCCESS
  const createNewSsh = (values) => {
    let newsshobj = { key : customer.ssh.length+1 , ...values};
    let custssh = {  ssh : [ ...customer.ssh , newsshobj]};
    editCustomer(custssh,'add');
  };

  const deleteSsh = ( key  ) =>{
      console.log(key+"------------");
      let tssh = [...customer.ssh];
      tssh.splice(key,1);
      editCustomer({ ssh : tssh},'rm')
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Fingerprint',
      dataIndex: 'fingerprint',
      key: 'fingerprint',
    },
    {
      title: 'Public key',
      dataIndex: 'ssh_key',
      key: 'ssh_key',
      render:(e,record)=>{ return record.ssh_key }
    },
    {
      title: '',
      dataIndex: 'index',
      key: 'index',
      render: (text , record , index) => {
        return (
          <Button
            type="default"
            style={{display: 'flex', alignItems: 'center'}}
            className="btn-danger"
            onClick={()=> deleteSsh(index)}
          >
            <AiOutlineDelete style={{marginRight: '5px'}} /> Delete Key
          </Button>
        );
      },
    },
  ];
  // Public Generate onChange
  const togglePublicGenerate = (e) => {
    setPublicGenerate(e.target.value);
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      {showView === 'list' ? (
        <>
          {/* Groups List Starts */}
          <Row>
            <Col span="24" className="full-width page-header">
              <Row align="middle">
                <Col span="6" className="page-title ">
                  Profile - <span>SSH-Keys</span>
                </Col>
                <Col span="18" flex="row" align="end">
                  <Button
                    type="primary"
                    className="ms-1"
                    onClick={() => setShowView('create')}
                  >
                    + Create New
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="py-1 px-2" xs={24}>
              <Row align="stretch" gutter="20">
                <Col span="24" class Name="mb-2">
                  <Table
                    dataSource={customer && customer.ssh}
                    columns={columns}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Groups List Ends */}
        </>
      ) : null}

      {showView === 'create' ? (
        <>
          {/* Create SSH-Keys Starts */}
          <Row>
            <Col span="24">
              <Card title="Create SSH Keys">
                <Form
                  name="basic"
                  layout="vertical"
                  onFinish={createNewSsh}
                >
                  <ul className="list-unstyled form-list full-width">
                    <li>
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your Name!',
                          },
                        ]}
                      >
                        <Input className="" />
                      </Form.Item>
                    </li>
                    <li>
                      <Form.Item label="" name="publicGenerate">
                        <Radio.Group
                          onChange={togglePublicGenerate}
                          value={publicGenerate}
                          defaultValue={publicGenerate}
                        >
                          <Space direction="vertical">
                            <Radio value="public">Use public SSH-Key</Radio>
                            {/*<Radio value="generate">Generate new SSH-Key</Radio>*/}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </li>
                    {publicGenerate === 'public' ? (
                      <>
                      <li>
                      <Form.Item label="Fingerprint" name="fingerprint">
                        <TextArea rows={1} />
                      </Form.Item>
                    </li>
                      <li>
                        <Form.Item label="Public key" name="ssh_key">
                          <TextArea rows={4} />
                        </Form.Item>
                      </li>
                      </>
                    ) : null}
                  </ul>
                  <div className="full-width pb-3">
                    <Button type="default" onClick={() => setShowView('list')}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      //onClick={() => setShowView('privateKey')}
                      className="btn-success ms-2 px-3"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
          {/* Create SSH-Keys Ends */}
        </>
      ) : null}

      {showView === 'privateKey' ? (
        <Row>
          <Col span="18">
            <Card title="Your Private Key">
              <div className="full-width" style={{marginBottom: '3px'}}>
                Private Key
              </div>
              <div className="full-width">
                <TextArea
                  readOnly
                  value={pKey}
                  className="form-control"
                  style={{minHeight: 'calc(100vh - 350px)', fontSize: '12px'}}
                />
              </div>
              <div className="full-width py-2">
                <Button type="primary" onClick={() => setShowView('list')}>
                  Return to the List
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default ProfileSSHKeys;
